import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const Contact = React.lazy(dynamicImportComponents[`/customers/contacts/preview`])


export const ContactPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <Contact />
  </Suspense>
)
