import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'

const UpdateVehicle = React.lazy(() => import(`App/Components/Customers/Customer/Tabs/Assets/Vehicle/UpdateVehicle`).then(module => ({
  default: module.UpdateVehicle
})))

export const UpdateVehiclePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <UpdateVehicle />
  </Suspense>
)
