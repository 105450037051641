import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const AddLocation = React.lazy(dynamicImportComponents[`/locations/add`])


export const AddLocationPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AddLocation />
  </Suspense>
)
