import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const RerateQuoteForm = React.lazy(dynamicImportComponents[`/raters/quoteform/reRate`])

export const ReRateQuoteFormPage = () => (
    <Suspense fallback={<Spinner loading />}>
        <RerateQuoteForm />
    </Suspense>
)
