import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const Claims = React.lazy(dynamicImportComponents[`/admin/claims`])

export const AllClaimsPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <Claims />
  </Suspense>
)
