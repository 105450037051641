import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'

const UpdateDriver = React.lazy(() => import(`App/Components/Customers/Customer/Tabs/Assets/Driver/UpdateDriver`).then(module => ({
  default: module.UpdateDriver
})))

export const UpdateDriverPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <UpdateDriver />
  </Suspense>
)
