/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { TimeAgo } from 'App/Components/Common/TimeAgo'
import { MessageInput } from 'App/Components/Common/MessageInput'
import { useDispatch } from 'react-redux'
import { newSocket } from 'App/HOC/MainLayout'
import { NotificationManager } from 'react-notifications'
import { Loader } from 'App/Components/Common/Loader'
import { fileToBase64 } from 'App/Services'
import FileSaver from 'file-saver'
import {
  faArrowCircleDown,
  faCheck,
  faCheckDouble,
  faExclamationCircle,
  faFilePdf,
  faPaperPlane,
  faPhoneAlt,
  faPlusCircle,
  faCommentDots,
  faPlay,
  faPlus,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import {
  setAllMessages,
  setOutboundMessages,
  clearAllMessage,
  setSentMessage,
  setChatLatestLength,
} from 'App/Redux/actions'
import { useRouteMatch } from 'react-router-dom'
import moment from 'moment'
import { useRecording } from 'App/hooks/useRecording'
import { ImageModal } from 'App/Components/Common/ImageModal'

const chatStatusToIcons = [
  {
    status: `sending`,
    icon: faCheck,
    color: `text-gray-600`,
  },
  {
    status: `QUEUED`,
    icon: faCheckDouble,
    color: `text-gray-600`,
  },
  {
    status: `delivered`,
    icon: faCheckDouble,
    color: `text-green-600`,
  },
  {
    status: `FAILED`,
    icon: faExclamationCircle,
    color: `text-red-500`,
  },
  {
    status: `undelivered`,
    icon: faExclamationCircle,
    color: `text-red-500`,
  },
  {
    status: `RECEIVED`,
    icon: faCheckDouble,
    color: `text-green-600`,
  },
  {
    status: `completed`,
    icon: faPhoneAlt,
    color: `text-green-600`,
  },
  {
    status: `in-progress`,
    icon: faPhoneAlt,
    color: `text-green-600`,
  },
  {
    status: `answered`,
    icon: faPhoneAlt,
    color: `text-green-600`,
  },
  {
    status: `no-answer`,
    icon: faPhoneAlt,
    color: `text-red-500`,
  },
  {
    status: `busy`,
    icon: faPhoneAlt,
    color: `text-red-500`,
  },
  {
    status: `canceled`,
    icon: faPhoneAlt,
    color: `text-red-500`,
  },
  {
    status: `failed`,
    icon: faPhoneAlt,
    color: `text-red-500`,
  },
]

export const Message = () => {
  const [isloading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const {
    params: { customerId, contactId, dataTransactionId },
  } = useRouteMatch()

  const { currentConversation } = useSelector(
    ({ communication }) => communication,
  )
  const { user, preferredLocation } = useSelector(({ user }) => user)
  const { communicationCredentials } = useSelector(
    ({ currentCommunication }) => currentCommunication,
  )

  const { latestLength } = useSelector(({ notification }) => notification)
  const { activeCommunicationTab } = useSelector(({ customer }) => customer)
  const messagesEndRef = useRef(null)
  const chatScrollHeight = useRef(null)
  const {
    fetchRecording,
    clearRecodedUrl,
    decodedRecordingUrl: recordingFile,
    isLoading: isLoadingRecordedUrl,
  } = useRecording()

  let fileRef

  const [preview, setPreview] = useState(false)
  const [currentImage, setCurrentImage] = useState(``)
  const [recordingPlayed, setRecordingPlayed] = useState(``)
  const [newMessage, setNewMessage] = useState({
    message: ``,
  })

  //  handle file upload
  const handleFileUpload = async (file) => {
    if (file.length) {
      if ((file[0]?.size / (1024 * 1024)).toFixed(2) > 5) {
        NotificationManager.error(`File is too big.`)
      } else if (file[0].type.includes(`image`)) {
        // const base64 = await fileResizer(file[0])
        // if (base64) {
        //   sendingMMS(base64, `image`)
        // }
        fileToBase64(file[0], (err, base64) => {
          if (base64) {
            sendingMMS(base64, `image`)
          }
        })
      } else if (file[0].type.includes(`pdf`)) {
        fileToBase64(file[0], (err, base64) => {
          if (base64) {
            sendingMMS(base64, `pdf`)
          }
        })
      } else if (file[0].type.includes(`video`)) {
        fileToBase64(file[0], (err, base64) => {
          if (base64) {
            sendingMMS(base64, `video`)
          }
        })
      }
    }
  }

  const handleMessageChange = (name, value) => {
    setNewMessage((preState) => ({
      ...preState,
      [name]: value,
    }))
  }
  useEffect(() => {
    if (newSocket) {
      newSocket?.emit(`CHAT_HISTORY`, {
        _customerId:
          communicationCredentials.customerId ||
          customerId ||
          dataTransactionId,
        _contactId: contactId || undefined,
        customerPhone: communicationCredentials?.isWorkPhone
          ? communicationCredentials?.workPhone
          : communicationCredentials?.phone,
        pageNumber: 1,
      })

      newSocket?.on(`CHAT_HISTORY_RES`, (message) => {
        if (message) {
          setIsLoading(false)
        }
        dispatch(setAllMessages(message))
      })

      newSocket?.on(`OUTBOUND_MESSAGE_RES`, (message) => {
        if (contactId === message._contactId) {
          dispatch(setOutboundMessages(message))
        } else if (
          communicationCredentials?.customerId === message?._customerId
        ) {
          dispatch(setOutboundMessages(message))
        }
      })

      newSocket?.on(`MY_OUTBOUND_MMS`, (message) => {
        if (contactId === message._contactId) {
          dispatch(setOutboundMessages(message))
        } else if (
          communicationCredentials?.customerId === message?._customerId
        ) {
          dispatch(setOutboundMessages(message))
        }
      })

      newSocket?.on(`INBOUND_MESSAGE`, (message) => {
        dispatch(setChatLatestLength(latestLength + 1))

        if (contactId === message._contactId) {
          dispatch(setSentMessage(message))
        } else if (
          communicationCredentials?.customerId === message?._customerId
        ) {
          dispatch(setSentMessage(message))
        }
      })
    }

    return () => {
      newSocket?.off(`CHAT_HISTORY`)
      newSocket?.off(`CHAT_HISTORY_RES`)
      newSocket?.off(`OUTBOUND_MESSAGE_RES`)
      newSocket?.off(`MY_OUTBOUND_MMS`)
      newSocket?.off(`INBOUND_MESSAGE`)
      dispatch(clearAllMessage())
    }
  }, [newSocket, communicationCredentials?.customerId, customerId])

  useEffect(() => {
    if (messagesEndRef.current)
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight
  }, [currentConversation, activeCommunicationTab])

  // Sending SMS
  const sendingSMS = () => {
    if (newMessage.message) {
      const newMessageArray = {
        direction: `OUTBOUND`,
        status: `sending`,
        type: `SMS`,
        _customerId: communicationCredentials?.customerId || customerId,
        _contactId: contactId || undefined,
        _franchiseId: user?.profile?._franchise?._id,
        _agentId: user?.profile?.sub,
        agentImg: user?.profile?.dp,
        agentName: user?.profile?.full_name,
        message: newMessage.message,
        customerPhone: communicationCredentials?.isWorkPhone
          ? communicationCredentials?.workPhone
          : communicationCredentials?.phone,
        locationPhone: preferredLocation?.phone,
      }
      newSocket?.emit(`OUTBOUND_MESSAGE`, newMessageArray)
      handleMessageChange(`message`, ``)
    }
  }
  // Sending MMS
  const sendingMMS = (base64, fileType) => {
    if (base64) {
      const newMessageArray = {
        direction: `OUTBOUND`,
        status: `sending`,
        type: `MMS`,
        _customerId: communicationCredentials?.customerId || customerId,
        _contactId: contactId || undefined,
        _franchiseId: user?.profile?._franchise?._id,
        _agentId: user?.profile?.sub,
        agentImg: user?.profile?.dp,
        agentName: user?.profile?.full_name,
        message: newMessage.message,
        customerPhone: communicationCredentials?.isWorkPhone
          ? communicationCredentials?.workPhone
          : communicationCredentials?.phone,
        locationPhone: preferredLocation?.phone,
        mediaType: fileType ? fileType : ``,
        mediaUrl: base64,
      }
      newSocket?.emit(`OUTBOUND_MESSAGE`, newMessageArray)
    }
  }

  const manageStatus = (incommingStatus) => {
    return chatStatusToIcons.map((chat, index) => {
      if (chat.status === incommingStatus) {
        return (
          <FontAwesomeIcon
            icon={chat.icon}
            className={`text-white mt-0.5 ${chat.color}`}
            size="sm"
            key={`font` + index}
          />
        )
      }
    })
  }

  const CalculateTime = (sec) => {
    if (sec == 0) return `No answer`
    let h
    let m
    let s
    if (sec >= 0) {
      h = Math.floor(sec / 3600)
      m = Math.floor((sec % 3600) / 60)
      s = Math.floor((sec % 3600) % 60)
    } else {
      h = Math.ceil(sec / 3600)
      m = Math.ceil((sec % 3600) / 60)
      s = Math.ceil((sec % 3600) % 60)
    }

    let hDisplay =
      h !== 0
        ? h + (h == 1 ? ` hr, ` : ` hrs`) + (m != 0 || s > 0 ? `, ` : ``)
        : ``
    let mDisplay =
      m !== 0 ? m + (m == 1 ? ` min, ` : ` min`) + (s != 0 ? ` ` : ``) : ``
    let sDisplay = s !== 0 ? s + (s == 1 ? ` sec` : ` sec`) : ``
    return hDisplay + mDisplay + sDisplay
  }
  return (
    <>
      {preview && currentImage && (
        <ImageModal
          setPreview={setPreview}
          setCurrentImage={setCurrentImage}
          currentImage={currentImage}
        />
      )}
      {communicationCredentials?.doNotContact ? (
        <div className="flex justify-center items-center h-96 max-h-xl font-semibold text-sm text-gray-500">
          <p>DNC enabled</p>
        </div>
      ) : communicationCredentials?.preferredContactMethods?.length &&
        !communicationCredentials?.preferredContactMethods.includes(`SMS`) &&
        !communicationCredentials?.preferredContactMethods.includes(`CHAT`) ? (
        <div className="grid place-content-center place-items-center gap-y-2 h-96 max-h-xl font-semibold text-sm text-gray-500">
          <div className=" rounded-full bg-gray-200 p-4">
            <FontAwesomeIcon icon={faCommentDots} size="lg" />
          </div>
          <p>Not Preferred</p>
        </div>
      ) : communicationCredentials?.phone ? (
        <div className="h-96 flex flex-col">
          <div
            className="relative mx-3 mt-1 flex flex-col justify-between h-96 overflow-y-scroll hide-scroller hide-scroller-firefox"
            ref={messagesEndRef}
          >
            <div className="relative">
              <div
                className="h-full flex flex-col justify-end bg-white rounded-md px-2 mb-2"
                ref={chatScrollHeight}
              >
                {currentConversation.length < 1 && (
                  <div className="h-80 flex justify-center items-center">
                    {isloading ? <Loader /> : <p>Start a conversation</p>}
                  </div>
                )}

                {currentConversation.map((message, index) => (
                  <div key={index}>
                    <div className={` relative p-1 `}>
                      <span className="pl-2 pt-0.5 w-72">
                        <div className={`flex  w-full`}>
                          <div className="w-full">
                            <div className={`text-blue-500 font-medium`}>
                              <p
                                className={`capitalize ${
                                  message.direction !== `OUTBOUND`
                                    ? `text-right`
                                    : `text-left`
                                }`}
                              >
                                {message?.agentName?.toLowerCase()}
                              </p>
                            </div>
                          </div>
                        </div>
                        {message?.type === `SMS` ? (
                          <div
                            className={`${
                              message.direction !== `OUTBOUND`
                                ? `bg-green-500 float-right`
                                : `bg-blue-500 float-left`
                            } ${
                              message?.error &&
                              `tooltip tooltip-${
                                message?.message?.length > 30 ? `top` : `right`
                              }`
                            } before:w-4 before:whitespace-pre-wrap before:[--tw-content:'line1_\\a_line2'] text-left py-1 px-2 rounded-md shadow-md border-1 min-w-20 max-w-md relative`}
                            data-tip={message?.error}
                          >
                            <p className="text-white">{message?.message}</p>
                            <div className="float-right">
                              <span
                                className={`text-2xs capitalize absolute pt-2 ${
                                  message.direction === `OUTBOUND`
                                    ? `left-1`
                                    : `right-1`
                                }`}
                              >
                                {manageStatus(message?.status)}
                                <div className="flex float-right pl-2 text-2xs">
                                  <TimeAgo timeStamp={message?.createdAt} />
                                </div>
                              </span>
                            </div>
                          </div>
                        ) : message?.type === `VOICE_CALL` ? (
                          <>
                            <div
                              className={`${
                                message.direction !== `OUTBOUND`
                                  ? ` float-right`
                                  : ` float-left`
                              } bg-gray-100 py-1 px-2 rounded-md shadow-md  border-1 w-72 relative`}
                            >
                              <p className="text-sm">
                                {manageStatus(message?.status)}
                                <span className="mx-2 underline">
                                  voice call at
                                  <span className="text-blue-600 px-1">
                                    {moment(message.createdAt).format(
                                      `HH:mm A`,
                                    )}
                                  </span>
                                </span>

                                <span className="text-xs text-gray-400">
                                  ({CalculateTime(message?.callDuration)})
                                </span>
                                {message?.recordingUrl?.length > 0 && (
                                  <div className="w-full m-2">
                                    <span
                                      className={`px-1 text-sm text-gray-200 rounded shadow  ${
                                        recordingFile &&
                                        recordingPlayed === index
                                          ? `font-semibold text-gray-700 hover:text-gray-900`
                                          : `text-red-500 hover:text-red-600`
                                      } ${
                                        isLoadingRecordedUrl &&
                                        recordingPlayed === index
                                          ? `cursor-not-allowed`
                                          : `cursor-pointer`
                                      } `}
                                      onClick={() => {
                                        setRecordingPlayed((prev) =>
                                          prev === index ? null : index,
                                        )
                                        fetchRecording(message?.recordingUrl)
                                        clearRecodedUrl()
                                        if (
                                          recordingFile &&
                                          recordingPlayed === index
                                        ) {
                                          clearRecodedUrl()
                                        }
                                      }}
                                    >
                                      <span>
                                        {recordingPlayed === index &&
                                        recordingFile
                                          ? `Stop`
                                          : `Play Recording`}
                                        <FontAwesomeIcon
                                          icon={
                                            recordingPlayed === index &&
                                            isLoadingRecordedUrl
                                              ? faSpinner
                                              : recordingPlayed === index &&
                                                recordingFile
                                              ? faPlus
                                              : faPlay
                                          }
                                          size="xs"
                                          className={`ml-2  ${
                                            recordingPlayed === index &&
                                            recordingFile &&
                                            `transform rotate-45`
                                          }`}
                                          spin={
                                            recordingPlayed === index &&
                                            isLoadingRecordedUrl
                                          }
                                        />
                                      </span>
                                    </span>
                                  </div>
                                )}
                              </p>
                              <div className="float-right">
                                <span
                                  className={`text-2xs capitalize absolute pt-2 ${
                                    message.direction === `OUTBOUND`
                                      ? `left-1`
                                      : `right-1`
                                  }`}
                                >
                                  <div className="flex  pl-2 text-2xs">
                                    <TimeAgo timeStamp={message?.createdAt} />
                                  </div>
                                </span>
                              </div>
                            </div>
                            {recordingFile && recordingPlayed === index && (
                              <span
                                className={`mt-4  ${
                                  message.direction !== `OUTBOUND`
                                    ? `float-right`
                                    : `float-left`
                                }  `}
                              >
                                <audio
                                  controls
                                  src={recordingFile}
                                  preload="auto"
                                />
                              </span>
                            )}
                          </>
                        ) : message?.type === `MMS` &&
                          message?.mediaType?.includes(`image`) ? (
                          <div
                            className={`relative ${
                              message.direction !== `OUTBOUND`
                                ? `float-right`
                                : `float-left`
                            }`}
                          >
                            <img
                              src={message?.mediaUrl}
                              alt="Image Sending is Failed"
                              className={`rounded-md`}
                              style={{
                                height: `30vh`,
                                width: `20vw`,
                                cursor: `pointer`,
                              }}
                              onClick={() => {
                                setPreview(true)
                                setCurrentImage(message?.mediaUrl)
                              }}
                            />
                            <div className="float-right">
                              <span
                                className={`text-2xs capitalize absolute pt-2 ${
                                  message.direction === `OUTBOUND`
                                    ? `left-1`
                                    : `right-1`
                                }`}
                              >
                                {manageStatus(message?.status)}
                                <div className="flex float-right pl-2 text-2xs">
                                  <TimeAgo timeStamp={message?.createdAt} />
                                </div>
                              </span>
                            </div>
                          </div>
                        ) : message?.mediaType === `video` &&
                          message?.type === `MMS` ? (
                          <div className="relative">
                            <video width="650" height="300" controls>
                              <source
                                src={message?.mediaUrl}
                                type="video/mp4"
                              />
                            </video>
                            <div className="float-right">
                              <span
                                className={`text-2xs capitalize absolute pt-2 ${
                                  message.direction === `OUTBOUND`
                                    ? `left-1`
                                    : `right-1`
                                }`}
                              >
                                {manageStatus(message?.status)}
                                <div className="flex float-right pl-2 text-2xs">
                                  <TimeAgo timeStamp={message?.createdAt} />
                                </div>
                              </span>
                            </div>
                          </div>
                        ) : message?.mediaType === `pdf` &&
                          message?.type === `MMS` ? (
                          <div className="relative">
                            <div className="flex items-center gap-1 w-min bg-gray-100 shadow-md px-4 py-2 rounded-3xl">
                              Document
                              <FontAwesomeIcon icon={faFilePdf} size="sm" />
                              <FontAwesomeIcon
                                icon={faArrowCircleDown}
                                className="ml-1 cursor-pointer hover:text-gray-300"
                                onClick={() =>
                                  FileSaver.saveAs(message?.mediaUrl)
                                }
                              />
                            </div>
                            <div className="float-right">
                              <span
                                className={`text-2xs capitalize absolute pt-2 ${
                                  message.direction === `OUTBOUND`
                                    ? `left-1`
                                    : `right-1`
                                }`}
                              >
                                {manageStatus(message?.status)}
                                <div className="flex float-right pl-2 text-2xs">
                                  <TimeAgo timeStamp={message?.createdAt} />
                                </div>
                              </span>
                            </div>
                          </div>
                        ) : (
                          message?.type === `audio` && (
                            <audio controls className="m-2">
                              <source
                                src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
                                type="audio/ogg"
                              />
                            </audio>
                          )
                        )}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col pb-2">
            <div className="flex-1 relative">
              <MessageInput
                pl="pl-8"
                type="text"
                placeholder="Type Here ..."
                value={newMessage?.message}
                setValue={(e) => {
                  handleMessageChange(`message`, e.target.value)
                }}
                onEnter={() => {
                  sendingSMS()
                }}
              />
              <>
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  onClick={() => fileRef.click()}
                  className="absolute text-gray-700 left-3 mt-3 cursor-pointer"
                />
                <input
                  className="hidden"
                  type="file"
                  name="attachment"
                  ref={(refParam) => (fileRef = refParam)}
                  onChange={(e) => {
                    handleFileUpload(e.target.files)
                    e.target.files = null
                  }}
                  accept="application/pdf, image/*, video/*, audio/*"
                />
              </>
              <>
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  onClick={() => {
                    sendingSMS()
                  }}
                  className="absolute text-gray-700 right-3 mt-3 cursor-pointer"
                />
              </>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid place-content-center place-items-center gap-y-2 h-96 max-h-xl font-semibold text-sm text-gray-500">
          <div className=" rounded-full bg-gray-200 p-4">
            <FontAwesomeIcon icon={faCommentDots} size="lg" />
          </div>
          <p>Contact info is not provided</p>
        </div>
      )}
    </>
  )
}
