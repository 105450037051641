import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
const AssessmentDashboard = React.lazy(dynamicImportComponents[`/assessment/analytics`])

export const AssessmentDashboardPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AssessmentDashboard />
  </Suspense>
)
