import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React from 'react'
import { Suspense } from 'react'

const AllCarriers = React.lazy(dynamicImportComponents[`/imported-carriers`])

export const ImportedCarrierPage = () => {
    return (
        <div>
            <Suspense fallback={<Spinner loading />}>
                <AllCarriers />
            </Suspense>
        </div>
    )
}
