import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const UpdateRatingTemplate = React.lazy(dynamicImportComponents[`/raters/ratingtemplates/update`])


export const UpdateRatingTemplatePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <UpdateRatingTemplate />
  </Suspense>
)
