import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'

const AddDriverAsset = React.lazy(() => import(`App/Components/Customers/Customer/Tabs/Assets/Driver/AddDriver`).then(module => ({
  default: module.AddDriverAsset
})))

export const AddDriverPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AddDriverAsset />
  </Suspense>
)
