import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const Preference = React.lazy(dynamicImportComponents[`/raters/preferences`])

export const PreferencePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <Preference />
  </Suspense>
)
