import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const PoliciesReports = React.lazy(
  dynamicImportComponents[`/reports/policy-reports`],
)

export const PoliciesReportsPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <PoliciesReports />
  </Suspense>
)
