import { faLocationArrow, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { TimeAgo } from '../TimeAgo'

export const Card = ({
  title,
  createdAt,
  message,
  source,
  deleteCard,
  index,
  isDeleting,
  currentIndex,
  setCurrentIndex,
  curruentLocation,
  readCard,
  isGeneralNotification,
}) => {
  return (
    <div className=" w-full my-0.5 shadow-sm border-1 rounded-lg overflow-hidden">
      <div className="flex py-0.5">
        <div className="flex1">
          <p className="bg-blue-100 text-blue-500 text-xs rounded m-1 text-center px-2 py-0.5 capitalize ">
            {source}
          </p>
          <p className="text-black text-sm font-bold  ml-2 capitalize truncate">
            {title.toLowerCase()}
          </p>
        </div>

        <div className="flex-1">
          <div className="float-right ">
            <div className="flex">
              {readCard && (
                <span className="text-4xl text-blue-600 font-bold text-center -mt-5">
                  .
                </span>
              )}
              {isDeleting && currentIndex === index ? (
                <FontAwesomeIcon icon={faSpinner} spin={true} />
              ) : (
                <button
                  className=" relative 
              w-6 h-6
              flex 
              justify-center  
              items-center 
              rounded-full  
              text-gray-500 
              hover:text-gray-800 
              hover:bg-gray-300 text-xl"
                  onClick={(e) => {
                    deleteCard()
                    setCurrentIndex(index)
                    if (e && e.stopPropagation) e.stopPropagation()
                  }}
                >
                  <span className="text-black h-7 w-6 block outline-none focus:outline-none -mt-1">
                    ×
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <p
        className={`px-3 text-gray-600 text-2xs mt-1 ${
          isGeneralNotification ? `truncate` : ``
        }`}
      >
        {message === `file` ? `You received a file` : message}
      </p>
      <div className="flex justify-between">
        <div className=" text-gray-400 text-2xs rounded mt-2 pr-1 pl-2">
          {curruentLocation && <FontAwesomeIcon icon={faLocationArrow} />}
          {` `}
          {curruentLocation}
        </div>
        <div className="float-right mt-2 pr-3 pb-1 text-gray-400 text-2xs ">
          <TimeAgo timeStamp={createdAt} />
        </div>
      </div>
    </div>
  )
}
