import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { PublicRoute } from './PublicRoute'
import { PrivateRoute } from './PrivateRoute'
import { LandingPage } from 'App/Pages/LandingPage'
import { NotFoundPage } from '../Pages/NotFoundPage'
import { OidcRedirectPage } from 'App/Pages/OidcRedirectPage'
import { AddSystemRolePage, PreviewSystemRolesPage, SystemRolesPage, UpdateSystemRolesPage } from 'App/Pages/SystemRoles'
import { GoogleAuthorizedPage } from 'App/Pages/GoogleAuthorizedPage'
import { VideoCallPage } from 'App/Pages/VideoCall'
import { DailyLedgerPage, PaymentRecordsPage, PoliciesReportsPage } from 'App/Pages/Reports'
import {
  AddDriverPage,
  DriverPreviewPage,
  UpdateDriverPage,
  VehiclePage,
  UpdateVehiclePage,
  AddVehiclePage,
} from 'App/Pages/Assets'
import {
  AddPolicyPage,
  AllPoliciesPage,
  PolicyPreviewPage,
  PolicyUpdatePage,
} from 'App/Pages/Policies'
import { AllRequestsPage, RequestPage } from 'App/Pages/Requests'
import { OtherPolicyPreviewPage } from 'App/Pages/OtherPolicies'
import {
  AddPaymentPage,
  AddReceiptPage,
  PreviewPaymentPage,
  UpdatePaymentPage,
} from 'App/Pages/Payment'
import { AllTemplatePage } from 'App/Pages/Template'
import { CommunicationCenter, CommunicationLogsPage } from 'App/Pages/CommunicationCenter'
import {
  QuotePreviewPage,
  MyQuotesPage,
  QuickEstimatePage,
  QuoteFormPage,
  PreferencePage,
  UpdatePreferencesPage,
  AddRatingTemplatePage,
  AllRatingTemplatePage,
  UpdateRatingTemplatePage,
  QuoteOffersPage,
  ReRateQuoteFormPage,
} from 'App/Pages/Raters'
import {
  AllLocationPage,
  UpdateLocationPage,
  AddLocationPage,
  LocationPage,
} from 'App/Pages/Locations'
import { AllClaimsPage } from 'App/Pages/Claims/AllClaims'
import { ClaimPage } from 'App/Pages/Claims/ClaimPage'
import {
  AddAgentPage,
  AgentPage,
  AllAgentsPage,
  UpdateAgentPage,
} from 'App/Pages/Agent'
import {
  AllFranchisesPage,
  FranchisePage,
  AddFranchisePage,
  UpdateFranchisePage,
} from 'App/Pages/Franchises'
import {
  AllCustomersPage,
  CustomerPage,
  AddCustomerPage,
  UpdateCustomersPage,
} from 'App/Pages/CustomerPage'
import {
  AllOtherCustomersPage,
  OtherCustomerPage,
} from 'App/Pages/OtherCustomerPage'
import {
  ContactPage,
  UpdateContactPage,
  AddContactPage,
} from 'App/Pages/ContactPage'
import {
  AddCarrierPage,
  AllCarrierPage,
  UpdateCarrierPage,
  CarrierPreviewPage,
  ImportedCarrierPage,
} from 'App/Pages/CarrierPage'
import {
  AllBroadCastingTemplatesPage,
  AllCampaignGroupsDetailPage,
  AllCampaignGroupsPage,
  BroadcastTemplatePreview,
  CampaignPreviewPage,
} from 'App/Pages/Broadcasts'
import {
  AllDataTransactionsPage,
  PreviewDataTransactionPage,
  AllCarDealersPage,
  AddCarDealerPage,
  CarDealerPage,
  UpdateCarDealerPage,
  AllLeadsPage,
  LeadsPreviewPage,
  AddCredentialPage,
  AllCredentialPage,
  PreviewCredentialPage,
  UpdateCredentialPage,
  AddLeadsPage,
} from 'App/Pages/CRM'
import {
  AllTransactionsPage,
  PreviewTransactionPage,
  TransactionInspectionPage,
} from 'App/Pages/Transactions'
import { GlobalSearch } from 'App/Components/GlobalSearch'
import {
  AddMonthlyGoalsPage,
  AllMonthlyGoalsPage,
  MonthlyGoalsPage,
  UpdateMonthlyGoalsPage,
} from 'App/Pages/MonthlyGoals'
import { AllDesignation } from 'App/Components/Designation/AllDesignation'
import { ProfilePage, ProfileUpdatePage } from 'App/Pages/ProfilePage'
import {
  AddPaymentTemplatePage,
  AllPaymentTemplatePage,
  PreviewPaymentTemplatePage,
  UpdatePaymentTemplatePage,
} from 'App/Pages/PaymentTemplate'
import { DashboardPage } from 'App/Pages/DashboardPage'
import {
  AssessmentDashboardPage,
  AssessmentPreviewPage,
  EvaluatePage,
  AllAssessmentsPage,
  EvaluationReportPage,
  AllTemplatesPage,
  PreviewTemplatesPage,
  TeamsPage,
} from 'App/Pages/Assessment'
//import { AssessmentTemplate } from 'App/Components/Assessment/Template'
import { MessengerPage } from 'App/Pages/MessagerPage'
import {
  AddTicketPage,
  AllTicketCategoryPage,
  AllTicketsPage,
  TicketPreviewPage,
} from 'App/Pages/Tickets'

import {
  AddTaskPage,
  AllTasksPage,
  TaskPreviewPage,
} from 'App/Pages/TaskManager'
import { AllAssessments } from 'App/Components/Assessment/AllAssessments'
import { AddTemplatePage } from 'App/Pages/Assessment/AddTemplatePage'
import { UpdateTemplatePage } from 'App/Pages/Assessment/UpdateTemplatePage'
import { TicketCategoryPreview } from 'App/Components/TicketsCenter/Category/TicketCategoryPreview'

import {
  AddGroupCarrierPage,
  AllGroupCarrierPage,
  GroupCarrierPage,
  UpdateGroupCarrierPage,
} from 'App/Pages/GroupCarrierPage'
import {
  AddGroupCredentialPage,
  AllGroupCredentialsPage,
  GroupCredentialPage,
  UpdateGroupCredentialPage,
} from 'App/Pages/GroupCredentialPage'
import QuoteSoldPage from 'App/Pages/Raters/MyQuotesPage/QuoteSoldPage'
import { AddScrapingCredentialsPage } from 'App/Pages/ScarpingCredentials'
import { AllScrapingCredentialsPage } from 'App/Pages/ScarpingCredentials'
import { GeneralNotificationPage, GeneralNotificationsPage } from 'App/Pages/GeneralNotifications'


export const Router = () => {
  const accessToken = useSelector(
    ({
      user: {
        user: { accessToken },
      },
    }) => accessToken,
  )

  // const [socket, setSocket] = useState(null)

  // const user = useSelector(({ user: { user } }) => user)

  // const dispatch = useDispatch()
  // Token renewal
  // useEffect(() => {
  //   const refreshAccessToken = async () => {
  //     return await getAccessToken()
  //   }

  //   refreshAccessToken()
  //   // setupSocket()
  // }, [accessToken])

  // setting up socket connection
  // const setupSocket = useCallback(() => {
  //   if (user && !socket?.connected && accessToken) {
  //     newSocket = io.connect(AMS_BACKEND_API, {
  //       auth: {
  //         token: accessToken ? accessToken : ``,
  //       },
  //       reconnection: true,
  //     })

  //     newSocket?.on(`connect_error`, (err) => {
  //       if (err?.message) {
  //         setTimeout(() => {
  //           newSocket?.connect()
  //         }, 2000)
  //       }
  //     })

  //     newSocket?.on(`connect`, () => {
  //       setSocket(newSocket)
  //     })

  //     newSocket?.on(`disconnect`, () => {
  //       setSocket(null)
  //     })

  //     // Gmail Events
  //     newSocket?.emit(`checkGoogleSignin`, { userId: user?.profile?.sub })

  //     newSocket?.on(`GoogleSigninStatus`, (feedback) => {
  //       dispatch(setIsUserSignInWithGmail(feedback))
  //     })

  //     // newSocket?.on(`INBOUND_MESSAGE`, (notification) => {
  //     //   console.log(`got notification`, notification)
  //     // })
  //   }
  // }, [accessToken])

  // calling socket functions
  // useEffect(() => {
  //   if (accessToken) setupSocket()
  // }, [])
  // useEffect(() => {
  //   if (accessToken) {
  //     setupSocket()
  //   } else {
  //     newSocket = null
  //   }
  // }, [accessToken])

  return (
    <Switch>
      <PublicRoute
        authenticated={accessToken}
        path="/login"
        component={LandingPage}
      />
      <PublicRoute
        authenticated={accessToken}
        path="/find-user"
        component={LandingPage}
      />
      <PublicRoute
        authenticated={accessToken}
        path="/set-password"
        component={LandingPage}
      />
      <PublicRoute
        authenticated={false}
        path="/video-call"
        component={VideoCallPage}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/profile"
        component={ProfilePage}
        permission={true}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/profile/update"
        component={ProfileUpdatePage}
        permission={true}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `dashboard:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/dashboard"
        component={DashboardPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `super-admin:*`,
        ]}
        authenticated={accessToken}
        path="/admin/general-notifications"
        component={GeneralNotificationsPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `super-admin:*`,
        ]}
        authenticated={accessToken}
        path="/admin/general-notifications/:generalNotificationId"
        component={GeneralNotificationPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `payments-templates:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/admin/paymenttemplates"
        component={AllPaymentTemplatePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `payments-templates:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/admin/paymenttemplates/add"
        component={AddPaymentTemplatePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `payments-templates:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/admin/paymenttemplates/:templateId/update"
        component={UpdatePaymentTemplatePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `payments-templates:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/admin/paymenttemplates/:templateId"
        component={PreviewPaymentTemplatePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `system-roles:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/admin/system-roles"
        component={SystemRolesPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `system-roles:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/admin/system-roles/add"
        component={AddSystemRolePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `system-roles:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/admin/system-roles/:roleId"
        component={PreviewSystemRolesPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `system-roles:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/admin/system-roles/:roleId/update"
        component={UpdateSystemRolesPage}
      />
      {/* franchise routes */}
      <PrivateRoute
        exact={true}
        permission={[`corporate-manager:*`]}
        authenticated={accessToken}
        path="/admin/franchises"
        component={AllFranchisesPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`corporate-manager:*`]}
        authenticated={accessToken}
        path="/admin/franchises/add"
        component={AddFranchisePage}
      />
      <PrivateRoute
        exact={true}
        permission={[`corporate-manager:*`]}
        authenticated={accessToken}
        path="/admin/franchises/:id"
        component={FranchisePage}
      />
      <PrivateRoute
        exact={true}
        permission={[`corporate-manager:*`]}
        authenticated={accessToken}
        path="/admin/franchises/:id/update"
        component={UpdateFranchisePage}
      />
      {/* franchise locations */}
      <PrivateRoute
        exact={true}
        permission={[`corporate-manager:*`]}
        authenticated={accessToken}
        path="/admin/franchises/:fId/location/add"
        component={AddLocationPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`corporate-manager:*`]}
        authenticated={accessToken}
        path="/admin/franchises/:fId/location/:lId/update"
        component={UpdateLocationPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`corporate-manager:*`]}
        authenticated={accessToken}
        path="/admin/franchises/:fId/location/:lId"
        component={LocationPage}
      />
      {/* franchise agents */}
      <PrivateRoute
        exact={true}
        permission={[`corporate-manager:*`]}
        authenticated={accessToken}
        path="/admin/franchises/:fId/agents/add"
        component={AddAgentPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`corporate-manager:*`]}
        authenticated={accessToken}
        path="/admin/franchises/:fId/agents/:agentId/update"
        component={UpdateAgentPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`corporate-manager:*`]}
        authenticated={accessToken}
        path="/admin/franchises/:fId/agents/:agentId"
        component={AgentPage}
      />
      {/* location routes */}
      <PrivateRoute
        exact={true}
        permission={[`locations:create`, `corporate-manager:*`, `manager:*`]}
        authenticated={accessToken}
        path="/admin/locations/add"
        component={AddLocationPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`locations:update`, `corporate-manager:*`, `manager:*`]}
        authenticated={accessToken}
        path="/admin/locations/:lId/update"
        component={UpdateLocationPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`locations:read`, `corporate-manager:*`, `manager:*`]}
        authenticated={accessToken}
        path="/admin/locations/:lId"
        component={LocationPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`locations:read`, `corporate-manager:*`, `manager:*`]}
        authenticated={accessToken}
        path="/admin/locations"
        component={AllLocationPage}
      />
      {/*claims */}
      <PrivateRoute
        exact={true}
        permission={[
          `claims:read`,
          `claims:update`,
          `claims:delete`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/admin/claims"
        component={AllClaimsPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `claims:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/admin/claims/:cId"
        component={ClaimPage}
      />
      {/* Agents routes */}
      <PrivateRoute
        exact={true}
        permission={[
          `users:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
          `car-dealer-manager:*`
        ]}
        authenticated={accessToken}
        path="/admin/agents"
        component={AllAgentsPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `users:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
          `car-dealer-manager:*`
        ]}
        authenticated={accessToken}
        path="/admin/agents/add"
        component={AddAgentPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `users:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
          `car-dealer-manager:*`
        ]}
        authenticated={accessToken}
        path="/admin/agents/:agentId"
        component={AgentPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `users:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
          `car-dealer-manager:*`
        ]}
        authenticated={accessToken}
        path="/admin/agents/:agentId/update"
        component={UpdateAgentPage}
      />


      {/* Customers Routes */}
      <PrivateRoute
        exact={true}
        permission={[
          `customers:read`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers"
        component={AllCustomersPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `customers:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/add"
        component={AddCustomerPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `customers:read`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId"
        component={CustomerPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `policies:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/policies/:policyId"
        component={PolicyPreviewPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `policies:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/policies/:policyId/update"
        component={PolicyUpdatePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `customers:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/update"
        component={UpdateCustomersPage}
      />
      {/* Contact Routes */}
      <PrivateRoute
        exact={true}
        permission={[
          `contacts:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/contact/add"
        component={AddContactPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `contacts:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/contact/:contactId"
        component={ContactPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `contacts:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/contact/:contactId/update"
        component={UpdateContactPage}
      />
      {/* Asset routes */}
      <PrivateRoute
        exact={true}
        permission={[
          `assets:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/asset/vehicle/add"
        component={AddVehiclePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `assets:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/asset/vehicle/:vehicleId"
        component={VehiclePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `assets:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/asset/vehicle/:vehicleId/update"
        component={UpdateVehiclePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `assets:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/asset/driver/add"
        component={AddDriverPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `assets:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/asset/driver/:driverId"
        component={DriverPreviewPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `assets:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/asset/driver/:driverId/update"
        component={UpdateDriverPage}
      />
      {/* carrier  */}
      <PrivateRoute
        exact={true}
        permission={[`carriers:read`, `credential-master:*`]}
        authenticated={accessToken}
        path="/carriers"
        component={AllCarrierPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`carriers:create`, `credential-master:*`]}
        authenticated={accessToken}
        path="/carriers/add"
        component={AddCarrierPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`carriers:read`, `credential-master:*`]}
        authenticated={accessToken}
        path="/carriers/:carrierId"
        component={CarrierPreviewPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`carriers:update`, `credential-master:*`]}
        authenticated={accessToken}
        path="/carriers/:carrierId/update"
        component={UpdateCarrierPage}
      />
      {/* imported Carrier */}
      <PrivateRoute
        exact={true}
        permission={[`carriers:read`, `credential-master:*`]}
        authenticated={accessToken}
        path="/imported-carriers"
        component={ImportedCarrierPage}
      />
      {/* {group carriers} */}
      <PrivateRoute
        exact={true}
        permission={[`carriers-group:read`]}
        authenticated={accessToken}
        path="/group-carriers"
        component={AllGroupCarrierPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`carriers-group:create`]}
        authenticated={accessToken}
        path="/group-carriers/add"
        component={AddGroupCarrierPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`carriers-group:read`]}
        authenticated={accessToken}
        path="/group-carriers/:groupCarrierId"
        component={GroupCarrierPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`carriers-group:update`]}
        authenticated={accessToken}
        path="/group-carriers/:groupCarrierId/update"
        component={UpdateGroupCarrierPage}
      />
      {/* Credentials */}
      <PrivateRoute
        exact={true}
        permission={[
          `credentials-group:read`,
          `credentials-group:*`,
          `credential-master:*`,
        ]}
        authenticated={accessToken}
        path="/admin/group-credentials"
        component={AllGroupCredentialsPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `credentials-group:create`,
          `credentials-group:*`,
          `credential-master:*`,
        ]}
        authenticated={accessToken}
        path="/admin/group-credentials/add"
        component={AddGroupCredentialPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `credentials-group:read`,
          `credentials-group:*`,
          `credential-master:*`,
        ]}
        authenticated={accessToken}
        path="/admin/group-credentials/:groupCredentialId"
        component={GroupCredentialPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `credentials-group:update`,
          `credentials-group:*`,
          `credential-master:*`,
        ]}
        authenticated={accessToken}
        path="/admin/group-credentials/:groupCredentialId/update"
        component={UpdateGroupCredentialPage}
      />
      {/* policies */}
      <PrivateRoute
        exact={true}
        permission={[
          `policies:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/policy"
        component={AllPoliciesPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `policies:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/policies/requests/:requestId"
        component={RequestPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `policies:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/policies/requests"
        component={AllRequestsPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `policies:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/policy/add"
        component={AddPolicyPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `policies:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/policies/add"
        component={AddPolicyPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `policies:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/policy/:policyId"
        component={PolicyPreviewPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `policies:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/policy/:policyId/update"
        component={PolicyUpdatePage}
      />
      {/* payments */}
      <PrivateRoute
        exact={true}
        permission={[
          `payments:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/addPayment"
        component={AddPaymentPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `payments:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/payments/add-receipt/:paymentId"
        component={AddReceiptPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `payments:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/payments/:paymentId"
        component={PreviewPaymentPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `payments:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/customers/:customerId/payments/:paymentId/update"
        component={UpdatePaymentPage}
      />
      {/* Reports Routes */}
      <PrivateRoute
        exact={true}
        permission={[
          `accountant-master:*`,
          `accountant:*`,
          `corporate-manager:*`,
          `manager:*`,
        ]}
        authenticated={accessToken}
        path="/reports/daily-ledger"
        component={DailyLedgerPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`accountant-master:*`]}
        authenticated={accessToken}
        path="/reports/policy-reports"
        component={PoliciesReportsPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`accountant-master:*`]}
        authenticated={accessToken}
        path="/reports/payment-records"
        component={PaymentRecordsPage}
      />
      <Route path="/auth/oidc-redirect">
        <OidcRedirectPage />
      </Route>
      <Route path="/google/authorization">
        <GoogleAuthorizedPage />
      </Route>
      {/* Templates */}
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/templatesfiles"
        component={AllTemplatePage}
        permission={[
          `pdf-templates:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      {/* communications */}
      <PrivateRoute
        exact={true}
        permission={[
          `global-communication:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/communications"
        component={CommunicationCenter}
      />

      <PrivateRoute
        exact={true}
        permission={[`super-admin:*`]}
        authenticated={accessToken}
        path="/admin/cs-logs"
        component={CommunicationLogsPage}
      />
      {/* AQS */}
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/raters/myquotes"
        permission={[
          `quotes:read`,
          `quotes:create`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        component={MyQuotesPage}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/raters/myquotes/:quoteId/update"
        permission={[
          `quotes:read`,
          `quotes:create`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        component={QuoteSoldPage}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/raters/myquotes/:quoteId"
        permission={[
          `quotes:read`,
          `quotes:create`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        component={QuotePreviewPage}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/raters/quickestimate"
        permission={[
          `quotes-estimate:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        component={QuickEstimatePage}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/raters/ratingtemplates"
        component={AllRatingTemplatePage}
        permission={[
          `rating-templates:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/raters/ratingtemplates/add"
        component={AddRatingTemplatePage}
        permission={[
          `rating-templates:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/raters/ratingtemplates/:state/update"
        component={UpdateRatingTemplatePage}
        permission={[
          `rating-templates:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      {/* Preferences Routes */}
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/raters/preferences"
        permission={[
          `quotes:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        component={PreferencePage}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/raters/preferences/update"
        component={UpdatePreferencesPage}
        permission={[
          `quotes:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      {/* Quote Form Page */}
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/raters/quoteform"
        permission={[
          `quotes:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        component={QuoteFormPage}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/raters/myquotes/:quoteId/rerate"
        permission={[
          `quotes:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        component={ReRateQuoteFormPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `quotes:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/raters/quoteform/offers"
        component={QuoteOffersPage}
      />
      {/* communications */}
      <PrivateRoute
        exact={true}
        permission={[
          `global-communication:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/communications"
        component={CommunicationCenter}
      />
      {/* Broadcasting */}
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/broadcasts/templates"
        component={AllBroadCastingTemplatesPage}
        permission={[
          `broadcast-templates:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/broadcasts/templates/:templateId"
        component={BroadcastTemplatePreview}
        permission={[
          `broadcast-templates:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/broadcasts/campaigns"
        component={AllCampaignGroupsPage}
        permission={[
          `broadcasts:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/broadcasts/campaigns/groupsdetail/:campaignid"
        component={AllCampaignGroupsDetailPage}
        permission={[
          `broadcasts:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/broadcasts/campaigns/groupsdetail/:campaignid/:logId"
        component={CampaignPreviewPage}
        permission={[
          `broadcasts:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      {/* crm */}
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/crm/leads"
        component={AllLeadsPage}
        permission={[
          `leads:read`,
          `car-dealer-agent:*`,
          `car-dealer-manager:*`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/crm/leads/add"
        component={AddLeadsPage}
        permission={[
          `leads:create`,
          `car-dealer-agent:*`,
          `car-dealer-manager:*`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/crm/leads/:leadId"
        component={LeadsPreviewPage}
        permission={[
          `leads:read`,
          `car-dealer-agent:*`,
          `car-dealer-manager:*`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/crm/dataTransactions"
        component={AllDataTransactionsPage}
        permission={[
          `data-transaction:read`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/crm/dataTransactions/:dataTransactionId"
        component={PreviewDataTransactionPage}
        permission={[
          `data-transaction:read`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      {/* Car Dealers Pages */}
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/crm/cardealers"
        component={AllCarDealersPage}
        permission={[`car-dealer:read`]}
      />

      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/crm/cardealers/add"
        component={AddCarDealerPage}
        permission={[`car-dealer:create`]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/crm/cardealers/:dealerId"
        component={CarDealerPage}
        permission={[`car-dealer:read`]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/crm/cardealers/:dealerId/update"
        component={UpdateCarDealerPage}
        permission={[`car-dealer:update`]}
      />
      {/* Credential Pages */}
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/admin/credentials"
        component={AllCredentialPage}
        permission={[
          `credentials:read`,
          `credential-master:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/admin/credentials/add"
        component={AddCredentialPage}
        permission={[`super-admin:*`, `credential-master:*`]}
      // permission={[]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/admin/credentials/:credentialId"
        component={PreviewCredentialPage}
        permission={[
          `credentials:read`,
          `credential-master:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/admin/credentials/:credentialId/update"
        component={UpdateCredentialPage}
        permission={[
          `credentials:update`,
          `credential-master:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      {/* scarping-credentials */}
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/admin/scarping-credentials"
        component={AllScrapingCredentialsPage}
        permission={[
          `credential-master:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/admin/scarping-credentials/add"
        component={AddScrapingCredentialsPage}
        permission={[
          `credentials:update`,
          `credential-master:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/transaction/transactions-history"
        component={AllTransactionsPage}
        permission={[
          `call-center-user:*`,
          `payment-history:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/transaction/transactions-history/:transactionId"
        component={PreviewTransactionPage}
        permission={[
          `payment-history:read`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/transaction/inspection"
        component={TransactionInspectionPage}
        permission={[
          `monthly-payments:*`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/globalSearch/result"
        component={GlobalSearch}
        permission={[
          `global-search:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/admin/monthlygoals"
        component={AllMonthlyGoalsPage}
        permission={[
          `monthly-goals:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/admin/monthlygoals/add"
        component={AddMonthlyGoalsPage}
        permission={[
          `monthly-goals:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/admin/monthlygoals/:id/update"
        component={UpdateMonthlyGoalsPage}
        permission={[
          `monthly-goals:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/admin/monthlygoals/:id"
        component={MonthlyGoalsPage}
        permission={[
          `monthly-goals:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/admin/designation"
        component={AllDesignation}
        permission={[
          `designation:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/messenger"
        component={MessengerPage}
        permission={[
          `messenger:read`,
          `dashboard:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `evaluate:read`,
          `evaluate:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/assessment/evaluation/:id"
        component={EvaluatePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `evaluate:read`,
          `evaluate:create`,
          `evaluate:dashboard`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/assessment/analytics"
        component={AssessmentDashboardPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `assessment-team:create`,
          `assessment-team:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/assessment/teams"
        component={TeamsPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `assessment-template:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/assessment/template"
        component={AllTemplatesPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `assessment-template:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/assessment/template/add"
        component={AddTemplatePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `assessment-template:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/assessment/template/:assessmentTemplateId/update"
        component={UpdateTemplatePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `assessment-template:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/assessment/template/:assessmentTemplateId"
        component={PreviewTemplatesPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `evaluate:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/assessment/weeks"
        component={AllAssessmentsPage}
      />
      <PrivateRoute
        exact={true}
        permission={[`corporate-manager:*`, `manager:*`, `admin:*`]}
        authenticated={accessToken}
        path="/assessment/weeks/super/:weeklyAssessmentId"
        component={AllAssessments}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `evaluate:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/assessment/weeks/:aId"
        component={AssessmentPreviewPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `evaluate:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/assessment/weeks/:aId/evaluations/:eId"
        component={EvaluatePage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `evaluate:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/assessment/weeks/:aId/evaluations/:eId/record"
        component={EvaluationReportPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `ticket:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/ticketscenter"
        component={AllTicketsPage}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/tickets/category"
        component={AllTicketCategoryPage}
        permission={[
          `ticket-category:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/tickets/category/:ticketCategoryId"
        component={TicketCategoryPreview}
        permission={[
          `ticket-category:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `ticket:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/ticketscenter/add"
        component={AddTicketPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `ticket:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/ticketscenter/:id"
        component={TicketPreviewPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `task:read`,
          `task:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/taskmanager"
        component={AllTasksPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `task:create`,
          `task:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/taskmanager/add"
        component={AddTaskPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `task:read`,
          `task:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/taskmanager/:id"
        component={TaskPreviewPage}
      />
      {/* Other Customers Routes */}
      <PrivateRoute
        exact={true}
        permission={[
          `other-customers:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/other-customers"
        component={AllOtherCustomersPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `other-customers:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/other-customers/:customerId"
        component={OtherCustomerPage}
      />
      <PrivateRoute
        exact={true}
        permission={[
          `other-customers:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]}
        authenticated={accessToken}
        path="/other-customers/:customerId/other-policies/:policyId"
        component={OtherPolicyPreviewPage}
      />
      {!accessToken ? (
        <Redirect from="/" exact={true} to="/dashboard" />
      ) : (
        <Redirect from="/" exact={true} to="/login" />
      )}
      <Route path="/404">
        <NotFoundPage />
      </Route>
      <Route path="*" render={() => <Redirect to="/404" />} />
    </Switch>
  )
}
