import {
  faArrowAltCircleRight,
  faBox,
  faClipboardList,
  faFolder,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setTaskActivityActiveTab } from 'App/Redux/actions'
import { permissionGranted } from 'App/Services'
import { ActivityWrapper } from 'App/Styled'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AboutTask } from './About'
import { TaskActivity } from './Activity'
import { ACTIVITY_TABS_NAME } from 'App/constants'

export const TaskPreview = () => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const dispatch = useDispatch()

  return (
    <div className="flex justify-end">
      <div className={`${isCollapsed ? `w-full` : `w-2/3`}`}>
        <AboutTask />
      </div>
      <ActivityWrapper collapsed={isCollapsed.toString()}>
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="px-2 py-1 "
        >
          {/* <CollapseClip> */}
          <FontAwesomeIcon
            icon={faArrowAltCircleRight}
            className={`text-red-500 my-0.5  cursor-pointer transform transition duration-500 ${
              isCollapsed ? `rotate-180 ` : `rotate-0`
            }`}
          />
          {/* </CollapseClip> */}
        </div>
        {!isCollapsed ? (
          <TaskActivity />
        ) : (
          <div
            className="text-center flex flex-col"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {permissionGranted([`notes:read`]) &&
            !permissionGranted([`uploadFile:read`]) ? (
              <>
                <div
                  data-tip={ACTIVITY_TABS_NAME.NOTES}
                  className="mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setTaskActivityActiveTab(1))}
                >
                  <FontAwesomeIcon
                    icon={faClipboardList}
                    className="text-red-500"
                  />
                </div>
                <div
                  data-tip={ACTIVITY_TABS_NAME.LOGS}
                  className="mx-5 py-3  cursor-pointer tooltip tooltip-left "
                  onClick={() => dispatch(setTaskActivityActiveTab(2))}
                >
                  <FontAwesomeIcon icon={faBox} className="text-red-500" />
                </div>
              </>
            ) : permissionGranted([`uploadFile:read`]) &&
              !permissionGranted([`notes:read`]) ? (
              <>
                <div
                  data-tip={ACTIVITY_TABS_NAME.LOGS}
                  className="mt-2 mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setTaskActivityActiveTab(1))}
                >
                  <FontAwesomeIcon icon={faBox} className="text-red-500" />
                </div>
                <div
                  data-tip={ACTIVITY_TABS_NAME.MEDIA}
                  className="mt--1 mx-5 py-3 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setTaskActivityActiveTab(2))}
                >
                  <FontAwesomeIcon icon={faFolder} className="text-red-500" />
                </div>
              </>
            ) : permissionGranted(
                [
                  `notes:read`,
                  `corporate-manager:*`,
                  `manager:*`,
                  `admin:*`,
                ] && [
                  `uploadFile:read`,
                  `corporate-manager:*`,
                  `manager:*`,
                  `admin:*`,
                ],
              ) ? (
              <>
                <div
                  data-tip={ACTIVITY_TABS_NAME.NOTES}
                  className="mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setTaskActivityActiveTab(1))}
                >
                  <FontAwesomeIcon
                    icon={faClipboardList}
                    className="text-red-500"
                  />
                </div>
                <div
                  data-tip={ACTIVITY_TABS_NAME.LOGS}
                  className="mt-2 mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setTaskActivityActiveTab(2))}
                >
                  <FontAwesomeIcon icon={faBox} className="text-red-500" />
                </div>
                <div
                  data-tip={ACTIVITY_TABS_NAME.MEDIA}
                  className=" mx-5 py-3 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setTaskActivityActiveTab(3))}
                >
                  <FontAwesomeIcon icon={faFolder} className="text-red-500" />
                </div>
              </>
            ) : (
              <>
                <div
                  data-tip={ACTIVITY_TABS_NAME.LOGS}
                  className="-mt-2 mx-5 py-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setTaskActivityActiveTab(1))}
                >
                  <FontAwesomeIcon icon={faBox} className="text-red-500" />
                </div>
              </>
            )}
          </div>
        )}
      </ActivityWrapper>
    </div>
  )
}
