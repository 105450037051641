import React, { Suspense, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setAuthorizationCode, setThreads } from 'App/Redux/actions'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import Mylogo from 'App/Assets/lotties/customLoader.json'
import { NotificationManager } from 'react-notifications'


const Lottie = React.lazy(() => import(`lottie-react`))

export const GoogleAuthorizedPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentCustomer = useSelector(
    ({ customer: { currentCustomer } }) => currentCustomer,
  )
  const { profile } = useSelector(({ user: { user } }) => user)

  const { mutate, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/communication/gmail/create-token`,
        {
          ...payload,
        },
      ).then((res) => {
        return res
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        if (res) {
          dispatch(setThreads(res.data.data))
          history.push(`/customers/${currentCustomer._id}`)
        }
      },
    },
  )

  useEffect(() => {
    if (process.browser && window.location.search.includes(`code`)) {
      const code = window.location.search.split(`&`)[0].split(`=`)[1]
      if (code && profile) {
        dispatch(setAuthorizationCode(code))
        mutate({
          code: code,
          userId: profile?.sub,
        })
      } else {
        history.push(`/404`)
      }
    } else {
      history.push(`/404`)
    }
  }, [process.browser])

  useEffect(() => {
    if (isError) {
      NotificationManager.error(`Could not get Threads`, `Error`)
      dispatch(setAuthorizationCode(null))
      let redirectToCustomer = setTimeout(
        () => history.push(`/customers/${currentCustomer._id}`),
        1000,
      )

      return () => {
        clearTimeout(redirectToCustomer)
      }
    }
  }, [isError])

  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <Suspense fallback={``}>
        <Lottie
          animationData={Mylogo}
          loop={true}
          autoplay={true}
          className="h-40"
        />
      </Suspense>
      <p className="-bottom-44 text-xl font-bold capitalize">
        Please wait, getting your conversations from the gmail
      </p>
    </div>
  )
}
