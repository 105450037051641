import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const PaymentRecords = React.lazy(dynamicImportComponents[`/reports/payment-records`])

export const PaymentRecordsPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <PaymentRecords />
  </Suspense>
)
