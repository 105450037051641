/* eslint-disable no-unused-vars */
import { Card } from 'App/Components/Common/Card'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { useHistory, useLocation } from 'react-router-dom'
import { Reload } from 'App/Components/Common/Reload'
import InfiniteScroll from 'react-infinite-scroller'
import { NotificationManager } from 'react-notifications'
import { permissionGranted } from 'App/Services'
import { NotificationCardkelton } from './notificationCardSkelton'
import { setGeneralNotificationsLength } from 'App/Redux/Slices/Notifications'
import { ReactModal } from 'App/Components/Common/Modal'
import { timeFormatPST } from 'App/Services/General/dateFormat'
export const GeneralNotification = () => {
  const dispatch = useDispatch()
  const { generalNotificationsLength } = useSelector(
    ({ notification }) => notification,
  )

  const history = useHistory()
  const [currentIndex, setCurrentIndex] = useState(``)
  const [currentPage, setCurrentPage] = useState(1)
  const [notificationData, setNotificationData] = useState(``)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const [generalNotificationsList, setGeneralNotificationsList] = useState([])
  const {
    isLoading,
    data: _generalNotificationData,
    refetch: getAllGeneralNotifications,
    isRefetching,
    error,
  } = useQuery(
    `GeneralNotificationApi${currentPage}`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/general-notifications/unread/all?page=${currentPage}&offset=15`,
      )
        .then((res) => {
          dispatch(setGeneralNotificationsLength(res.data.data.unreadRecords))
          return res.data.data
        })
        .catch((err) => {
          if (err) NotificationManager.error(`Something went wrong`)
        }),
    {
      // enabled: generalNotificationsLength > 0,
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setGeneralNotificationsList((prev) => [
          ...prev,
          ...res?.generalNotification,
        ])
      },
    },
  )

  useEffect(() => {
    currentPage > 1 && getAllGeneralNotifications()
  }, [currentPage])

  const loadMoreNotifications = () => {
    if (!isLoading && !isRefetching) {
      setCurrentPage(currentPage + 1)
      // getAllGeneralNotifications();
    }
  }

  const { mutate: readNotification, isLoading: readingNotification } =
    useMutation(
      async (payload) => {
        // if (!payload.isAlreadyRead || payload.isDeleted) {
        await AxiosInstance.patch(
          `${AMS_BACKEND_API}/api/general-notifications/${payload.id}`,
          {
            isRead: payload.isRead,
            isDeleted: payload.isDeleted,
          },
        ).then(() => {
          setCurrentIndex(``)
          setGeneralNotificationsList([])
          // setGeneralNotificationsList((prev) =>
          //   prev.filter(
          //     (notification) =>
          //       notification.userNotificationId !== payload.id,
          //   ),
          // )
          // getAllGeneralNotifications()
          // dispatch(setLatestLength(latestLength - 1))
        })
        // }
      },
      {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: () => {
          getAllGeneralNotifications()
        },
      },
    )

  return (
    <div className="w-full">
      {error ? (
        <Reload refetch={() => getAllGeneralNotifications()} />
      ) : (
        <>
          {isModalVisible && (
            <ReactModal
              title="General Notification Info"
              closeModal={(e) => {
                e.stopPropagation()
                setIsModalVisible(false)
              }}
              closeButton={false}
            >
              <div className="w-96 text-black p-2 text-md pb-4">
                <p className="flex justify-end items-center text-gray-400 text-2xs mb-2">
                  <p>{timeFormatPST(notificationData.createdAt)}</p>
                </p>
                <p className="text-sm text-gray-500 font-semibold mb-1 mt-2">
                  Title:
                </p>
                <p className="bg-gray-100 p-2 rounded-md text-sm">
                  {notificationData.title}
                </p>
                <p className="text-sm text-gray-500 font-semibold mb-1 mt-2">
                  Description:
                </p>
                <p className="bg-gray-100 p-2 rounded-md text-sm h-48 overflow-y-auto">
                  {notificationData.description}
                </p>
              </div>
            </ReactModal>
          )}
          <div className="w-full py-1 gap-1 px-1 h-72  overflow-y-scroll hide-scroller hide-scroller-firefox ">
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMoreNotifications}
              hasMore={
                generalNotificationsList?.length > 0 &&
                generalNotificationsList?.length <
                  _generalNotificationData?.totalRecords
              }
              threshold={192}
              loader={<NotificationCardkelton />}
              useWindow={false}
            >
              {generalNotificationsList?.length < 1 ? (
                isLoading || isRefetching ? (
                  <NotificationCardkelton />
                ) : (
                  <div className="text-center text-xs font-md text-gray-500 my-2">
                    No General Notifications Available.
                  </div>
                )
              ) : (
                generalNotificationsList?.map((notification, index) => (
                  <div
                    key={index}
                    className="cursor-pointer"
                    onClick={() => {
                      readNotification({
                        id: notification.userNotificationId,
                        isRead: true,
                        isAlreadyRead: notification.isRead,
                      })
                      setNotificationData(notification)
                      setIsModalVisible(true)
                    }}
                  >
                    <Card
                      title={notification.title}
                      message={notification.description}
                      // source={notification.status}
                      createdAt={notification.createdAt}
                      deleteCard={() => {
                        readNotification({
                          id: notification.userNotificationId,
                          isDeleted: true,
                          isRead: true,
                          isAlreadyRead: notification.isRead,
                        })
                      }}
                      index={index}
                      isDeleting={readingNotification || isRefetching}
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      readCard={!notification?.isRead}
                      isGeneralNotification={true}
                    />
                  </div>
                ))
              )}
            </InfiniteScroll>
          </div>
        </>
      )}
    </div>
  )
}
