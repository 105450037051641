import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports';

const AllPolicies = React.lazy(dynamicImportComponents[`/policy`]);

export const AllPoliciesPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllPolicies />
  </Suspense>
)

