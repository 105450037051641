import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const GeneralNotifications = React.lazy(dynamicImportComponents[`/admin/general-notifications`])

export const GeneralNotificationsPage = () => (
    <Suspense fallback={<Spinner loading />}>
        <GeneralNotifications />
    </Suspense>
)
