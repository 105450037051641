import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import {
  Container,
  PageHeader,
  SecondaryHeading,
  SubmitButton,
} from 'App/Styled'
import { MaskField } from 'App/Components/Common/MaskField'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import {
  loadGenderOptions,
  loadZipCodes,
  ValidateDuplicateAgentEmail,
  ValidateZip,
} from 'App/Services'
import { Spinner } from 'App/Components/Common/Spinner'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API } from '../../../../Config/backendApis'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { AxiosInstance, LEAFLET_URL, regEx } from 'App/Config'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications'

export const UpdateCarDealer = () => {
  const history = useHistory()

  const {
    params: { dealerId },
  } = useRouteMatch()

  const { currentDealer } = useSelector(({ carDealer }) => carDealer)

  const [locZipValidating, setLocZipValidating] = useState(false)
  const [agentEmailValidating, setAgentEmailValidating] = useState(false)
  const [agentPhoneValidating, setAgentPhoneValidating] = useState(false)

  const [businessName, setbusinessName] = useState(``)
  const [carDealerInfo, setCarDealerInfo] = useState({
    email: ``,
    phone: ``,
    fax: ``,
  })

  const [address, setAddress] = useState({
    unitNo: ``,
    street: ``,
    city: ``,
    state: ``,
    zip: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const [mapCoordinates, setMapCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const [contactPerson, setContactPerson] = useState({
    firstName: ``,
    middleName: ``,
    lastName: ``,
    gender: ``,
  })

  const [contactPersonInfo, setContactPersonInfo] = useState({
    email: ``,
    phone: ``,
    ext: ``,
    fax: ``,
  })

  const [comission, setComission] = useState({
    type: ``,
    rate: ``,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    businessNameError: ``,
    dealerEmailError: ``,
    dealerPhoneError: ``,
    dealerExtError: ``,
    dealerFaxError: ``,

    contactPersonFirstNameError: ``,
    contactPersonLastNameError: ``,

    contactPersonEmailError: ``,
    contactPersonPhoneError: ``,
    contactPersonExtError: ``,
    contactPersonFaxError: ``,
    genderError: ``,
    //    address
    zipError: ``,
    streetNoError: ``,
    cityError: ``,
    stateError: ``,
    coordinatesError: ``,

    typeError: ``,
    rateError: ``,
  })

  useEffect(() => {
    if (currentDealer) {
      setbusinessName(currentDealer?.businessName)

      setCarDealerInfo((prevState) => ({
        ...prevState,
        email: currentDealer?.contactInfo?.email,
        phone: currentDealer?.contactInfo?.phone,
        fax: currentDealer?.contactInfo?.fax,
      }))
      setAddress((prevState) => ({
        ...prevState,
        unitNo: currentDealer?.address?.unitNo,
        street: currentDealer?.address?.street,
        city: currentDealer?.address?.city,
        state: currentDealer?.address?.state,
        zip: {
          value: currentDealer?.address?.zip,
          label: currentDealer?.address?.zip,
        },
      }))
      setCoordinates((prevState) => ({
        ...prevState,
        lat: currentDealer?.address?.coordinates?.lat
          ? currentDealer?.address?.coordinates?.lat
          : 0,
        lng: currentDealer.address?.coordinates?.long
          ? currentDealer?.address?.coordinates?.long
          : 0,
      }))
      setMapCoordinates((prevState) => ({
        ...prevState,
        lat: currentDealer?.address?.coordinates?.lat
          ? currentDealer?.address?.coordinates?.lat
          : 0,
        lng: currentDealer.address?.coordinates?.long
          ? currentDealer?.address?.coordinates?.long
          : 0,
      }))

      setContactPerson((prevState) => ({
        ...prevState,
        firstName: currentDealer?.contactPerson?.firstName,
        middleName: currentDealer?.contactPerson?.middleName,
        lastName: currentDealer?.contactPerson?.lastName,
        gender: {
          value: currentDealer?.contactPerson?.gender ?? ``,
          label: currentDealer?.contactPerson?.gender ?? ``,
        },
      }))
      setContactPersonInfo((prevState) => ({
        ...prevState,
        email: currentDealer?.contactPerson?.contactInfo?.email,
        phone: currentDealer?.contactPerson?.contactInfo?.phone,
        // ext: currentDealer?.contactPerson?.contactInfo?.ext,
        fax: currentDealer?.contactPerson?.contactInfo?.fax,
      }))
      setComission((prevState) => ({
        ...prevState,

        type: {
          value: currentDealer?.commission?.type,
          label:
            currentDealer?.commission?.type === `FLAT_RATE`
              ? `Flat`
              : currentDealer?.commission?.type === `PERCENTAGE`
              ? `%`
              : ``,
        },
        rate: currentDealer?.commission?.rate.toString(),
      }))
    }
  }, [currentDealer])

  const loadComissionTypes = () => {
    const options = {
      options: [
        {
          value: `PERCENTAGE`,
          label: `%`,
        },
        {
          value: `FLAT_RATE`,
          label: `Flat`,
        },
      ],
      hasMore: false,
    }
    return options
  }

  const {
    mutate,
    isLoading,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/car-dealers/${dealerId}`,
        {
          ...payload,
        },
      )
        .then((res) => {
          if (res.data) {
            res.data && history.push(`/crm/cardealers/${dealerId}`)
          }
        })
        .catch((err) => {
          if (
            err?.response &&
            err.response.data.error.error.includes(
              `Incoming request body can't be empty.`,
            )
          ) {
            NotificationManager.error(`No field Changed`)
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const validateAgentEmail = async (e) => {
    const { value } = e.target
    if (value?.length > 0) {
      setAgentEmailValidating(true)
      const response = await ValidateDuplicateAgentEmail({ email: value })
      if (response?.hasError) {
        setAgentEmailValidating(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`contactPersonEmailError`, `Email Already Exists`)
      } else {
        if (!regEx.test(value.toLowerCase())) {
          setAgentEmailValidating(false)
          handleErrors(`formSubmit`, false)
          handleErrors(`contactPersonEmailError`, `Enter Valid Email`)
        } else {
          setAgentEmailValidating(false)
          handleErrors(`formSubmit`, false)
          handleErrors(`contactPersonEmailError`, ``)
        }
      }
    }
  }

  const validateAgentPhone = async (e) => {
    const { value } = e.target
    if (value && value?.length === 12) {
      setAgentPhoneValidating(true)
      const response = await ValidateDuplicateAgentEmail({ phone: value })
      if (response?.hasError) {
        setAgentPhoneValidating(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`contactPersonPhoneError`, `Phone Already Exists`)
      } else {
        setAgentPhoneValidating(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`contactPersonPhoneError`, ``)
      }
    }
  }

  const ValidateForm = () => {
    const nonNumericRegex = /^[0-9@#$%^&*()_+={}[\]:;<>,.?~\\-]+$/
    if (!businessName.length) {
      handleErrors(`businessNameError`, `Enter Bussiness`)
    } else if (nonNumericRegex.test(businessName)) {
      handleErrors(
        `businessNameError`,
        `Bussiness Name cannot contain numeric characters only`,
      )
    } else {
      handleErrors(`businessNameError`, ``)
    }

    if (!carDealerInfo?.email?.length) {
      handleErrors(`dealerEmailError`, `Enter Email`)
    } else if (!regEx.test(carDealerInfo?.email.toLowerCase())) {
      handleErrors(`dealerEmailError`, `Enter Valid Email`)
    } else {
      handleErrors(`dealerEmailError`, ``)
    }

    if (!carDealerInfo?.phone?.length) {
      handleErrors(`dealerPhoneError`, `Enter Phone`)
    } else if (
      carDealerInfo.phone !== currentDealer?.contactInfo?.phone &&
      carDealerInfo.phone.length < 12
    ) {
      handleErrors(`dealerPhoneError`, `Enter Valid Phone Number`)
    } else {
      handleErrors(`dealerPhoneError`, ``)
    }

    if (
      carDealerInfo.fax !== currentDealer?.contactInfo?.fax &&
      carDealerInfo.fax.length &&
      carDealerInfo.fax.length < 12
    ) {
      handleErrors(`dealerFaxError`, `Enter Valid Fax`)
    } else {
      handleErrors(`dealerFaxError`, ``)
    }

    if (carDealerInfo?.ext?.length) {
      if (isNaN(carDealerInfo.ext)) {
        handleErrors(`dealerExtError`, `Extension should be a number`)
      } else if (carDealerInfo.ext.length !== 4) {
        handleErrors(`dealerExtError`, `Extension should be 4 digits`)
      } else {
        handleErrors(`dealerExtError`, ``)
      }
    } else {
      handleErrors(`dealerExtError`, ``)
    }

    if (!address.zip?.length && !Object.keys(address.zip).length) {
      handleErrors(`zipError`, `Select Zip Code`)
    } else {
      handleErrors(`zipError`, ``)
    }

    if (!address?.street?.length) {
      handleErrors(`streetNoError`, `Enter Street`)
    } else {
      handleErrors(`streetNoError`, ``)
    }
    if (!contactPerson?.gender?.value?.length) {
      handleErrors(`genderError`, `Select gender`)
    } else {
      handleErrors(`genderError`, ``)
    }

    if (!address.city.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }

    if (!address.state.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }

    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, true)
    } else if (
      coordinates.lat !== mapCoordinates.lat ||
      coordinates.lng !== mapCoordinates.lng
    ) {
      handleErrors(`coordinatesError`, `Coordinates not matched`)
    } else {
      handleErrors(`coordinatesError`, false)
    }

    if (!contactPerson.firstName.length) {
      handleErrors(`contactPersonFirstNameError`, `Enter First Name`)
    } else if (nonNumericRegex.test(contactPerson.firstName)) {
      handleErrors(
        `contactPersonFirstNameError`,
        `First Name cannot contain numeric characters only`,
      )
    } else {
      handleErrors(`contactPersonFirstNameError`, ``)
    }

    if (!contactPerson.lastName?.length) {
      handleErrors(`contactPersonLastNameError`, `Enter Last Name`)
    } else if (nonNumericRegex.test(contactPerson.lastName)) {
      handleErrors(
        `contactPersonLastNameError`,
        `Last Name cannot contain numeric characters only`,
      )
    } else {
      handleErrors(`contactPersonLastNameError`, ``)
    }

    if (!contactPersonInfo.email?.length) {
      handleErrors(`contactPersonEmailError`, `Enter Email`)
    } else if (!regEx.test(contactPersonInfo?.email.toLowerCase())) {
      handleErrors(`contactPersonEmailError`, `Enter Valid Email`)
    } else {
      if (errors.contactPersonEmailError !== `Email Already Exists`) {
        handleErrors(`contactPersonEmailError`, ``)
      }
    }

    if (!contactPersonInfo.phone?.length) {
      handleErrors(`contactPersonPhoneError`, `Enter Phone`)
    } else if (
      contactPersonInfo.phone !==
        currentDealer?.contactPerson?.contactInfo?.phone &&
      contactPersonInfo.phone?.length < 12
    ) {
      handleErrors(`contactPersonPhoneError`, `Enter Valid Phone Number`)
    } else {
      if (errors.contactPersonPhoneError !== `Phone Already Exists`) {
        handleErrors(`contactPersonPhoneError`, ``)
      }
    }

    if (contactPersonInfo?.ext?.length) {
      if (isNaN(contactPersonInfo.ext)) {
        handleErrors(`contactPersonExtError`, `Extension should be a number`)
      } else if (contactPersonInfo?.ext?.length !== 4) {
        handleErrors(`contactPersonExtError`, `Extension should be 4 digits`)
      } else {
        handleErrors(`contactPersonExtError`, ``)
      }
    } else {
      handleErrors(`contactPersonExtError`, ``)
    }

    if (
      contactPersonInfo?.fax?.length &&
      contactPersonInfo?.fax !==
        currentDealer?.contactPerson?.contactInfo?.fax &&
      contactPersonInfo.fax?.length &&
      contactPersonInfo.fax?.length < 12
    ) {
      handleErrors(`contactPersonFaxError`, `Enter Valid Fax`)
    } else {
      handleErrors(`contactPersonFaxError`, ``)
    }

    if (!comission.type?.value?.length) {
      handleErrors(`typeError`, `Select Option`)
    } else {
      handleErrors(`typeError`, ``)
    }
    if (!comission.rate?.length) {
      handleErrors(`rateError`, `Enter Value`)
    } else if (comission.type?.value?.length) {
      if (
        comission.type?.value === `PERCENTAGE` &&
        parseInt(comission.rate) > 100
      ) {
        handleErrors(`rateError`, `Value Must be Upto 100`)
      } else if (comission?.rate < 0) {
        handleErrors(`rateError`, `Enter Valid Rate`)
      } else {
        handleErrors(`rateError`, ``)
      }
    } else {
      handleErrors(`rateError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.businessNameError.length &&
      !errors.dealerEmailError.length &&
      !errors.dealerPhoneError.length &&
      !errors.genderError.length &&
      !errors.dealerFaxError.length &&
      !errors.contactPersonFirstNameError.length &&
      !errors.contactPersonLastNameError.length &&
      !errors.contactPersonEmailError.length &&
      !errors.contactPersonPhoneError.length &&
      // !errors.contactPersonExtError.length &&
      !errors.contactPersonFaxError.length &&
      !errors.zipError.length &&
      !errors.streetNoError.length &&
      !errors.cityError.length &&
      !errors.stateError.length &&
      // !errors.coordinatesError &&
      !errors.typeError.length &&
      !errors.rateError.length
    ) {
      mutate(preparePayload())
      handleErrors(`formSubmit`, false)
    } else if (errors?.coordinatesError === `Coordinates not matched`) {
      NotificationManager.error(`Coordinates not matched`)
    }
  }, [errors])

  const preparePayload = () => {
    let data = {}

    // comission
    if (
      comission.type?.value !== currentDealer?.commission?.type ||
      comission.rate?.toString() !== currentDealer?.commission?.rate.toString()
    ) {
      data.commission = {}
      if (comission.type?.value !== currentDealer?.commission?.type) {
        data.commission.type = comission.type.value
      }
      if (
        comission.rate?.toString() !==
        currentDealer?.commission?.rate.toString()
      ) {
        data.commission.rate = comission.rate
      }
    }

    // Contact Person

    if (
      contactPerson.firstName !== currentDealer.contactPerson?.firstName ||
      contactPerson.middleName !== currentDealer?.contactPerson?.middleName ||
      contactPerson.lastName !== currentDealer.contactPerson?.lastName ||
      contactPerson.gender?.value !== currentDealer.contactPerson?.gender ||
      contactPersonInfo.email !==
        currentDealer.contactPerson.contactInfo?.email ||
      contactPersonInfo.phone !==
        currentDealer.contactPerson.contactInfo?.phone ||
      contactPersonInfo.fax !== currentDealer.contactPerson.contactInfo?.fax
    ) {
      data.contactPerson = {}
      let contactInfo = {}

      if (contactPerson.firstName !== currentDealer.contactPerson?.firstName) {
        data.contactPerson.firstName = contactPerson.firstName
      }
      if (
        contactPerson.middleName !== currentDealer?.contactPerson?.middleName
      ) {
        data.contactPerson.middleName = contactPerson.middleName
      }
      if (contactPerson.lastName !== currentDealer.contactPerson?.lastName) {
        data.contactPerson.lastName = contactPerson.lastName
      }
      if (contactPerson.gender.value !== currentDealer.contactPerson?.gender) {
        data.contactPerson.gender = contactPerson.gender.value
      }

      if (
        contactPersonInfo.email !==
        currentDealer.contactPerson.contactInfo?.email
      ) {
        contactInfo.email = contactPersonInfo.email
      }
      if (
        contactPersonInfo.phone !==
        currentDealer.contactPerson.contactInfo?.phone
      ) {
        contactInfo.phone = contactPersonInfo.phone
      }
      if (
        contactPersonInfo.fax !== currentDealer.contactPerson.contactInfo?.fax
      ) {
        contactInfo.fax = contactPersonInfo.fax
      }

      data.contactPerson.contactInfo = contactInfo
    }

    // contact Info
    if (
      carDealerInfo.phone !== currentDealer.contactInfo?.phone ||
      carDealerInfo.fax !== currentDealer?.contactInfo?.fax ||
      carDealerInfo.email !== currentDealer.contactInfo?.email
    ) {
      data.contactInfo = {}
      if (carDealerInfo.email !== currentDealer.contactInfo.email) {
        data.contactInfo.email = carDealerInfo.email
      }
      if (carDealerInfo.phone !== currentDealer?.contactInfo?.phone) {
        data.contactInfo.phone = carDealerInfo.phone
      }
      if (carDealerInfo.fax !== currentDealer?.contactInfo?.fax) {
        data.contactInfo.fax = carDealerInfo?.fax
      }
    }

    // Address

    const addressPayload = {}
    let coordinatesPayload = {}

    if (address.unitNo !== currentDealer?.address?.unitNo) {
      addressPayload.unitNo = address.unitNo
    }
    if (address.street !== currentDealer?.address?.street) {
      addressPayload.street = address.street
    }
    if (address.city !== currentDealer?.address?.city) {
      addressPayload.city = address.city
    }
    if (address.state !== currentDealer?.address?.state) {
      addressPayload.state = address.state
    }
    if (address.zip.value !== currentDealer?.address?.zip) {
      addressPayload.zip = address.zip.value
      coordinatesPayload.lat = coordinates.lat
      coordinatesPayload.long = coordinates.lng
    }

    if (Object.keys(addressPayload).length !== 0) {
      if (address.zip.value !== currentDealer?.address?.zip) {
        addressPayload.coordinates = coordinatesPayload
      }
      data.address = addressPayload
    }
    return data
  }

  const handleAddressInput = async (e, name) => {
    if (name) {
      setLocZipValidating(true)
      const data = await ValidateZip(e?.value)
      if (data) {
        setLocZipValidating(false)
        const res = data.address
        setAddress((prevState) => ({
          ...prevState,
          [`city`]: res.city,
          [`state`]: res.state,
        }))
        setAddress((prevState) => ({
          ...prevState,
          [name]: e,
        }))

        //setting coordinates
        setCoordinates((prevState) => ({
          ...prevState,
          [`lat`]: res.lat,
          [`lng`]: res.long,
        }))
        setMapCoordinates((prevState) => ({
          ...prevState,
          [`lat`]: res.lat,
          [`lng`]: res.long,
        }))
      }
    } else {
      const { name, value } = e.target

      setAddress((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  const handleCarDealerInfoChange = (e) => {
    const { name, value } = e.target
    setCarDealerInfo((preState) => ({
      ...preState,
      [name]: value,
    }))
  }
  const handleContactPersonChange = (e) => {
    const { name, value } = e.target
    setContactPerson((preState) => ({
      ...preState,
      [name]: value,
    }))
  }
  const handleContactPersonInfoChange = (e) => {
    const { name, value } = e.target
    setContactPersonInfo((preState) => ({
      ...preState,
      [name]: value,
    }))
  }
  const handleCommissionChange = (name, value) => {
    setComission((preState) => ({
      ...preState,
      [name]: value,
    }))
  }

  return (
    <>
      <Container>
        <PageHeader>
          <div>Update Car Dealer</div>
        </PageHeader>
        {updationError && (
          <ReactNotification action="error" message="Something went wrong" />
        )}
        <Spinner loading={isLoading} />
        <div
          className={`p-3 bg-white ${
            isLoading && `opacity-30 pointer-events-none`
          } `}
        >
          {/* Locations basic form */}
          <div className="flex gap-6 my-2">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Business Name"
                placeholder="Enter Name"
                value={businessName}
                errorMessage={errors.businessNameError}
                setValue={(e) => setbusinessName(e.target.value)}
                disabled={true}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="email"
                title="Email *"
                name="email"
                placeholder="Enter Email"
                errorMessage={errors.dealerEmailError}
                value={carDealerInfo.email}
                setValue={handleCarDealerInfoChange}
              />
            </div>
            <div className="flex-1 relative">
              <MaskField
                type="text"
                title="Phone *"
                name="phone"
                placeholder="Enter Phone No"
                errorMessage={errors.dealerPhoneError}
                value={carDealerInfo.phone}
                setValue={handleCarDealerInfoChange}
              />
            </div>
            {/* <div className="flex-1 relative">
              <InputField
                type="text"
                title="Extension"
                name="ext"
                placeholder="Enter Extension"
                errorMessage={errors.dealerExtError}
                value={carDealerInfo.ext}
                setValue={handleCarDealerInfoChange}
              />
            </div> */}
            <div className="flex-1 relative">
              <MaskField
                type="text"
                title="Fax"
                name="fax"
                placeholder="Enter Fax"
                errorMessage={errors.dealerFaxError}
                value={carDealerInfo.fax}
                setValue={handleCarDealerInfoChange}
              />
            </div>
          </div>

          <SecondaryHeading>Contact Person</SecondaryHeading>

          <div className="flex gap-6 my-1">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="First Name *"
                placeholder="Enter Name"
                name="firstName"
                errorMessage={errors.contactPersonFirstNameError}
                value={contactPerson.firstName}
                setValue={handleContactPersonChange}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Middle Name"
                placeholder="Enter Name"
                name="middleName"
                errorMessage={errors.contactPersonMiddleNameError}
                value={contactPerson.middleName}
                setValue={handleContactPersonChange}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Last Name *"
                placeholder="Enter Name"
                name="lastName"
                errorMessage={errors.contactPersonLastNameError}
                value={contactPerson.lastName}
                setValue={handleContactPersonChange}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Gender *"
                placeholder="Select gender"
                errorMessage={errors.genderError}
                value={contactPerson.gender}
                setValue={(value) => {
                  setContactPerson((preState) => ({
                    ...preState,
                    [`gender`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadGenderOptions}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="flex gap-6 my-1">
            <div className="flex-1 relative">
              <InputField
                type="email"
                title="Email *"
                name="email"
                placeholder="Enter Email"
                errorMessage={errors.contactPersonEmailError}
                value={contactPersonInfo.email}
                validating={agentEmailValidating}
                setValue={handleContactPersonInfoChange}
                onBlur={validateAgentEmail}
              />
            </div>
            <div className="flex-1 relative">
              <MaskField
                type="text"
                title="Phone *"
                placeholder="Enter Phone No"
                name="phone"
                errorMessage={errors.contactPersonPhoneError}
                value={contactPersonInfo.phone}
                validating={agentPhoneValidating}
                setValue={handleContactPersonInfoChange}
                onBlur={validateAgentPhone}
              />
            </div>
            {/* <div className="flex-1 relative">
              <InputField
                type="text"
                title="Extension"
                name="ext"
                placeholder="Enter Extension"
                errorMessage={errors.contactPersonExtError}
                value={contactPersonInfo.ext}
                setValue={handleContactPersonInfoChange}

              />
            </div> */}
            <div className="flex-1 relative">
              <MaskField
                type="text"
                title="Fax"
                placeholder="Enter Fax"
                name="fax"
                errorMessage={errors.contactPersonFaxError}
                value={contactPersonInfo.fax}
                setValue={handleContactPersonInfoChange}
              />
            </div>
          </div>

          <SecondaryHeading>Commission</SecondaryHeading>

          <div className="flex gap-6 my-1">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Comission Type *"
                placeholder="Select Type"
                errorMessage={errors.typeError}
                value={comission.type}
                setValue={(value) => handleCommissionChange(`type`, value)}
                isMulti={false}
                loadOptions={loadComissionTypes}
                isSearchable={true}
              />
            </div>

            <div className="flex-1 relative">
              <InputField
                type="number"
                title="Rate *"
                placeholder="Enter Rate"
                errorMessage={errors.rateError}
                value={comission.rate}
                setValue={(e) => handleCommissionChange(`rate`, e.target.value)}
              />
            </div>
          </div>

          <SecondaryHeading>Address</SecondaryHeading>
          <div className="flex gap-6 my-1">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Street *"
                name="street"
                placeholder="Enter Street"
                errorMessage={errors.streetNoError}
                value={address.street}
                setValue={(e) => handleAddressInput(e)}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Zip *"
                placeholder="Select Zip"
                errorMessage={errors.zipError}
                validating={locZipValidating}
                value={address.zip}
                setValue={(e) => handleAddressInput(e, `zip`)}
                isMulti={false}
                loadOptions={loadZipCodes}
                isSearchable={true}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Unit No"
                name="unitNo"
                placeholder="Enter Unit No"
                value={address.unitNo}
                setValue={(e) => handleAddressInput(e)}
              />
            </div>

            <div className="flex-1 relative">
              <InputField
                type="text"
                title="City *"
                name="city"
                placeholder="Enter City"
                errorMessage={errors.cityError}
                disabled={true}
                value={address.city}
                setValue={(e) => handleAddressInput(e)}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="State *"
                name="state"
                placeholder="Enter State"
                errorMessage={errors.stateError}
                disabled={true}
                value={address.state}
                setValue={(e) => handleAddressInput(e)}
              />
            </div>
          </div>
          <div className="rounded-md grid grid-cols-1">
            <label className="text-sm mt-2 text-gray-700 font-medium">
              Locate Yourself
              {/* {errors.coordinatesError && (
                <span className="text-red-500 required-dot ml-2">*</span>
              )} */}
            </label>
            <MapContainer
              center={[coordinates.lat, coordinates.lng]}
              zoom={3}
              scrollWheelZoom={true}
            >
              <ChangeView
                center={[coordinates.lat, coordinates.lng]}
                zoom={3}
              />
              <TileLayer url={LEAFLET_URL} />
              <DraggableMarker
                coord={coordinates}
                setCoordinates={(e) => setCoordinates(e)}
              />
            </MapContainer>
          </div>

          {/* Location Address starts from here */}
          <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
            <SubmitButton onClick={() => ValidateForm()}>
              Update Dealer
            </SubmitButton>
          </div>
        </div>
      </Container>
    </>
  )
}
