import React, { useState } from 'react'
import { ClaimActivity } from './Activity'
import { AboutClaim } from './About'
import { ActivityWrapper } from 'App/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowAltCircleRight,
  faBox,
  faClipboardList,
  faFolder,
} from '@fortawesome/free-solid-svg-icons'
import { setClaimActivityActiveTab } from 'App/Redux/actions'
import { useDispatch } from 'react-redux'
import { permissionGranted } from 'App/Services'
import { ACTIVITY_TABS_NAME } from 'App/constants'

export const ClaimPreview = () => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const dispatch = useDispatch()

  return (
    <div className="flex justify-end">
      <div className={`${isCollapsed ? `w-full` : `w-2/3`}`}>
        <AboutClaim />
      </div>
      <ActivityWrapper collapsed={isCollapsed.toString()}>
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="px-2 py-1 "
        >
          {/* <CollapseClip> */}
          <FontAwesomeIcon
            icon={faArrowAltCircleRight}
            className={`text-red-500 my-0.5 cursor-pointer transform transition duration-500 ${
              isCollapsed ? `rotate-180 ` : `rotate-0`
            }`}
          />
          {/* </CollapseClip> */}
        </div>
        {!isCollapsed ? (
          <ClaimActivity />
        ) : (
          <div
            className="text-center flex flex-col"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {permissionGranted(
              [`notes:read`, `corporate-manager:*`, `manager:*`, `admin:*`] || [
                `notes:*`,
                `corporate-manager:*`,
                `manager:*`,
                `admin:*`,
              ],
            ) ? (
              <>
                {` `}
                <div
                  data-tip={ACTIVITY_TABS_NAME.NOTES}
                  className="mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setClaimActivityActiveTab(1))}
                >
                  <FontAwesomeIcon
                    icon={faClipboardList}
                    className="text-red-500"
                  />
                </div>
                <div
                  data-tip={ACTIVITY_TABS_NAME.LOGS}
                  className="mt-4 mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setClaimActivityActiveTab(2))}
                >
                  <FontAwesomeIcon icon={faBox} className="text-red-500" />
                </div>
                <div
                  data-tip={ACTIVITY_TABS_NAME.MEDIA}
                  className="mt-4 mx-5 mb-3 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setClaimActivityActiveTab(3))}
                >
                  <FontAwesomeIcon icon={faFolder} className="text-red-500" />
                </div>
              </>
            ) : (
              <>
                <div
                  data-tip={ACTIVITY_TABS_NAME.LOGS}
                  className="py-2 mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setClaimActivityActiveTab(1))}
                >
                  <FontAwesomeIcon icon={faBox} className="text-red-500" />
                </div>
                <div
                  data-tip={ACTIVITY_TABS_NAME.MEDIA}
                  className="py-2 mx-5 mb-1 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setClaimActivityActiveTab(2))}
                >
                  <FontAwesomeIcon icon={faFolder} className="text-red-500" />
                </div>
              </>
            )}
          </div>
        )}
      </ActivityWrapper>
    </div>
  )
}
