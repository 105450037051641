import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'

const AddVehicle = React.lazy(() => import(`App/Components/Customers/Customer/Tabs/Assets/Vehicle/AddVehicle`).then(module => ({
  default: module.AddVehicle
})))

export const AddVehiclePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AddVehicle />
  </Suspense>
)
