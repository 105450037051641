import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const TransactionInspection = React.lazy(dynamicImportComponents[`/transaction/inspection`])


export const TransactionInspectionPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <TransactionInspection />
  </Suspense>
)
