import React, { Suspense } from 'react'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import { Spinner } from 'App/Components/Common/Spinner'


const AddFranchise = React.lazy(dynamicImportComponents[`/franchise/add`])

export const AddFranchisePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AddFranchise />
  </Suspense>
)
