import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const AllCredentials = React.lazy(dynamicImportComponents[`/admin/credentials`])


export const AllCredentialPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllCredentials />
  </Suspense>
)
