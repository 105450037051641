import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports';
import React, { Suspense } from 'react'

const Teams = React.lazy(dynamicImportComponents[`/assessment/teams`]);



export const TeamsPage = () => (
    <Suspense fallback={<Spinner loading />}>
        <Teams />
    </Suspense>
)
