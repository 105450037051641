import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import { setMonthlyGoalActivityActiveTab } from 'App/Redux/actions'
import { TOne, TTwo } from 'App/Styled'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { MonthlyGoalNote } from '../../MonthlyGoalNote'
import { permissionGranted } from 'App/Services'
import { ACTIVITY_TABS } from 'App/constants'

export const MonthlyGoalActivity = () => {
  const { currentGoal, activeActivityTab } = useSelector(
    ({ monthlyGoal }) => monthlyGoal,
  )
  const dispatch = useDispatch()
  return (
    <div className="px-2">
      <Tabs2
        openTab={activeActivityTab}
        tabs={
          permissionGranted([
            `notes:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ])
            ? ACTIVITY_TABS.NOTES_AND_LOGS_TABS
            : ACTIVITY_TABS.LOGS_TAB
        }
        getActiveTab={(val) => dispatch(setMonthlyGoalActivityActiveTab(val))}
      />
      {permissionGranted([
        `notes:read`,
        `corporate-manager:*`,
        `manager:*`,
        `admin:*`,
      ]) ? (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <MonthlyGoalNote />
          </TOne>

          <TTwo opentab={activeActivityTab}>
            <UpdateLogs
              logs={currentGoal?.updateLogs ? currentGoal?.updateLogs : []}
            />
          </TTwo>
        </div>
      ) : (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <UpdateLogs
              logs={currentGoal?.updateLogs ? currentGoal?.updateLogs : []}
            />
          </TOne>
        </div>
      )}
    </div>
  )
}
