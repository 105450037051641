import React, { useState, useEffect } from 'react'
import { Container, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import { useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { useHistory } from 'react-router-dom'
import { permissionGranted } from 'App/Services'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import dateFormat from 'App/Services/General/dateFormat'

const tableColumns = [
  { label: `Policy`, value: `policyNo` },
  { label: `Carrier`, value: `_carrier` },
  { label: `Creator`, value: `_creator` },
  { label: `Premium`, value: `totalPremium` },
  { label: `Customer`, value: `customer` },
  { label: `Eft Date`, value: `effectiveDate` },
  { label: `Exp Date`, value: `expiryDate` },
  { label: `Created`, value: `createdAt` },
  { label: `Updated`, value: `updatedAt` },
]

export const SearchPolicies = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [isOffset, setIsOffset] = useState(``)

  const history = useHistory()
  const { searchQuery } = useSelector(({ globalSearch }) => globalSearch)
  // fetching all Policies
  const {
    data: allPolicies,
    isFetching,
    isLoading,
    refetch: getAllPolicies,
    isError,
  } = useQuery(
    `PoliciesApiGlobal`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/policies?search={"query":"${
          searchQuery.length ? searchQuery : ``
        }", "filters":{}}&sort=""&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  useEffect(() => {
    getAllPolicies()
  }, [currentPage, isOffset])

  return (
    <Container shadow="yes">
      <div className="relative">
        {isError ? (
          <Reload refetch={() => getAllPolicies()} />
        ) : (
          <>
            {(isLoading || isFetching) && (
              <div className="text-center py-10 absolute top-1/3 left-1/2 z-40">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  size="1x"
                  className="text-red-600"
                />
              </div>
            )}
            <PageHeader bgcolor="bg-white">
              <div className="flex items-center ">
                <div className="py-1 px-2">
                  <ReactSelectField
                    placeholder="10"
                    value={isOffset}
                    setValue={(e) => {
                      setCurrentPage(1)
                      setIsOffset(e)
                    }}
                    isMulti={false}
                    loadOptions={loadOffset}
                  />
                </div>
              </div>
            </PageHeader>
            <div
              className={`bg-white mb-1 sm:mb-0 ${
                (isLoading || isFetching) && ` opacity-30 pointer-events-none`
              }`}
            >
              <div className="overflow-x-auto  mt-2 rounded-t-md">
                <table className="w-full border-collapse">
                  <TableHeader>
                    <TableRow>
                      {tableColumns.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div className="cursor-pointer select-none text-sm flex truncate">
                              <div className={`flex`}>{item.label}</div>
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {allPolicies?.policies?.length != 0 ? (
                      allPolicies?.policies?.map((policy, index) => (
                        <TableRow
                          key={index.toString()}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            if (
                              permissionGranted([
                                `policies:read`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ])
                            ) {
                              history.push(`/policy/${policy._id}`)
                            }
                          }}
                        >
                          <td className="px-4 py-0.5 z-0 text-sm">
                            <span className="font-medium truncate capitalize">
                              {policy.policyNo ? (
                                policy.policyNo.toLowerCase()
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </span>
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm truncate capitalize">
                            {policy._carrier?.label ? (
                              policy._carrier?.label
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm truncate">
                            {policy?._creator?.label ? (
                              policy._creator?.label
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm truncate">
                            {policy.totalPremium ? (
                              `$` + policy.totalPremium
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm truncate">
                            {policy._customer?.label ? (
                              policy._customer?.label
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm truncate">
                            {policy.createdAt ? (
                              <span>{dateFormat(policy.createdAt)}</span>
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm truncate">
                            {policy.expiryDate ? (
                              <span>{dateFormat(policy.expiryDate)}</span>
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm truncate">
                            <span>{dateFormat(policy.updatedAt)}</span>
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm truncate">
                            <span>{dateFormat(policy.createdAt)}</span>
                          </td>
                          {/* Actions TD */}
                        </TableRow>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="12" className="text-center p-6">
                          <div>No Policy Found</div>
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </table>
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={allPolicies?.totalPages}
                  totalRecords={allPolicies?.totalRecords}
                  curerntPageRecords={allPolicies?.currentPageRecords}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
