export * from './auth'
export * from './backendApis'
export * from './axiosInstance'
export { UNAUTHENTICATED_ROUTES } from './unauthenticatedRoutes'
export { SIDEBAR_ITEMS } from './sidebar'
export { LEAFLET_URL, regEx } from './franchise'
export { allowedSystemPermissions } from './systemPermissions'
export const offset = 10

export const urlRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/


export const policyTermLabels = {
  // 12: `ANNUAL`,
  1: `Monthly`,
  12: `Annual`,
  3: `Quarterly`,
  6: `Semi Annual`,
  2: `Two Month`,
}

export const isDate = (string) => {
  // Define a regular expression pattern for ISO 8601 dates
  const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/

  // Use test() to check if the string matches the pattern
  return iso8601Pattern.test(string)
}
