import { faDownload, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FileSaver from 'file-saver'
import React, { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'

export const ImageModal = ({
  setPreview,
  setCurrentImage,
  currentImage,
  className = `max-w-3xl max-h-50 object-cover`,
}) => {
  const modalRef = useRef(null)

  const handleClickOutsideModal = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setPreview(false)
      setCurrentImage(``)
    }
  }

  // Disable scrolling when the modal is open
  useEffect(() => {
    document.body.style.overflow = currentImage ? `hidden` : `auto`
  }, [currentImage])

  const handleDownload = () => {
    FileSaver.saveAs(currentImage)
    setPreview(false)
    setCurrentImage(``)
  }

  return ReactDOM.createPortal(
    <div
      id="modal"
      className="fixed inset-0 z-50 bg-gray-900 bg-opacity-70 flex justify-center items-center"
      onClick={handleClickOutsideModal}
      style={{
        backdropFilter: `blur(5px)`,
        WebkitBackdropFilter: `blur(5px)`, // Safari support
      }}
    >
      <div className="fixed z-50 top-1 right-6 flex items-center">
        <button
          className="text-3xl font-bold text-white p-2 rounded"
          onClick={handleDownload}
        >
          <FontAwesomeIcon
            icon={faDownload}
            className="bg-red-500 hover:bg-red-600 text-white text-md text-lg p-3 px-4 rounded-md cursor-pointer"
          />
        </button>
        <button
          className="text-3xl font-bold text-white p-2 rounded"
          onClick={() => {
            setPreview(false)
            setCurrentImage(``)
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="bg-red-500 hover:bg-red-600 text-white text-md text-lg p-3 px-4 rounded-md cursor-pointer"
          />
        </button>
      </div>
      <img
        ref={modalRef}
        id="modal-img"
        src={currentImage}
        alt="Image Not Found"
        className={className}
      />
    </div>,
    document.getElementById(`modal-root`),
  )
}
