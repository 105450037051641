import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const EvaluationReport = React.lazy(dynamicImportComponents[`/assessment/evaluation/report`])


export const EvaluationReportPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <EvaluationReport />
  </Suspense>
)
