import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { AsyncPaginate } from 'react-select-async-paginate'
export const ReactSelectField = ({
  title,
  placeholder,
  errorMessage,
  value,
  setValue,
  loadOptions,
  isMulti,
  isSearchable,
  cacheUniqs,
  disabled,
  noTitle = false,
  defaultValue,
  isOptionDisabled,
  validating,
  currentIndex,
  requiredValue,
  isScrollable,
  isClearable,
  autoFocus,
  additionalFilters,
  operatingState,
}) => {
  const customStyles = {
    menu: (provided, { isDisabled }) => ({
      ...provided,
      zIndex: 9999,
      cursor: isDisabled ? `not-allowed` : `pointer`,
    }),
    menuList: (provided, state) => ({
      ...provided,
      height: isScrollable && state.hasValue?.length ? `190px` : `auto`,
      textAlign: `left`,
      maxHeight: `210px`,
    }),
    option: (provided, { isDisabled }) => ({
      ...provided,
      cursor: isDisabled ? `not-allowed` : `pointer`,
    }),
    control: (provided, state) => ({
      ...provided,
      background: disabled
        ? `#f8fafc`
        : requiredValue
        ? `rgba(253, 253, 234, 1)`
        : `#fff`,
      minHeight: isScrollable ? `34px !important` : `32px !important`,
      height: `${
        isScrollable
          ? !isMulti
            ? `34px !important`
            : !state.hasValue && `34px !important`
          : !isMulti
          ? `32px !important`
          : !state.hasValue && `32px !important`
      }`,
      borderColor: state.isFocused
        ? `#e03831 !important`
        : requiredValue || errorMessage
        ? `#e03831 !important`
        : `#dadadb`,
      boxShadow: `none !important`,
      cursor: disabled ? `not-allowed` : `pointer`,
    }),
    placeholder: (provided) => ({
      ...provided,
      marginTop: `-3px`,
      color: `#b9b9b9`,
      cursor: disabled ? `not-allowed` : `pointer`,
    }),
    singleValue: (provided) => ({
      ...provided,
      marginTop: isScrollable ? `-1px` : `-4px`,
      padding: `1px`,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      marginRight: errorMessage && `15px`,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      marginTop: isScrollable ? `-8px` : `-3px`,
      cursor: disabled ? `not-allowed` : `pointer`,
    }),
    indicatorSeparator: (provided, state) =>
      !isScrollable
        ? {
            ...provided,
          }
        : {
            ...provided,
            height: `30px`,
            marginTop: state?.hasValue ? `17px` : `9px`,
          },
    dropdownIndicator: (provided) =>
      !isScrollable
        ? {
            ...provided,
            cursor: disabled ? `not-allowed` : `pointer`,
          }
        : { ...provided },
    container: (provided) => ({
      ...provided,
      cursor: disabled ? `not-allowed` : `pointer`,
    }),
    valueContainer: (provided, state) =>
      isScrollable
        ? {
            ...provided,
            overflowX: state?.hasValue && `scroll`,
            direction: state?.hasValue ? `rt` : `auto`,
            flexWrap: `unset`,
            minHeight: `32px !important`,
          }
        : { ...provided },

    multiValue: (provided) =>
      isScrollable
        ? {
            ...provided,
            flex: `0 0 auto`,
          }
        : { ...provided },
  }
  return (
    <div className="relative">
      <label className="text-gray-700 font-medium text-sm">
        {title}
        {!noTitle && (errorMessage || requiredValue) && (
          <span className="text-red-500 required-dot"></span>
        )}
      </label>
      <AsyncPaginate
        value={value}
        placeholder={disabled ? `Disabled` : placeholder}
        debounceTimeout={700}
        onChange={setValue}
        loadOptions={loadOptions}
        cacheUniqs={[cacheUniqs]}
        loadOptionsOnMenuOpen={true}
        isMulti={isMulti}
        additional={{
          page: 1,
          currentIndex: currentIndex,
          additionalFilters,
          operatingState,
        }}
        isSearchable={isSearchable}
        defaultValue={defaultValue}
        defaultOptions
        isDisabled={disabled}
        isClearable={isClearable}
        isOptionDisabled={(option) =>
          isOptionDisabled && option?.disable !== undefined
        }
        className={`mt-0.5 focus:outline-none text-sm ${
          disabled ? `cursor-not-allowed` : `cursor-default`
        } relative`}
        styles={customStyles}
        autoFocus={autoFocus}
      />
      {errorMessage && (
        <>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="absolute text-red-500 right-11 bottom-7"
          />
          <p className=" text-sm text-red-500 mb-1">{errorMessage}</p>
        </>
      )}
      {validating && (
        <FontAwesomeIcon
          icon={faSpinner}
          className={`absolute text-red-500 right-11 bottom-2`}
          spin={true}
        />
      )}
    </div>
  )
}
