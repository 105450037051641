import React from 'react'
import { TOne } from 'App/Styled'
import { useDispatch, useSelector } from 'react-redux'
import { setActivityActiveTab } from 'App/Redux/actions'
import { OtherCustomerNote } from 'App/Components/OtherCustomers/OtherCustomerNote'
// import { Communications } from 'App/Components/Communications'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { permissionGranted } from 'App/Services'
import { ACTIVITY_TABS } from 'App/constants'

export const Activity = () => {
  const { activeActivityTab } = useSelector(({ customer }) => customer)
  const dispatch = useDispatch()
  return (
    <div className="px-2 z-0">
      <Tabs2
        openTab={activeActivityTab}
        tabs={[ACTIVITY_TABS.NOTES_AND_LOGS_TABS[0]]}
        getActiveTab={(val) => dispatch(setActivityActiveTab(val))}
      />
      {
        permissionGranted(
          [`notes:read`, `corporate-manager:*`, `manager:*`, `admin:*`] || [
            `notes:*`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ],
        ) && (
          <div className="tab-content tab-space">
            <TOne opentab={activeActivityTab}>
              <OtherCustomerNote />
            </TOne>

            {/* <TTwo opentab={activeActivityTab}>
            <Communications />
          </TTwo> */}
          </div>
        )
        //  : (
        //   <div className="tab-content tab-space">
        //     <TOne opentab={activeActivityTab}>
        //       <Communications />
        //     </TOne>
        //   </div>
        // )
      }
    </div>
  )
}
