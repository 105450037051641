import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AddLead = React.lazy(dynamicImportComponents[`/crm/leads/add`])

export const AddLeadsPage = () => (
    <Suspense fallback={<Spinner loading />}>
        <AddLead />
    </Suspense>
)
