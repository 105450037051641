import { Spinner } from 'App/Components/Common/Spinner'
import React, { Suspense } from 'react'

const AddReceipt = React.lazy(() =>
  import(`App/Components/Payment/AddReceipt`).then((module) => ({
    default: module.AddReceipt,
  })),
)

export const AddReceiptPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AddReceipt />
  </Suspense>
)




