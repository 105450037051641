import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const QuoteForm = React.lazy(dynamicImportComponents[`/raters/quoteform`])

export const QuoteFormPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <QuoteForm />
  </Suspense>
)
