/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react'
import { defaultImage } from 'App/Assets'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'
import { Container, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Avatar,
} from '@windmill/react-ui'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronCircleDown,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'
import dateFormat from 'App/Services/General/dateFormat'

const tableColumns = [
  { label: `First Name`, value: `firstName` },
  { label: `Last Name`, value: `lastName` },
  { label: `Type`, value: `type` },
  { label: `Email`, value: `email` },
  { label: `Phone`, value: `phone` },
  { label: `Work Phone`, value: `workPhone` },
  { label: `Policies`, value: `policyCount` },
  { label: `Created`, value: `createdAt` },
  { label: `Updated`, value: `updatedAt` },
]

export const SearchCustomers = () => {
  const history = useHistory()

  const [currentPage, setCurrentPage] = useState(1)
  const { searchQuery } = useSelector(({ globalSearch }) => globalSearch)
  const [isOffset, setIsOffset] = useState(``)
  const [active, setActive] = useState(false)
  const [collapseIndex, setCollapseIndex] = useState(``)

  const handleCollapse = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (!active) {
      setCollapseIndex(id)
    } else {
      setCollapseIndex(``)
    }
  }

  const {
    data: currentSearch,
    isLoading,
    isFetching,
    refetch: getSearchCustomers,
    isError,
  } = useQuery(
    `CustomersApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/customers/all?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }"}&sort=""&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  useEffect(() => {
    getSearchCustomers()
  }, [currentPage, isOffset])

  return (
    <Container shadow="yes">
      <div className="relative">
        {isError ? (
          <Reload refetch={() => getSearchCustomers()} />
        ) : (
          <>
            {(isLoading || isFetching) && (
              <div className="text-center py-10 absolute top-1/3 left-1/2 z-40">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  size="1x"
                  className="text-red-600"
                />
              </div>
            )}
            <PageHeader bgcolor="bg-white">
              <div className="flex items-center ">
                <div className="py-1 px-2">
                  <ReactSelectField
                    placeholder="10"
                    value={isOffset}
                    setValue={(e) => {
                      setCurrentPage(1)
                      setIsOffset(e)
                    }}
                    isMulti={false}
                    loadOptions={loadOffset}
                  />
                </div>
              </div>
            </PageHeader>

            <div
              className={`bg-white mb-1 sm:mb-0 ${
                (isLoading || isFetching) && ` opacity-30 pointer-events-none`
              }`}
            >
              <div className="overflow-x-auto  rounded-t-md mt-1">
                <table className="w-full border-collapse">
                  <TableHeader>
                    <TableRow>
                      <TableCell className="select-none text-sm">
                        Image
                      </TableCell>
                      {tableColumns.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div className=" cursor-pointer select-none text-sm flex truncate">
                              <div className={`flex `}>{item.label}</div>
                            </div>
                          </TableCell>
                        )
                      })}
                      <TableCell className="select-none text-sm float-right px-6">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {!currentSearch?.customers?.length ? (
                      <tr>
                        <td colSpan="12" className="text-center p-6">
                          No Customer Found
                        </td>
                      </tr>
                    ) : (
                      currentSearch?.customers?.map((_customer, index) => (
                        <Fragment key={index}>
                          <TableRow
                            key={index}
                            className="hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              if (
                                permissionGranted([
                                  `customers:read`,
                                  `corporate-manager:*`,
                                  `manager:*`,
                                  `admin:*`,
                                ])
                              ) {
                                history.push(`/customers/${_customer._id}`)
                              }
                            }}
                          >
                            <td className="px-5 py-1.5 text-sm">
                              <div className="flex items-center text-sm">
                                <Avatar
                                  src={
                                    _customer.img ? _customer.img : defaultImage
                                  }
                                  alt="Judith"
                                />
                              </div>
                            </td>
                            <td
                              className="px-4 py-0.5 cursor-pointer capitalize truncate text-sm"
                              onClick={() => {
                                history.push(`/customers/${_customer._id}`)
                              }}
                            >
                              {_customer.firstName ? (
                                _customer.firstName?.toLowerCase()
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>
                            <td
                              className="px-4 py-0.5 cursor-pointer capitalize truncate text-sm"
                              onClick={() => {
                                history.push(`/customers/${_customer._id}`)
                              }}
                            >
                              {_customer.lastName ? (
                                _customer.lastName?.toLowerCase()
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 capitalize text-sm">
                              {_customer.type ? (
                                _customer.type?.toLowerCase()
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>

                            <td className="px-4 py-0.5 text-sm">
                              {_customer.contactInfo?.email ? (
                                _customer.contactInfo.email
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>
                            <td className="px-2 py-0.5 w-auto text-sm">
                              {_customer.contactInfo?.phone ? (
                                _customer.contactInfo?.phone
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>
                            <td className="px-2 py-0.5 w-auto text-sm">
                              {_customer.contactInfo?.workPhone ? (
                                _customer.contactInfo?.workPhone
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 w-auto text-sm">
                              {_customer.policyCount ? (
                                <Badge>{_customer.policyCount}</Badge>
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 w-auto text-sm truncate">
                              {_customer.createdAt ? (
                                dateFormat(_customer.createdAt)
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 w-auto text-sm truncate">
                              {_customer.updatedAt ? (
                                dateFormat(_customer.updatedAt)
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>
                            <td className="px-7 py-2 z-0 text-sm flex float-right text-gray-400">
                              <span data-tip="Show" className="tooltip">
                                <FontAwesomeIcon
                                  className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                                    collapseIndex === index &&
                                    `rotate-180 text-blue-600`
                                  }`}
                                  icon={faChevronCircleDown}
                                  onClick={(e) => {
                                    setActive(!active)
                                    handleCollapse(index, e)
                                  }}
                                />
                              </span>
                            </td>
                          </TableRow>
                          {/*Collapsable Row  */}
                          {collapseIndex === index && (
                            <TableRow>
                              {!_customer.policyNo?.length ? (
                                <td
                                  colSpan={12}
                                  className="w-full text-center font-bold py-1"
                                >
                                  <p>No Policy Found</p>
                                </td>
                              ) : (
                                <>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {_customer.policyNo?.map((_eachPolicy) => {
                                      return (
                                        <div className="my-2 ml-3">
                                          <Badge>{_eachPolicy}</Badge>
                                        </div>
                                      )
                                    })}
                                  </td>
                                </>
                              )}
                            </TableRow>
                          )}
                        </Fragment>
                      ))
                    )}
                  </TableBody>
                </table>
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={currentSearch?.totalPages}
                  totalRecords={currentSearch?.totalRecords}
                  curerntPageRecords={currentSearch?.currentPageRecords}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
