import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import { setTicketActivityActiveTab } from 'App/Redux/actions'
import { TOne, TTwo } from 'App/Styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TicketNote } from '../../TicketNote'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { permissionGranted } from 'App/Services'
import { ACTIVITY_TABS } from 'App/constants'

export const TicketActivity = () => {
  const { currentTicket, activeActivityTab } = useSelector(
    ({ tickets }) => tickets,
  )
  const dispatch = useDispatch()

  return (
    <div className="px-1">
      <Tabs2
        openTab={activeActivityTab}
        tabs={
          permissionGranted([
            `notes:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ])
            ? ACTIVITY_TABS.NOTES_AND_LOGS_TABS
            : ACTIVITY_TABS.LOGS_TAB
        }
        getActiveTab={(val) => dispatch(setTicketActivityActiveTab(val))}
      />
      {permissionGranted([
        `notes:read`,
        `corporate-manager:*`,
        `manager:*`,
        `admin:*`,
      ]) ? (
        <div className="tab-content tab-space overflow-y-hidden">
          <TOne opentab={activeActivityTab}>
            <TicketNote />
          </TOne>

          <TTwo opentab={activeActivityTab}>
            <UpdateLogs
              logs={currentTicket?.updateLogs ? currentTicket?.updateLogs : []}
            />
          </TTwo>
        </div>
      ) : (
        <div className="tab-content tab-space overflow-y-hidden">
          <TOne opentab={activeActivityTab}>
            <UpdateLogs
              logs={currentTicket?.updateLogs ? currentTicket?.updateLogs : []}
            />
          </TOne>
        </div>
      )}
    </div>
  )
}
