import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarWeek,
  faExclamationTriangle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { Input } from 'App/Styled'

export const InputField = ({
  type,
  value,
  name,
  title,
  id,
  disabled,
  placeholder,
  errorMessage,
  setValue,
  setKeyDownValue = () => {},
  tipdata,
  validating,
  ref,
  requiredValue,
  dateTimePickerIcon,
  maxLength,
  minlength,
  onKeyPress = () => {},
  ...props
}) => {
  return (
    <>
      <label
        data-tip={tipdata}
        className={` ${tipdata && `tooltip`} text-gray-700 font-medium text-sm`}
      >
        {title}
        {tipdata && <span className="tooltip"></span>}

        {(errorMessage || requiredValue) && (
          <span className="text-red-500 required-dot"></span>
        )}
      </label>
      <Input
        {...props}
        type={type}
        name={name}
        id={id}
        ref={ref}
        disabled={disabled || validating}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e)}
        // onBlur={onBlur}
        onKeyDown={(e) => setKeyDownValue(e)}
        requiredvalue={requiredValue}
        maxLength={maxLength}
        minLength={minlength}
        onKeyPress={(e) => onKeyPress(e)}
        onWheel={(e) => e?.target?.type === `number` && e?.target?.blur()}
        className={`${requiredValue && `bg-yellow-50`}`}
      />
      {dateTimePickerIcon ? (
        <FontAwesomeIcon
          icon={faCalendarWeek}
          className="text-blue-500 absolute right-2 mt-3 cursor-pointer "
          size="sm"
        />
      ) : (
        ``
      )}

      {validating ? (
        <FontAwesomeIcon
          icon={faSpinner}
          className="absolute text-red-500 right-2 mt-3"
          spin={true}
        />
      ) : (
        errorMessage && (
          <>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="absolute text-red-500 right-2 mt-2.5"
            />
            <p className="text-sm text-red-500">{errorMessage}</p>
          </>
        )
      )}
    </>
  )
}
