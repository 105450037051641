import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import { TOne } from 'App/Styled'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { setCategoryActivityActiveTab } from 'App/Redux/actions'
import { ACTIVITY_TABS } from 'App/constants'

export const TicketCategoryActivity = () => {
  const { currentCategory, activeActivityTab } = useSelector(
    ({ ticketCategory }) => ticketCategory,
  )

  const dispatch = useDispatch()
  return (
    <div className="px-2">
      <Tabs2
        openTab={activeActivityTab}
        tabs={ACTIVITY_TABS.LOGS_TAB}
        getActiveTab={(val) => {
          dispatch(setCategoryActivityActiveTab(val))
        }}
      />
      {/* <div className="tab-content tab-space">
        <TTwo opentab={activeActivityTab}>
          <UpdateLogs
            logs={
              currentCategory?.updateLogs ? currentCategory?.updateLogs : []
            }
          />
        </TTwo>
      </div> */}
      <div className="tab-content tab-space">
        <TOne opentab={activeActivityTab}>
          <UpdateLogs
            logs={
              currentCategory?.updateLogs ? currentCategory?.updateLogs : []
            }
          />
        </TOne>
      </div>
    </div>
  )
}
