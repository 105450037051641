import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const AddPolicy = React.lazy(dynamicImportComponents[`/policy/add`])

export const AddPolicyPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AddPolicy />
  </Suspense>
)
