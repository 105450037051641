import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AllCustomers = React.lazy(dynamicImportComponents[`/customers`])


export const AllCustomersPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllCustomers />
  </Suspense>
)
