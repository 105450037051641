import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AddAgent = React.lazy(dynamicImportComponents[`/add/agent`])

export const AddAgentPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AddAgent />
  </Suspense>
)


