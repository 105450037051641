import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const PreviewCredential = React.lazy(dynamicImportComponents[`/credentials/preview`])


export const PreviewCredentialPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <PreviewCredential />
  </Suspense>
)
