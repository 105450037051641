import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const UpdateLocation = React.lazy(dynamicImportComponents[`/locations/update`])


export const UpdateLocationPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <UpdateLocation />
  </Suspense>
)
