import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'


const CarrierPreview = React.lazy(dynamicImportComponents[`/carriers/preview`])


export const CarrierPreviewPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <CarrierPreview />
  </Suspense>
)
