export const dynamicImportComponents = {
    "/dashboard": () =>
        import(`App/Components/Dashboard`).then((module) => ({
            default: module.Dashboard,
        })),
    // it will called from side bar
    "/admin/system-roles": () =>
        import(`App/Components/SystemRoles`).then((module) => ({
            default: module.SystemRoles
        })),
    "system-roles-add": () =>
        import(`App/Components/SystemRoles/AddSystemRoles`).then((module) => ({
            default: module.AddSystemRoles,
        })),
    "system-roles-view": () =>
        import(`App/Components/SystemRoles/PreviewSystemRole`).then((module) => ({
            default: module.PreviewSystemRole,
        })),
    "/system-roles/update": () =>
        import(`App/Components/SystemRoles/UpdateSystemRole`).then((module) => ({
            default: module.UpdateSystemRole,
        })),
    "/admin/franchises": () =>
        import(`App/Components/Franchise/AllFranchises`).then((module) => ({
            default: module.AllFranchise
        })),
    "/franchise/add": () =>
        import(`App/Components/Franchise/AddFranchise`).then((module) => ({
            default: module.AddFranchise
        })),
    "/franchise/preview": () =>
        import(`App/Components/Franchise/Franchise`).then((module) => ({
            default: module.Franchise
        })),
    "/franchise/preview/overview": () =>
        import(`App/Components/Franchise/Franchise/Tabs/Overview`).then((module) => ({
            default: module.Overview
        })),
    "/franchise/update": () =>
        import(`App/Components/Franchise/UpdateFranchise`).then((module) => ({
            default: module.UpdateFranchise
        })),
    "/franchise/preview/customer": () =>
        import(`App/Components/Franchise/Franchise/Tabs/Customers`).then((module) => ({
            default: module.FCustomers
        })),
    "/franchise/preview/location": () =>
        import(`App/Components/Franchise/Franchise/Tabs/Locations`).then((module) => ({
            default: module.Locations
        })),
    "/franchise/preview/agents": () =>
        import(`App/Components/Franchise/Franchise/Tabs/Agents`).then((module) => ({
            default: module.FAgents
        })),
    "/admin/locations": () => import(`App/Components/Locations/AllLocations`).then((module) => ({
        default: module.Locations
    })),
    "/locations/preview": () => import(`App/Components/Locations/Location`).then((module) => ({
        default: module.Location
    })),
    "/locations/add": () => import(`App/Components/Locations/AddLocation`).then((module) => ({
        default: module.AddLocation
    })),
    "/locations/update": () => import(`App/Components/Locations/UpdateLocation`).then((module) => ({
        default: module.UpdateLocation
    })),
    "/admin/agents": () => import(`App/Components/Agents/AllAgents`).then((module) => ({
        default: module.AllAgents
    })),
    "/admin/credentials": () => import(`App/Components/Credentials/AllCredentials`).then((module) => ({
        default: module.AllCredentials
    })),
    "/credentials/preview": () => import(`App/Components/Credentials/PreviewCredential`).then((module) => ({
        default: module.PreviewCredential
    })),
    "/credentials/add": () => import(`App/Components/Credentials/AddCredentials`).then((module) => ({
        default: module.AddCredential
    })),
    "/credentials/update": () => import(`App/Components/Credentials/UpdateCredential`).then((module) => ({
        default: module.UpdateCredential
    })),
    "/carriers": () => import(`App/Components/Carrier/AllCarriers`).then((module) => ({
        default: module.AllCarriers
    })),
    "/carriers/preview": () => import(`App/Components/Carrier/CarrierPreview`).then((module) => ({
        default: module.CarrierPreview
    })),
    "/carriers/add": () => import(`App/Components/Carrier/AddCarrier`).then((module) => ({
        default: module.AddCarrier
    })),
    "/carriers/update": () => import(`App/Components/Carrier/UpdateCarrier`).then((module) => ({
        default: module.UpdateCarrier
    })),
    "/imported-carriers": () => import(`App/Components/Carrier/AllCarriers`).then((module) => ({
        default: module.AllCarriers
    })),
    "/admin/group-credentials": () => import(`App/Components/Credentials/GroupCredentials/AllGroup`).then((module) => ({
        default: module.AllGroupCredentials
    })),
    "/admin/scarping-credentials": () => import(`App/Components/Credentials/Scrapper/AllCredential`).then((module) => ({
        default: module.AllScrapingCredentials
    })),
    "/admin/monthlygoals": () => import(`App/Components/MonthlyGoals/AllMonthlyGoals`).then((module) => ({
        default: module.AllMonthlyGoals
    })),
    "/admin/paymenttemplates": () => import(`App/Components/PaymentTemplate/AllPaymentTemplate`).then((module) => ({
        default: module.AllPaymentTemplate
    })),
    "/admin/general-notifications": () => import(`App/Components/GeneralNotifications/GeneralNotifications`).then((module) => ({
        default: module.GeneralNotifications
    })),
    "/admin/claims": () => import(`App/Components/Claims/AllClaims`).then((module) => ({
        default: module.Claims
    })),
    "/admin/cs-logs": () => import(`App/Components/Communications/Logs`).then((module) => ({
        default: module.CommunicationLogs
    })),
    "/assessment/analytics": () => import(`App/Components/Assessment/AssessmentDashboard`).then((module) => ({
        default: module.AssessmentDashboard
    })),
    "/assessment/teams": () => import(`App/Components/Assessment/Team/Teams`).then((module) => ({
        default: module.AllTeams
    })),
    "/assessment/template": () => import(`App/Components/Assessment/AssessmentTemplate/AllTemplates`).then((module) => ({
        default: module.AllTemplates
    })),
    "/assessment/weeks": () => import(`App/Components/Assessment/WeeklyAssessments`).then((module) => ({
        default: module.Assessments
    })),
    "/assessment/template/add": () => import(`App/Components/Assessment/AssessmentTemplate/AddTemplate`).then((module) => ({
        default: module.AddTemplate
    })),
    "/assessment/template/preview": () => import(`App/Components/Assessment/AssessmentTemplate/PreviewTemplate`).then((module) => ({
        default: module.PreviewTemplate
    })),
    "/assessment/template/update": () => import(`App/Components/Assessment/AssessmentTemplate/UpdateTemplate`).then((module) => ({
        default: module.UpdateTemplate
    })),
    "/assessment/weeks/preview": () => import(`App/Components/Assessment/Evaluations`).then((module) => ({
        default: module.Evaluations
    })),
    "/assessment/evaluation": () => import(`App/Components/Assessment/Evaluate`).then((module) => ({
        default: module.Evaluate
    })),
    "/assessment/evaluation/report": () => import(`App/Components/Assessment/EvaluateReport`).then((module) => ({
        default: module.EvaluationReport
    })),
    "/transaction/inspection": () => import(`App/Components/Transactions/Inspection`).then((module) => ({
        default: module.TransactionInspection
    })),
    "/transaction/transactions-history": () => import(`App/Components/Transactions/TransactionHistory/AllTransactions`).then((module) => ({
        default: module.AllTransactions
    })),
    "/customers": () => import(`App/Components/Customers/AllCustomers`).then((module) => ({
        default: module.AllCustomers
    })),
    "/customers/preview": () => import(`App/Components/Customers/Customer`).then((module) => ({
        default: module.Customer
    })),
    "/customers/preview/overview": () => import(`App/Components/Customers/Customer/Tabs/Overview`).then((module) => ({
        default: module.Overview
    })),
    "/customers/preview/contacts": () => import(`App/Components/Customers/Customer/Tabs/Contacts/AllContacts`).then((module) => ({
        default: module.AllContacts
    })),
    "/customers/contacts/preview": () => import(`App/Components/Customers/Customer/Tabs/Contacts/Contact`).then((module) => ({
        default: module.Contact
    })),
    "/customers/contacts/add": () => import(`App/Components/Customers/Customer/Tabs/Contacts/AddContact`).then((module) => ({
        default: module.AddContact
    })),
    "/customers/contacts/update": () => import(`App/Components/Customers/Customer/Tabs/Contacts/UpdateContact`).then((module) => ({
        default: module.UpdateContact
    })),
    "/customers/update": () => import(`App/Components/Customers/UpdateCustomer`).then((module) => ({
        default: module.UpdateCustomer
    })),
    "/policy": () => import(`App/Components/Policies/AllPolicies`).then((module) => ({
        default: module.AllPolicies
    })),
    "/policy/add": () => import(`App/Components/Policies/AddPolicy`).then((module) => ({
        default: module.AddPolicy
    })),
    "/policy/preview": () => import(`App/Components/Policies/PolicyPreview`).then((module) => ({
        default: module.PolicyPreview
    })),
    "/policy/update": () => import(`App/Components/Policies/PolicyUpdate`).then((module) => ({
        default: module.PolicyUpdate
    })),
    "/policies/requests": () => import(`App/Components/Requests/AllRequests`).then((module) => ({
        default: module.AllRequests
    })),
    "/broadcasts/templates": () => import(`App/Components/Broadcasts/BroadcastingTemplates/AllBroadcastingTemplates`).then((module) => ({
        default: module.AllBroadCastingTemplates
    })),
    "/broadcasts/campaigns": () => import(`App/Components/Broadcasts/Campaigns/AllCampaignGroups`).then((module) => ({
        default: module.AllCampaignGroups
    })),
    "/reports/daily-ledger": () => import(`App/Components/Reports/DailyLedgers`).then((module) => ({
        default: module.DailyLedger
    })),
    "/reports/policy-reports": () => import(`App/Components/Reports/PoliciesReports`).then((module) => ({
        default: module.PoliciesReports
    })),
    "/reports/payment-records": () => import(`App/Components/Reports/PaymentRecords`).then((module) => ({
        default: module.PaymentRecords
    })),
    "/raters/myquotes": () => import(`App/Components/Raters/MyQuotes/AllQuotes`).then((module) => ({
        default: module.MyQuotes
    })),
    "/raters/myquotes/update": () => import(`App/Components/Raters/MyQuotes/UpdateQuote`).then((module) => ({
        default: module.QuoteSold
    })),
    "/raters/myquotes/preview": () => import(`App/Components/Raters/MyQuotes/QuotePreview`).then((module) => ({
        default: module.QuotePreview
    })),
    "/raters/quickestimate": () => import(`App/Components/Raters/QuickEstimate`).then((module) => ({
        default: module.QuickEstimate
    })),
    "/raters/preferences": () => import(`App/Components/Raters/Preferences/Preference`).then((module) => ({
        default: module.Preference
    })),
    "/raters/ratingtemplates": () => import(`App/Components/Raters/RatingTemplates/AllRatingTemplates`).then((module) => ({
        default: module.AllRatingTemplates
    })),
    "/raters/ratingtemplates/add": () => import(`App/Components/Raters/RatingTemplates/AddRatingTemplate`).then((module) => ({
        default: module.AddRatingTemplate
    })),
    "/raters/ratingtemplates/preview": () => import(`App/Components/Raters/RatingTemplates/RatingTemplate`).then((module) => ({
        default: module.RatingTemplate
    })),
    "/raters/ratingtemplates/update": () => import(`App/Components/Raters/RatingTemplates/UpdateRatingTemplate`).then((module) => ({
        default: module.UpdateRatingTemplate
    })),
    "/raters/quoteform": () => import(`App/Components/Raters/QuoteForm`).then((module) => ({
        default: module.QuoteForm
    })),
    "/raters/quoteform/reRate": () => import(`App/Components/Raters/QuoteForm/Rerate`).then((module) => ({
        default: module.RerateQuoteForm
    })),
    "/raters/quoteform/offers": () => import(`App/Components/Raters/QuoteForm/QuoteOffers`).then((module) => ({
        default: module.QuoteOffers
    })),
    "/raters/quoteform/preview/about": () => import(`App/Components/Raters/MyQuotes/QuotePreview/About`).then((module) => ({
        default: module.AboutQuote
    })),
    "/crm/leads": () => import(`App/Components/CRM/Leads/AllLeads`).then((module) => ({
        default: module.AllLeads
    })),
    "/crm/leads/preview": () => import(`App/Components/CRM/Leads/PreviewLeads`).then((module) => ({
        default: module.PreviewLead
    })),
    "/crm/leads/add": () => import(`App/Components/CRM/Leads/AddLeads`).then((module) => ({
        default: module.AddLead
    })),
    "/crm/dataTransactions": () => import(`App/Components/CRM/DataTransaction/AllDataTransactions`).then((module) => ({
        default: module.AllDataTransaction
    })),
    "/crm/cardealers": () => import(`App/Components/CRM/CarDealers/AllCarDealers`).then((module) => ({
        default: module.AllCarDealers
    })),
    "/messenger": () => import(`App/Components/Messenger`).then((module) => ({
        default: module.Messenger
    })),
    "/tickets/category": () => import(`App/Components/TicketsCenter/Category/AllCategory`).then((module) => ({
        default: module.AllTicketsCategory
    })),
    "/ticketscenter": () => import(`App/Components/TicketsCenter/AllTickets`).then((module) => ({
        default: module.AllTickets
    })),
    "/taskmanager": () => import(`App/Components/TaskManager/AllTasks`).then((module) => ({
        default: module.AllTasks
    })),
    "/other-customers": () => import(`App/Components/OtherCustomers/AllOtherCustomers`).then((module) => ({
        default: module.AllOtherCustomers
    })),
    "/communications": () => import(`App/Components/GlobalCommunications`).then((module) => ({
        default: module.GlobalCommunications
    })),
    "/profile": () => import(`App/Components/Profile/ProfilePreview`).then((module) => ({
        default: module.Profile
    })),
    "/profile/update": () => import(`App/Components/Profile/UpdateProfile`).then((module) => ({
        default: module.UpdateProfile
    })),
    "/add/agent": () =>
        import(`App/Components/Agents/AddAgent`).then((module) => ({
            default: module.AddAgent
        })),
}
