import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'


const PolicyPreview = React.lazy(dynamicImportComponents[`/policy/preview`])

export const PolicyPreviewPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <PolicyPreview />
  </Suspense>
)
