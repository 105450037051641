import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const GlobalCommunications = React.lazy(dynamicImportComponents[`/communications`])

export const CommunicationCenter = () => (
  <Suspense fallback={<Spinner loading />}>
    <GlobalCommunications />
  </Suspense>
)


export { CommunicationLogsPage } from './communicationLogs'
