export const loadFrequency = () => {
    const options = {
        options: [
            { value: `DAY`, label: `DAY` },
            { value: `WEEK`, label: `WEEK` },
            { value: `MONTH`, label: `MONTH` },
            { value: `YEAR`, label: `YEAR` },
        ],
        hasMore: false,
    }
    return options
}