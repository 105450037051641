import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API } from 'App/Config/backendApis'
import { AxiosInstance, allowedSystemPermissions } from 'App/Config'
import { useHistory } from 'react-router-dom'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import {
  fileToBase64,
  loadAgents,
  loadAllTicketsPriorities,
  loadFranchises,
  permissionGranted,
} from 'App/Services'
import { TextArea } from 'App/Components/Common/TextArea'
import { getNonce } from 'App/Services/Auth/getNonce'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { NotificationManager } from 'react-notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { loadFrequency } from 'App/Services/General/loadFrequency'

export const AddTasks = () => {
  const history = useHistory()
  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)

  const {
    user: {
      profile: { _locations },
    },
  } = useSelector(({ user }) => user)
  const loadAgentLocations = () => {
    const options = _locations.map((item) => {
      return { value: item._id, label: item.name }
    })
    return {
      options,
    }
  }

  const [tasks, setTasks] = useState({
    assignTo: ``,
    taskType: ``,
    priority: ``,
    dueDate: ``,
    alertTime: ``,
    recurring: ``,
    afterEvery: ``,
    title: ``,
    description: ``,
    frequency: ``,
  })
  const [afterEveryTitle, setAfterEveryTitle] = useState(``)
  const [media, setMedia] = useState(``)
  const [recurringValue, setRecurringValue] = useState(false)
  const [fileName, setFileName] = useState([])
  const [mediaUrls, setMediaUrls] = useState([])
  const [franchise, setFranchise] = useState({})
  const [location, setLocation] = useState({})
  const loadAllTaskTypes = () => {
    const options = {
      options: [
        { value: `EARNING GOALS`, label: `EARNING GOALS` },
        { value: `ASSESSMENT`, label: `  ASSESSMENT` },
        { value: `LEADS/ VISITORS CALL`, label: `LEADS/ VISITORS CALL` },
        { value: `RECALL CUSTOMERS`, label: `RECALL CUSTOMERS` },
        { value: `POLICY CHANGE`, label: `POLICY CHANGE` },
        { value: `RENEWAL`, label: `RENEWAL` },
        { value: `REQUEST SERVICE`, label: `REQUEST SERVICE` },
        { value: `TICKET CENTER`, label: `TICKET CENTER` },
        { value: `QUOTES`, label: `QUOTES` },
      ],
      hasMore: false,
    }
    return options
  }
  const loadAlertTime = () => {
    const options = {
      options: [
        { value: 5, label: `5 minutes before` },
        { value: 10, label: `10 minutes before` },
        { value: 15, label: `15 minutes before` },
        { value: 20, label: `20 minutes before` },
        { value: 25, label: `25 minutes before` },
        { value: 30, label: `30 minutes before` },
        { value: 45, label: `45 minutes before` },
        { value: 60, label: `One Hour before` },
        { value: 90, label: `90 minutes before` },
        { value: 120, label: `2 Hours before` },
      ],
      hasMore: false,
    }
    return options
  }

  const customRecurring = () => {
    const options = {
      options: [
        { value: `NONE`, label: `NONE` },
        { value: `DAY`, label: `DAY` },
        { value: `WEEK`, label: `WEEK` },
        { value: `MONTH`, label: `MONTH` },
        { value: `YEAR`, label: `YEAR` },
        { value: `CUSTOM`, label: `CUSTOM` },
      ],
      hasMore: false,
    }
    return options
  }

  const afterEvery = () => {
    const options = []
    for (let i = 1; i <= 30; ++i) {
      options.push({ value: i, label: i.toString() })
    }
    return { options, hasMore: false }
  }

  const [errors, setErrors] = useState({
    formSubmit: false,
    assignToError: ``,
    taskTypeError: ``,
    priorityError: ``,
    dueDateError: ``,
    alertTimeError: ``,
    recurringError: ``,
    frequencyError: ``,
    afterEveryError: ``,
    titleError: ``,
    descriptionError: ``,
    franchiseError: ``,
    locationError: ``,
  })

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/task/create`, {
        ...payload,
      }).then((response) => {
        if (response.data) {
          response?.data?.data && history.push(`/taskmanager`)
          NotificationManager.success(`Created Successfully`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  //get all locations by franchise
  const loadLocations = async (search, loadedOptions, { page }) => {
    try {
      const {
        data: { data },
      } = await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/franchises/${
          franchise.value ? franchise.value : _franchise?._id
        }/locations/list/?search=${search && search}&page=${page}&offset=10`,
      )
      if (data) {
        return {
          options: data.locations,
          hasMore: data.totalPages - page > 0,
          additional: {
            page: page + 1,
          },
        }
      }
    } catch (error) {
      if (error.response?.data?.hasError) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        }
      }
    }
  }

  //get all agents by location
  // const loadAgent = async (search, loadedOptions, { page }) => {
  //   try {
  //     const {
  //       data: { data },
  //     } = await AxiosInstance.post(
  //       `${AMS_BACKEND_API}/api/users/v2/list/?search={"query":"${search.length ? search : ``
  //       }"}&page=${page}&offset=10`,
  //       {
  //         franchiseId: franchise.value ? franchise.value : _franchise?._id,
  //         locations: [location?.value],
  //         permissions: allowedSystemPermissions.task
  //       },)

  //     if (data) {
  //       return {
  //         options: data.agents,
  //         hasMore: data.totalPages - page > 0,
  //         additional: {
  //           page: page + 1,
  //         },
  //       }
  //     }
  //   }
  //   catch (error) {
  //     if (error.response?.data?.hasError) {
  //       return {
  //         options: [],
  //         hasMore: false,
  //         additional: {
  //           page: page,
  //         },
  //       }
  //     }
  //   }

  // }
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  let fileRef
  const {
    mutate: getFileUrl,
    isLoading: loadingFileUrl,
    error: uploadingError,
  } = useMutation(
    `GetFileUrl`,
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/cloudinary/file`, {
        ...payload,
      })
        .then((response) => {
          setFileName((prev) => [...prev, media])
          setMediaUrls((prev) => [...prev, response?.data?.data])
        })
        .catch(() => {
          NotificationManager.error(`${media}  File not supported`)
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )
  const handleFileUpload = async (file) => {
    // convert nested objects into array
    let files = [],
      keys = Object.keys(file)

    for (let i = 0, n = keys.length; i < n; i++) {
      let key = keys[i]
      files[key] = file[key]
    }
    // nested objects converted into array and now array mapped
    files.map((item) => {
      if (
        item?.type.includes(`image`) ||
        item?.type.includes(`pdf`) ||
        item?.type.includes(`.doc`) ||
        item?.type.includes(`docx`) ||
        item?.type.includes(`video`)
      ) {
        fileToBase64(item, (err, base64) => {
          if (base64) {
            setMedia(item?.name)
            getFileUrl({
              file: base64,
              fileName: item?.name,
              fileType: item.type,
              path: `Task-Management/media`,
            })
          }
        })
      }
    })
  }

  const validateForm = () => {
    if (!tasks.assignTo?.value?.length) {
      handleErrors(`assignToError`, `Select Agent Name`)
    } else {
      handleErrors(`assignToError`, ``)
    }
    if (!tasks.taskType?.value?.length) {
      handleErrors(`taskTypeError`, `Select Task Type`)
    } else {
      handleErrors(`taskTypeError`, ``)
    }
    if (!location?.value?.length) {
      handleErrors(`locationError`, `Select Location`)
    } else {
      handleErrors(`locationError`, ``)
    }

    if (!tasks.priority?.value?.length) {
      handleErrors(`priorityError`, `Select Task Priority`)
    } else {
      handleErrors(`priorityError`, ``)
    }
    if (!tasks.dueDate) {
      handleErrors(`dueDateError`, `Select Due Date `)
    } else {
      handleErrors(`dueDateError`, ``)
    }
    if (!tasks.alertTime?.value) {
      handleErrors(`alertTimeError`, `Select Alert Time`)
    } else {
      handleErrors(`alertTimeError`, ``)
    }
    if (!tasks.recurring?.value?.length) {
      handleErrors(`recurringError`, `Select Recurring`)
    } else {
      handleErrors(`recurringError`, ``)
    }
    if (
      tasks.recurring?.value === `CUSTOM` &&
      !tasks.frequency?.value?.length
    ) {
      handleErrors(`frequencyError`, `Select Frequency`)
    } else {
      handleErrors(`frequencyError`, ``)
    }

    if (tasks.recurring?.value === `CUSTOM` && !tasks.afterEvery?.value) {
      handleErrors(`afterEveryError`, `Select After Every `)
    } else {
      handleErrors(`afterEveryError`, ``)
    }
    if (!tasks.title?.length) {
      handleErrors(`titleError`, `Enter Title`)
    } else {
      handleErrors(`titleError`, ``)
    }

    if (!tasks?.description?.length) {
      handleErrors(`descriptionError`, `Enter Description`)
    } else {
      handleErrors(`descriptionError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }
  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.franchiseError.length &&
      !errors.locationError.length &&
      !errors.assignToError.length &&
      !errors.taskTypeError.length &&
      !errors.priorityError.length &&
      !errors.dueDateError.length &&
      !errors.alertTimeError.length &&
      !errors.recurringError.length &&
      !errors.frequencyError.length &&
      !errors.afterEveryError.length &&
      !errors.titleError.length &&
      !errors.descriptionError.length
    ) {
      mutate(preparePayload())

      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])
  const preparePayload = () => {
    let data = {}
    if (tasks.title?.length) {
      data.title = tasks.title
    }
    if (tasks?.description?.length) {
      data.description = tasks.description
    }

    if (tasks.assignTo?.value?.length) {
      data.assignTo = tasks.assignTo.value
    }
    if (tasks.dueDate) {
      data.dueDate = tasks.dueDate
    }
    if (tasks.alertTime.value) {
      data.alertTime = tasks.alertTime.value
    }

    if (tasks.taskType?.value?.length) {
      data.taskType = tasks.taskType.value
    }
    if (franchise?.value?.length) {
      data._franchise = franchise.value
    } else {
      data._franchise = _franchise?._id
    }
    if (location?.value?.length) {
      data._location = location.value
    }

    // tasks.location

    if (tasks.priority?.value?.length) {
      data.priority = tasks.priority.value
    }

    if (
      tasks.recurring?.value?.length ||
      tasks.afterEvery?.value ||
      tasks.frequency?.value
    ) {
      data.recurring = {
        recurringType: tasks.recurring.value,
        recurringAfter: tasks.afterEvery.value,
        recurringCustomType: tasks.frequency.value,
      }
    }
    if (mediaUrls?.length) {
      data.attachments = mediaUrls
    }
    return data
  }

  const handleTaskChanges = (value, name) => {
    if (name === `recurring`) {
      if (value.value === `CUSTOM`) setRecurringValue(true)
      else {
        setRecurringValue(false)
        setTasks((prevState) => ({
          ...prevState,
          [name]: ``,
        }))
      }
    }

    if (name === `frequency`) {
      if (value.value === `DAY`) setAfterEveryTitle(`Day`)
      else if (value.value === `WEEK`) setAfterEveryTitle(`WEEK`)
      else if (value.value === `MONTH`) setAfterEveryTitle(`MONTH`)
      else if (value.value === `YEAR`) setAfterEveryTitle(`YEAR`)
    }

    setTasks((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const deleteHandler = (index) => {
    setFileName((prev) => {
      return prev.filter((item, ind) => ind !== index)
    })
    setMediaUrls((prev) => {
      return prev.filter((item, ind) => ind !== index)
    })
  }
  useEffect(() => {
    setLocation(``)
  }, [franchise])
  useEffect(() => {
    setTasks((prevState) => ({
      ...prevState,
      [`assignTo`]: ``,
    }))
  }, [location])
  return (
    <Container>
      <PageHeader padding="true">Add Tasks</PageHeader>

      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <Spinner loading={isLoading} />
      <div
        className={`px-4 bg-white ${
          isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        {/* titles basic form */}
        {permissionGranted([`super-admin:*`]) && (
          <div className="flex gap-6 ">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Task Title *"
                placeholder="Enter Task Title"
                maxLength={100}
                value={tasks.title}
                errorMessage={errors.titleError}
                setValue={(e) => {
                  handleTaskChanges(e.target.value, `title`)
                }}
              />
            </div>
          </div>
        )}
        <div className="grid grid-cols-2 2xl:grid-cols-4 gap-6 my-2">
          {!permissionGranted([`super-admin:*`]) && (
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Task Title *"
                placeholder="Enter Task Title"
                maxLength={100}
                value={tasks.title}
                errorMessage={errors.titleError}
                setValue={(e) => {
                  handleTaskChanges(e.target.value, `title`)
                }}
              />
            </div>
          )}
          {permissionGranted([`super-admin:*`]) && (
            <>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Franchise *"
                  placeholder="Select Franchise"
                  value={tasks.franchise}
                  defaultValue={{
                    label: _franchise?.name,
                    value: _franchise?._id,
                  }}
                  setValue={(value) => setFranchise(value)}
                  isMulti={false}
                  loadOptions={loadFranchises}
                  isSearchable={true}
                />
              </div>

              <div className="flex-1 relative">
                <ReactSelectField
                  title="Location *"
                  placeholder="Select Location"
                  errorMessage={errors.locationError}
                  setValue={(value) => setLocation(value)}
                  value={location}
                  isMulti={false}
                  loadOptions={loadLocations}
                  isSearchable={true}
                  cacheUniqs={franchise}
                />
              </div>
            </>
          )}
          {!permissionGranted([`super-admin:*`]) && (
            <div className="flex-1 relative">
              <ReactSelectField
                title="Location *"
                placeholder="Select Location"
                errorMessage={errors.locationError}
                value={tasks.location}
                setValue={(value) => setLocation(value)}
                isMulti={false}
                loadOptions={
                  permissionGranted([`corporate-manager:*`, `manager:*`])
                    ? loadLocations
                    : loadAgentLocations
                }
                isSearchable={true}
                cacheUniqs={franchise}
              />
            </div>
          )}
          {location?.label && (
            <div className="flex-1 relative">
              <ReactSelectField
                title="Assign To *"
                placeholder="Select Agent"
                errorMessage={errors.assignToError}
                value={tasks.assignTo}
                setValue={(value) => {
                  handleTaskChanges(value, `assignTo`)
                }}
                isMulti={false}
                loadOptions={loadAgents}
                isSearchable={true}
                cacheUniqs={location}
                additionalFilters={{
                  franchiseId: franchise.value
                    ? franchise.value
                    : _franchise?._id,
                  locations: [location?.value],
                  permissions: allowedSystemPermissions.task,
                }}
              />
            </div>
          )}

          <div className="flex-1 relative">
            <ReactSelectField
              title="Task Type *"
              placeholder="Select Task Type"
              errorMessage={errors.taskTypeError}
              value={tasks.taskType}
              setValue={(value) => {
                handleTaskChanges(value, `taskType`)
              }}
              isMulti={false}
              loadOptions={loadAllTaskTypes}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <DatePickerField
              title="Due *"
              placeholder="Select Due Date"
              errorMessage={errors.dueDateError}
              value={tasks?.dueDate}
              timePicker={true}
              setValue={(e) => {
                const selectedDateTime = new Date(e)
                const currentDateTime = new Date()
                if (selectedDateTime < currentDateTime) {
                  return
                }
                handleTaskChanges(e, `dueDate`)
              }}
              minDate={new Date()}
              dateFormat={`Pp`}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Priority *"
              placeholder="Select Priority"
              errorMessage={errors.priorityError}
              value={tasks.priority}
              setValue={(value) => {
                handleTaskChanges(value, `priority`)
              }}
              isMulti={false}
              loadOptions={loadAllTicketsPriorities}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Alert Time *"
              placeholder="Select Alert Time"
              errorMessage={errors.alertTimeError}
              value={tasks.alertTime}
              setValue={(value) => {
                handleTaskChanges(value, `alertTime`)
              }}
              isMulti={false}
              loadOptions={loadAlertTime}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Recurring *"
              placeholder="Select Recurring"
              errorMessage={errors.recurringError}
              value={tasks.recurring}
              setValue={(value) => {
                handleTaskChanges(value, `recurring`)
              }}
              isMulti={false}
              loadOptions={customRecurring}
              isSearchable={false}
            />
          </div>
          {recurringValue && (
            <>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Frequency *"
                  placeholder="Select Frequency"
                  errorMessage={errors.frequencyError}
                  value={tasks.frequency}
                  setValue={(value) => {
                    handleTaskChanges(value, `frequency`)
                  }}
                  isMulti={false}
                  setRecurringValue={true}
                  loadOptions={loadFrequency}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title={`After Every ${afterEveryTitle} *`}
                  placeholder="Select After Every"
                  errorMessage={errors.afterEveryError}
                  value={tasks.afterEvery}
                  setValue={(value) => {
                    handleTaskChanges(value, `afterEvery`)
                  }}
                  isMulti={false}
                  setRecurringValue={true}
                  loadOptions={afterEvery}
                  isSearchable={false}
                />
              </div>
            </>
          )}
        </div>

        <div className="flex gap-6 my-1">
          <div className="flex-1 relative">
            <TextArea
              type="textarea"
              title="Description *"
              placeholder="Enter Description"
              autoFocus={false}
              value={tasks.description}
              errorMessage={errors.descriptionError}
              setValue={(e) => {
                handleTaskChanges(e.target.value, `description`)
              }}
            />
            {permissionGranted([
              `uploadFile:create`,
              `corporate-manager:*`,
              `manager:*`,
              `admin:*`,
            ]) && (
              <span
                data-tip="Add Files"
                className={`tooltip tooltip-left absolute text-blue-500 right-3 cursor-pointer ${
                  errors?.descriptionError ? `bottom-10` : ` bottom-4`
                }`}
              >
                <FontAwesomeIcon
                  icon={faPaperclip}
                  onClick={() => fileRef.click()}
                />
                <input
                  className="hidden"
                  type="file"
                  name="attachment"
                  ref={(refParam) => (fileRef = refParam)}
                  key={getNonce(3, `aA0`)}
                  onChange={(e) => {
                    handleFileUpload(e.target.files)
                  }}
                  accept="application/pdf,.docx,.doc, video/*,image/*"
                  multiple
                />
              </span>
            )}
          </div>
        </div>
        <div className="gap-6 my-1">
          <ul className="grid grid-flow-row-dense lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2">
            {!loadingFileUrl && !uploadingError
              ? fileName.map((item, index) => (
                  <li className="list-none border-3 rounded-md border-white bg-gray-100  flex justify-between py-2 px-2 ">
                    {item}
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      onClick={() => deleteHandler(index)}
                      className="bg-red ml-2 mt-1"
                    />
                  </li>
                ))
              : ``}
          </ul>
        </div>

        <div className="flex justify-center">
          <div className="text-center w-96 my-5">
            <SubmitButton onClick={validateForm} disabled={loadingFileUrl}>
              {loadingFileUrl ? `Uploading....` : `Add Task`}
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
