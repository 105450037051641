import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const SystemRoles = React.lazy(dynamicImportComponents[`/admin/system-roles`])

export const SystemRolesPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <SystemRoles />
  </Suspense>
)

export { UpdateSystemRolesPage } from './updateSystemRolePage'
export { PreviewSystemRolesPage } from './previewSystemRolePage'
export { AddSystemRolePage } from './addSystemRolePage'
