import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const PreviewSystemRole = React.lazy(dynamicImportComponents[`system-roles-view`],
)

export const PreviewSystemRolesPage = () => (
    <Suspense fallback={<Spinner loading />}>
        <PreviewSystemRole />
    </Suspense>
)
