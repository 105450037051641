import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AllTicketsCategory = React.lazy(dynamicImportComponents[`/tickets/category`])


export const AllTicketCategoryPage = () => (
    <Suspense fallback={<Spinner loading />}>
        <AllTicketsCategory />
    </Suspense>
)