import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const UpdateProfile = React.lazy(dynamicImportComponents[`/profile/update`])


export const ProfileUpdatePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <UpdateProfile />
  </Suspense>
)
