import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AllFranchise = React.lazy(dynamicImportComponents[`/admin/franchises`])

export const AllFranchisesPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllFranchise />
  </Suspense>
)
