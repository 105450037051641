import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const Messenger = React.lazy(dynamicImportComponents[`/messenger`])


export const MessengerPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <Messenger />
  </Suspense>
)
