import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const Customer = React.lazy(dynamicImportComponents[`/customers/preview`])

export const CustomerPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <Customer />
  </Suspense>
)
