import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const QuickEstimate = React.lazy(dynamicImportComponents[`/raters/quickestimate`])

export const QuickEstimatePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <QuickEstimate />
  </Suspense>
)
