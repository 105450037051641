import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setActiveCommunicationsTab,
  setCommunicationCredentials,
} from 'App/Redux/actions'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import { Message } from './Message'
import { TOne, TThree, TTwo } from 'App/Styled'
import { Call } from './Call'
import { Video } from './Video'
import { RadioButton } from '../Common/RadionButton'
// import { Gmail } from './Gmail'

const communicationsTabs = [
  {
    tName: `SMS`,
    tNumber: 1,
    permission: [
      `customers:read`,
      `leads:read`,
      `quotes:read`,
      `data-transactions:read`,
      `call-center-user:*`,
    ],
  },
  {
    tName: `Call`,
    tNumber: 2,
    permission: [`customer-call:*`, `call-center-user:*`],
  },
  {
    tName: `Video`,
    tNumber: 3,
    permission: [
      `customers:read`,
      `leads:read`,
      `quotes:read`,
      `data-transactions:read`,
    ],
  },
  // {
  //   tName: `Gmail`,
  //   tNumber: 4,
  //   permission: [`customers:read`, `admin-franchise`, `admin-master-franchise`],
  // },
]

export const Communications = ({ nonCustomerCall, quoteOffers }) => {
  const { activeCommunicationTab } = useSelector(({ customer }) => customer)
  const { communicationCredentials, currentActiveCall } = useSelector(
    ({ currentCommunication }) => currentCommunication,
  )

  const dispatch = useDispatch()

  const [phoneType, setIsWorkPhone] = useState(`Phone`)

  const togglePhone = (val) => {
    setIsWorkPhone(val)
    // set work phone flag so we can change the receiver
    //  phone number in our messages and call services.

    dispatch(
      setCommunicationCredentials({
        ...communicationCredentials,
        isWorkPhone: val === `Work Phone`,
      }),
    )
  }

  return (
    <>
      {communicationCredentials?.workPhone && ( 
        <>
          <div className="flex gap-6 mt-4 text-xs">
            <div className="flex-1 relative">
              <RadioButton
                title="Phone"
                name="isWorkPhone"
                value="Phone"
                id="isWorkPhone"
                // errorMessage={location.lIsMasterError}
                checked={phoneType === `Phone`}
                setValue={(e) => togglePhone(e.target.value)}
                disabled={currentActiveCall.activeCall}
              />
            </div>
            <div className="flex-1 relative">
              <RadioButton
                title="Work Phone"
                name="isWorkPhone"
                value="Work Phone"
                id="isWorkPhone"
                // errorMessage={location.lIsMasterError}
                checked={phoneType === `Work Phone`}
                setValue={(e) => togglePhone(e.target.value)}
                disabled={currentActiveCall.activeCall}
              />
            </div>
          </div>
        </>
      )}
      <div className="mt-4">
        <TabsHeaders
          openTab={activeCommunicationTab}
          tabs={communicationsTabs}
          getActiveTab={(val) => dispatch(setActiveCommunicationsTab(val))}
          isCustomColor={quoteOffers}
        />
      </div>

      <div className="tab-content tab-space" key={phoneType}>
        {/* tab one */}

        <TOne opentab={activeCommunicationTab}>
          <Message />
        </TOne>

        {/* tab 2 */}
        <TTwo opentab={activeCommunicationTab}>
          <Call nonCustomerCall={nonCustomerCall} />
        </TTwo>

        {/* tab 3 */}
        <TThree opentab={activeCommunicationTab}>
          <Video />
        </TThree>

        {/* tab 4 */}
        {/* <TFour opentab={activeCommunicationTab}>
          <Gmail />
        </TFour> */}
      </div>
    </>
  )
}
