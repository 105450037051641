import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const DailyLedger = React.lazy(dynamicImportComponents[`/reports/daily-ledger`])

export const DailyLedgerPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <DailyLedger />
  </Suspense>
)
