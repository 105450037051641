import { Spinner } from 'App/Components/Common/Spinner'
import React, { Suspense } from 'react'

const Agent = React.lazy(() =>
  import(`App/Components/Agents/Agent`).then((module) => ({
    default: module.Agent,
  })),
)

export const AgentPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <Agent />
  </Suspense>
)
