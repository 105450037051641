import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AllPaymentTemplate = React.lazy(dynamicImportComponents[`/admin/paymenttemplates`])

export const AllPaymentTemplatePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllPaymentTemplate />
  </Suspense>
)
