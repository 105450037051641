/* eslint-disable no-unused-vars */
import { brandLogo } from 'App/Assets'
import { Container } from 'App/Styled'
import React, { useEffect, useRef, useState } from 'react'

import { Reload } from 'App/Components/Common/Reload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { Badge, TableBody, TableHeader, TableRow } from '@windmill/react-ui'
import { useReactToPrint } from 'react-to-print'
import dateFormat from 'App/Services/General/dateFormat'
import { ReactModal } from 'App/Components/Common/Modal'
import { VerifyPayment } from '../VerifyPayment'
import { permissionGranted } from 'App/Services'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { formatAmount } from 'App/Services/utils/formatAmount'

export const PaymentReceipt = ({
  previewData,
  isLoading,
  error,
  refetch,
  isFetching,
}) => {
  const componentRef = useRef()
  const [verifyModal, setVerifyModal] = useState(false)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const history = useHistory()
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.key === `p`) {
        e.preventDefault()
        handlePrint()
      }
    }
    document.addEventListener(`keydown`, handleKeyPress)

    return () => {
      document.removeEventListener(`keydown`, handleKeyPress)
    }
  }, [])

  const handleVerify = () => {
    setVerifyModal(true)
  }
  const handleUpdate = () => {
    history.push(
      `/customers/${previewData._insured?._id}/payments/${previewData?._id}/update`,
    )
  }

  return (
    <Container>
      <div className="px-3 text-right">
        <button
          onClick={handlePrint}
          className="bg-blue-500 text-white  rounded-md px-2 mt-2 mr-2"
        >
          Print
        </button>
        {permissionGranted([
          `super-admin:*`,
          `corporate-manager:*`,
          `manager:*`,
          `accountant:*`,
        ]) && (
          <button
            onClick={handleVerify}
            className="bg-blue-500 text-white rounded-md px-2 mt-2 mr-2"
          >
            Verify
          </button>
        )}
        {permissionGranted([
          `super-admin:*`,
          `corporate-manager:*`,
          `manager:*`,
          `accountant:*`,
        ]) && (
          <button
            onClick={handleUpdate}
            className="bg-blue-500 text-white rounded-md px-2 mt-2"
          >
            Edit
          </button>
        )}
      </div>

      {verifyModal && (
        <ReactModal
          title="Verify Invoice"
          closeModal={() => setVerifyModal(false)}
        >
          <VerifyPayment
            previewData={previewData}
            closeModal={() => setVerifyModal(false)}
          />
        </ReactModal>
      )}

      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <div className="grid grid-cols-2 " ref={componentRef}>
          <div className="px-3 mt-6">
            <img src={brandLogo} alt="Brand Logo" />
          </div>
          <div className="justify-self-end px-4 pt-6 text-sm">
            <div>
              <span className="font-semibold ">Policy created by: </span>
              <span>{previewData?._agent?.label}</span>
            </div>
            <div>
              <span className="font-semibold ">
                Invoice #{previewData?._invoiceId}
              </span>
            </div>
            <div>
              <span className="font-semibold ">Created Date: </span>
              <span>{dateFormat(previewData?.createdAt)}</span>
            </div>
            <div>
              <span className="font-semibold ">Due Date: </span>
              <span>{dateFormat(previewData?.dueDate)}</span>
            </div>

            <div>
              <span className="font-semibold ">Creator: </span>
              <span>{previewData?._creator?.label}</span>
            </div>
          </div>

          <div className="flex justify-between col-span-2">
            <div className="p-4 text-sm">
              {previewData?._location ? (
                <>
                  <p className="font-semibold">
                    {previewData?._location?.label}
                  </p>
                  <p>
                    {previewData?._location?.address?.street},
                    {previewData?._location?.address?.unitNo}
                  </p>
                  <p>
                    {`${previewData?._location?.address?.city}, 
                  ${previewData?._location?.address?.state}, 
                  ${previewData?._location?.address?.zip}`}
                  </p>
                  Phone: <span>{previewData?._location?.phone}</span>
                </>
              ) : null}
            </div>
            {previewData._insured && (
              <div className="p-4 text-sm text-right">
                <p className="font-semibold">{previewData._insured?.name}</p>
                <p>
                  {previewData._insured?.address?.street},
                  {previewData._insured?.address?.unitNo}
                </p>
                <p>
                  {`${previewData._insured?.address?.city}, 
                  ${previewData._insured?.address?.state}, 
                  ${previewData._insured?.address?.zip[0]}`}
                </p>
                Phone: <span>{previewData._insured?.phone}</span>
              </div>
            )}
          </div>
          <div className="px-4 my-2 overflow-x-auto  rounded-t-md col-span-2">
            <table className="w-full border-collapse">
              <TableHeader>
                <TableRow className="p-2 grid grid-cols-9">
                  <tr className="col-span-2">Billing Amounts</tr>
                  <tr className="text-center col-span-2">Customer</tr>
                  <tr className="text-center col-span-2">Carrier</tr>

                  <tr className="text-center col-span-2">Policy Number</tr>

                  <tr className="justify-self-end col-span-1">Amount Due</tr>
                </TableRow>
              </TableHeader>
              <TableBody>
                {previewData?.items?.map((item, index) => (
                  <TableRow className="grid grid-cols-9 py-1 px-2" key={index}>
                    <td className="col-span-2 text-sm">
                      {item?.feeType.replace(`_`, ` `)}
                    </td>
                    <td className="text-center col-span-2 text-sm">
                      {previewData._insured?.name}
                    </td>
                    <td className="text-center col-span-2 text-sm">
                      {previewData?._carrier?.label}
                    </td>

                    <td className="text-center col-span-2 text-sm">
                      {previewData._policy?.label
                        ? previewData._policy?.label?.toUpperCase()
                        : `N/A`}
                    </td>
                    <td className="justify-self-end text-center col-span-1 text-sm">
                      ${formatAmount(item?.amount)}
                    </td>
                  </TableRow>
                ))}
              </TableBody>
            </table>
          </div>
          <div className="px-4 my-2 overflow-x-auto  rounded-t-md col-span-2">
            <table className="w-full border-collapse">
              <TableHeader>
                <TableRow className="grid grid-cols-12 py-1 px-2">
                  <tr className="text-left col-span-2">Transaction Id</tr>
                  <tr className="text-center col-span-2">Transactions</tr>
                  <tr className="text-left col-span-2">Agent</tr>

                  <tr className="text-left col-span-3">Confirm Ref No</tr>
                  <tr className="text-left col-span-2">Transaction Date</tr>
                  <tr className="text-left col-span-1">Amount Paid</tr>
                </TableRow>
              </TableHeader>
              <TableBody>
                {previewData?.payments?.map((payment) =>
                  payment?.paymentItems?.map((item, index) => (
                    <TableRow
                      className="grid grid-cols-12 py-1 px-2"
                      key={index}
                    >
                      <td className="text-left col-span-2 text-sm mr-2">
                        {item?._trxId ? item?._trxId : <Badge>N/A</Badge>}
                      </td>
                      <td className="text-center col-span-2 text-sm">
                        {item?.paymentMethod
                          .replace(`_DEBIT`, ` `)
                          .replace(`_`, ` `)}
                      </td>
                      <td className="text-left col-span-2 text-sm">
                        {payment?._agent?.label ? (
                          payment?._agent?.label
                        ) : (
                          <Badge>N/A</Badge>
                        )}
                      </td>

                      <td className="text-left col-span-3 text-sm">
                        {item?.confirmRefNo}
                      </td>
                      <td className="text-left col-span-2 text-sm">
                        {dateFormat(payment?.transactionDate)}
                      </td>
                      <td className="text-left col-span-1 text-sm">
                        ${formatAmount(item?.amount)}
                      </td>
                    </TableRow>
                  )),
                )}
                <TableRow className="grid grid-cols-12 py-1 ">
                  <td className="col-span-12 justify-self-end">
                    Paid Amount:{` `}${formatAmount(previewData?.paidAmount)}
                  </td>
                </TableRow>
              </TableBody>
            </table>
          </div>

          <div className="px-4 my-2 overflow-x-auto  rounded-t-md col-span-2">
            <table className="w-full border-collapse">
              <TableHeader>
                <TableRow className="grid grid-cols-12 py-1 px-2">
                  <tr className="col-span-6">Summary</tr>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow className="grid grid-cols-12 py-1 px-2">
                  <td className="col-span-12 justify-self-end">
                    Amount Left to Pay:{` `}$
                    {formatAmount(previewData?.remainingAmount)}
                  </td>
                </TableRow>
              </TableBody>
            </table>
          </div>
          <div className="px-4 my-2 overflow-x-auto text-sm rounded-t-md col-span-2">
            <table className="w-full border-collapse">
              <TableHeader>
                <TableRow className="grid grid-cols-12 py-1 px-2">
                  <tr className="col-span-6">Payment Notes</tr>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow className="grid grid-cols-12 py-1 px-2">
                  <th className="flex justify-between col-span-4">
                    <p>Method</p>
                    <p>Notes</p>
                  </th>
                </TableRow>
                {previewData?.payments?.map((payment) =>
                  payment.paymentItems.map((item, index) => (
                    <TableRow
                      className="grid grid-cols-12 py-1 px-2"
                      key={index}
                    >
                      <div className="flex justify-between col-span-4">
                        <p>{item?.paymentMethod}</p>
                        <p>{item?.paymentNote}</p>
                      </div>
                    </TableRow>
                  )),
                )}
              </TableBody>
            </table>
          </div>
        </div>
      )}
    </Container>
  )
}
