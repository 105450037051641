import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const AllRequests = React.lazy(dynamicImportComponents[`/policies/requests`])


export const AllRequestsPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllRequests />
  </Suspense>
)