import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const PreviewLead = React.lazy(dynamicImportComponents[`/crm/leads/preview`])

export const LeadsPreviewPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <PreviewLead />
  </Suspense>
)
