import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'

let notificationShown = false // Global flag to control notifications

export const DatePickerField = ({
  title,
  placeholder,
  errorMessage,
  value,
  setValue,
  disabled = false,
  maxDate,
  minDate,
  requiredValue,
  timePicker,
  dateFormat,
  onKeyDown = () => {},
  isDOB = false,
}) => {
  const formatDate = (input) => {
    const digits = input?.replace(/\D/g, ``)
    if (digits?.length === 8) {
      return `${digits?.slice(0, 2)}/${digits?.slice(2, 4)}/${digits?.slice(4)}`
    }
    return input
  }

  const parseDate = (input) => {
    const [month, day, year] = input?.split(`/`)
    return new Date(`${year}-${month}-${day}`)
  }

  const isValidDate = (input) => {
    const [month, day, year] = input?.split(`/`)
    const monthNum = parseInt(month, 10)
    const dayNum = parseInt(day, 10)
    const yearNum = parseInt(year, 10)
    // Check month, day, year is valid or not
    if (
      monthNum < 1 ||
      monthNum > 12 ||
      dayNum < 1 ||
      dayNum > 31 ||
      yearNum < 1000 ||
      yearNum > 9999
    ) {
      return false // Invalid date
    }

    const daysInMonth = new Date(yearNum, monthNum, 0).getDate()
    return dayNum <= daysInMonth
  }

  const showNotification = (message) => {
    if (notificationShown) return // Prevent showing multiple notifications

    notificationShown = true
    NotificationManager.error(message)

    setTimeout(() => {
      notificationShown = false
    }, 6000)
  }

  const handleDateChangeRaw = (event) => {
    const rawInput = event.target.value
    const sanitizedInput = rawInput?.replace(/[^0-9/]/g, ``)

    if (sanitizedInput !== rawInput) {
      showNotification(`Only numbers and '/' are allowed.`)
    }

    const formattedValue = formatDate(sanitizedInput)
    event.target.value = formattedValue

    if (formattedValue?.length === 10) {
      if (!isValidDate(formattedValue)) {
        showNotification(`Invalid date. Please check the format (MM/DD/YYYY).`)
        setValue(null)
        return
      }

      const parsedDate = parseDate(formattedValue)
      const year = parseInt(formattedValue?.split(`/`)[2], 10)

      if (isDOB && year > 2008) {
        showNotification(`Year should be less than or equal to 2008.`)
        setValue(null)
      } else {
        setValue(parsedDate)
      }
    } else if (sanitizedInput?.length > 10) {
      showNotification(`Date input is too long. Please use MM/DD/YYYY format.`)
      setValue(null)
    }
  }

  return (
    <>
      <label className="text-gray-700 font-medium text-sm">
        {title}
        {(errorMessage || requiredValue) && (
          <span className="text-red-500 required-dot"></span>
        )}
      </label>
      <DatePicker
        className={`rounded-md h-8 text-sm border-transparent flex-1 appearance-none border ${
          requiredValue ? `border-red-500 bg-yellow-50` : `border-gray-300`
        } w-full mt-0.5 px-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent ${
          disabled
            ? `bg-gray-100 text-gray-400 cursor-not-allowed`
            : `text-gray-700 `
        }`}
        placeholderText={placeholder}
        onChange={setValue}
        selected={value}
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        scrollableYearDropdown
        showTimeSelect={timePicker}
        yearDropdownItemNumber={150}
        dateFormat={dateFormat}
        onChangeRaw={handleDateChangeRaw}
        onKeyDown={onKeyDown}
      />
      {errorMessage && (
        <>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="absolute text-red-500 right-2 top-9"
          />
          <p className=" text-sm text-red-500 mb-1">{errorMessage}</p>
        </>
      )}
    </>
  )
}
