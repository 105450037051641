import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AllLeads = React.lazy(dynamicImportComponents[`/crm/leads`])

export const AllLeadsPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllLeads />
  </Suspense>
)
