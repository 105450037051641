import { ButtonSecondary } from 'App/Styled';
import React from 'react'

const ErrorFallBackPage = ({ error, resetErrorBoundary }) => {

    return (
        <div className="flex justify-center items-center h-screen">
            <div className='my-auto'>
                <p className='text-xl text-center font-bold'>Something went wrong!</p>
                <pre style={{ color: `red` }}>{error?.message}</pre>
                <ButtonSecondary onClick={resetErrorBoundary}>Refresh</ButtonSecondary>
            </div>
        </div>
    );

}

export default ErrorFallBackPage