import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AddRatingTemplate = React.lazy(dynamicImportComponents[`/raters/ratingtemplates/add`])


export const AddRatingTemplatePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AddRatingTemplate />
  </Suspense>
)
