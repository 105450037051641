import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'


const AllBroadCastingTemplates = React.lazy(dynamicImportComponents[`/broadcasts/templates`])


export const AllBroadCastingTemplatesPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllBroadCastingTemplates />
  </Suspense>
)
