// import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AddSystemRoles = React.lazy(dynamicImportComponents[`system-roles-add`])

export const AddSystemRolePage = () => (
    <Suspense fallback={`<Spinner loading />`}>
        <AddSystemRoles />
    </Suspense>
)
