import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports';
import React, { Suspense } from 'react'

const AllGroupCredentials = React.lazy(dynamicImportComponents[`/admin/group-credentials`]);



export const AllGroupCredentialsPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllGroupCredentials />
  </Suspense>
)
