import React, { Suspense } from 'react'
// import { useSelector } from 'react-redux'
import { permissionGranted } from 'App/Services'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const Assessments = React.lazy(dynamicImportComponents[`/assessment/weeks`])
const AllAssessments = React.lazy(() => import(`App/Components/Assessment/AllAssessments`).then(module => ({
  default: module.AllAssessments
})))

export const AllAssessmentsPage = () => {
  return permissionGranted([`manager:*`, `corporate-manager:*`, `admin:*`]) ? (
    <Suspense fallback={<Spinner loading />}>
      <Assessments />
    </Suspense>
  ) : (
    <Suspense fallback={<Spinner loading />}>
      <AllAssessments />
    </Suspense>
  )
}
