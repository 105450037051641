import { Spinner } from 'App/Components/Common/Spinner'
import React, { Suspense } from 'react'

const UpdateSystemRole = React.lazy(() =>
    import(`App/Components/SystemRoles/UpdateSystemRole`).then((module) => ({
        default: module.UpdateSystemRole,
    })),
)

export const UpdateSystemRolesPage = () => (
    <Suspense fallback={<Spinner loading />}>
        <UpdateSystemRole />
    </Suspense>
)
