import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports';
import React, { Suspense } from 'react'

const CommunicationLogs = React.lazy(dynamicImportComponents[`/admin/cs-logs`]);



export const CommunicationLogsPage = () => (
    <Suspense fallback={<Spinner loading />}>
        <CommunicationLogs />
    </Suspense>
)