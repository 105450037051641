import { Spinner } from 'App/Components/Common/Spinner'
import React, { Suspense } from 'react'


const UpdateAgent = React.lazy(() =>
  import(`App/Components/Agents/UpdateAgent`).then((module) => ({
    default: module.UpdateAgent,
  })),
)

export const UpdateAgentPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <UpdateAgent />
  </Suspense>
)