import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const QuoteSold = React.lazy(dynamicImportComponents[`/raters/myquotes/update`])

const QuoteSoldPage = () => (
    <Suspense fallback={<Spinner loading />}>
        <QuoteSold />
    </Suspense>
)

export default QuoteSoldPage