import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const UpdateContact = React.lazy(dynamicImportComponents[`/customers/contacts/update`])


export const UpdateContactPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <UpdateContact />
  </Suspense>
)
