import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const Locations = React.lazy(dynamicImportComponents[`/admin/locations`])

export const AllLocationPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <Locations />
  </Suspense>
)
