/* eslint-disable no-unused-vars */
import { Card } from 'App/Components/Common/Card'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { useHistory, useLocation } from 'react-router-dom'
import { Reload } from 'App/Components/Common/Reload'
import InfiniteScroll from 'react-infinite-scroller'
import { NotificationCardkelton } from '../PushNotifications/notificationCardSkelton'

import {
  setChatLatestLength,
  setChatPreviousLength,
} from 'App/Redux/Slices/Notifications'
import { setChatRoomId, setNonCustomerNumber } from 'App/Redux/actions'

export const ChatNotification = () => {
  const dispatch = useDispatch()
  const { chatLatestLength } = useSelector(({ notification }) => notification)
  const user = useSelector(({ user: { user } }) => user)
  const location = useLocation()
  const history = useHistory()
  const [currentIndex, setCurrentIndex] = useState(``)
  const [currentPage, setCurrentPage] = useState(1)

  const [chatNotificationData, setChatNotificationData] = useState([])
  const {
    isLoading,
    data: _chatNotificationData,
    refetch: getAllChatNotifications,
    isRefetching,
    error,
  } = useQuery(
    `ChatNotificationApi${currentPage}`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/notifications/unread/all?page=${currentPage}&offset=15&query=${`chatNotifications`}`,
      ).then((res) => {
        // if (chatNotificationData?.length < chatLatestLength)
        //   setCurrentPage(currentPage + 1)
        // else {
        //   setCurrentPage(res?.data?.data?.totalChatNotifications)
        // }

        // dispatch(setChatPreviousLength(chatLatestLength))
        dispatch(setChatLatestLength(res.data.data.unreadRecords))
        return res.data.data
      }),
    {
      // enabled: chatLatestLength > 0,
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setChatNotificationData((prev) => [...prev, ...res?.chatNotifications])
      },
    },
  )

  // const loadChatNotifications = async () => {
  //   setIsLoading(true)
  //   await AxiosInstance.get(
  //     `${AMS_BACKEND_API}/api/notifications?search={"query": ""}&sort=""&page=${currentPage}&offset=5`,
  //   )
  //     .then((res) => {
  //       console.log(`notificationss=>`, res.data.data.chatNotifications)
  //       if (notificationData?.length < latestLength) {
  //         setCurrentPage(currentPage + 1)
  //         setNotificationData([
  //           ...notificationData,
  //           ...res.data.data.chatNotifications,
  //         ])
  //       } else {
  //         setCurrentPage(res?.data?.data?.totalPages)
  //       }
  //       setError(false)

  //       setIsLoading(false)
  //       dispatch(setPreviousLength(latestLength))
  //       dispatch(setLatestLength(res?.data?.data?.totalChatNotification))
  //       console.log(`page type `, typeof res?.data?.totalChatNotifications)
  //     })
  //     .catch((err) => {
  //       if (err) {
  //         NotificationManager.error(`Something Went Wrong`)
  //       }
  //       setError(true)
  //       setIsLoading(false)
  //     })
  // }

  // const {
  //   isLoading: notificationLoading,
  //   refetch,
  //   isFetching,
  //   error: notificationError,
  //   mutate,
  // } = useMutation(
  //   `NotificationApi`,
  //   async () =>
  //     await AxiosInstance.get(
  //       `${AMS_BACKEND_API}/api/notifications?search={"query": "",
  //       "onlyMasterNotifications": ${false}}&sort=""&page=${currentPage}&offset=${5}`,
  //     ).then((res) => {
  //       return res.data.data
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //     cacheTime: 1,
  //     onSuccess: (res) => {
  //       dispatch(setPreviousLength(latestLength))
  //       dispatch(setLatestLength(res.totalRecords))
  //       setNotificationData(res.notifications)
  //     },
  //   },
  // )

  // useEffect(() => {
  //   if (
  //     user.accessToken &&
  //     location.pathname !== `/auth/oidc-logout-redirect`
  //   ) {
  //     getAllChatNotifications()
  //   }
  // }, [])

  const { mutate: readNotification, isLoading: readingNotification } =
    useMutation(
      async (payload) => {
        if (!payload.isAlreadyRead || payload.isDeleted) {
          await AxiosInstance.patch(
            `${AMS_BACKEND_API}/api/notifications/${payload.id}`,
            {
              isRead: payload.isRead,
              isDeleted: payload.isDeleted,
            },
          ).then(() => {
            // getAllChatNotifications()
            setCurrentIndex(``)
            // setChatNotificationData((prev) =>
            //   prev.filter((notification) => notification?._id !== payload.id),
            // )
            setChatNotificationData([])
            // loadChatNotifications()
          })
        }
      },
      {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: () => {
          getAllChatNotifications()
        },
      },
    )

  useEffect(() => {
    currentPage > 1 && getAllChatNotifications()
  }, [currentPage])

  const loadMoreNotifications = () => {
    if (!isLoading && !isRefetching) {
      setCurrentPage(currentPage + 1)
    }
  }

  return (
    <div className="w-full">
      {/* <div className="px-1.5 py-0.5 bg-red-500 rounded-t-md text-white font-medium text-sm ">
        Chat Notifications
      </div> */}

      {error ? (
        <Reload refetch={() => getAllChatNotifications()} />
      ) : (
        <>
          {/* {isLoading && <NotificationCardkelton />} */}
          <div className="w-full py-1 gap-1 px-1 h-72  overflow-y-scroll hide-scroller hide-scroller-firefox ">
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMoreNotifications}
              hasMore={
                chatNotificationData?.length > 0 &&
                chatNotificationData?.length <
                  _chatNotificationData?.totalRecords
              }
              threshold={192}
              loader={<NotificationCardkelton />}
              useWindow={false}
            >
              {chatNotificationData?.length < 1 ? (
                isLoading || isRefetching ? (
                  <NotificationCardkelton />
                ) : (
                  <div className="text-center text-xs font-md text-gray-500 my-2">
                    No Chat Notifications Available.
                  </div>
                )
              ) : (
                chatNotificationData?.map((notification, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (!readingNotification && !isRefetching)
                        if (notification?.customerData?._id) {
                          if (notification.contactData?._id) {
                            history.push(
                              `/customers/${notification?.customerData?._id}/contact/${notification.contactData._id}`,
                            )
                          } else {
                            history.push(
                              `/customers/${notification?.customerData?._id}`,
                            )
                          }
                        } else if (notification?.leadData?._id) {
                          history.push(
                            `/crm/leads/${notification?.leadData?._id}`,
                          )
                        } else if (notification?.quoteData?._id) {
                          history.push(
                            `/raters/myquotes/${notification?.quoteData?._id}`,
                          )
                        } else if (
                          notification?.source === `INTERNAL-COMMUNICATION`
                        ) {
                          dispatch(setChatRoomId(notification?._chatRoomId))
                          history.push(`/messenger`)
                        } else {
                          dispatch(
                            setNonCustomerNumber(notification?.customerPhone),
                          )
                          history.push(`/communications`)
                        }
                      readNotification({
                        id: notification._id,
                        isRead: true,
                        isAlreadyRead: notification.isRead,
                      })
                    }}
                    className="cursor-pointer"
                  >
                    <Card
                      title={`${
                        notification.source === `SMS`
                          ? notification.contactData
                            ? notification.contactData.contactName
                            : notification?.customerData
                            ? notification?.customerData?.customerName
                            : notification?.leadData
                            ? notification?.leadData?.customerName
                            : notification?.quoteData
                            ? notification?.quoteData?.customerName
                            : notification?.customerPhone
                          : notification?.sender?.agentName
                      }`}
                      message={
                        notification.description || notification?.resourceType
                      }
                      source={notification.source}
                      createdAt={notification.createdAt}
                      deleteCard={() => {
                        readNotification({
                          id: notification._id,
                          isDeleted: true,
                          isRead: true,
                          isAlreadyRead: notification.isRead,
                        })

                        // setNotificationData((prev) =>
                        //   prev.filter(
                        //     (_notification) =>
                        //       _notification?._id !== notification._id,
                        //   ),
                        // )
                        // dispatch(setChatPreviousLength(chatLatestLength - 1))
                      }}
                      index={index}
                      isDeleting={readingNotification || isRefetching}
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      readCard={!notification?.isRead}
                    />
                  </div>
                ))
              )}
            </InfiniteScroll>
          </div>
        </>
      )}
    </div>
  )
}
