import { Spinner } from 'App/Components/Common/Spinner'
import React, { Suspense } from 'react'

const Vehicle = React.lazy(() => import(`App/Components/Customers/Customer/Tabs/Assets/Vehicle/Vehicle`).then(module => ({
  default: module.Vehicle
})))

export const VehiclePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <Vehicle />
  </Suspense>
)
