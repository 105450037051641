import React from 'react'
import InputMask from 'react-input-mask'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'

export const MaskField = ({
  type,
  value,
  name,
  title,
  id,
  disabled,
  placeholder,
  errorMessage,
  setValue,
  validating,
  mask = `+19999999999`,
  requiredValue,
  ...props
}) => {
  return (
    <>
      <label className="text-gray-700 font-medium text-sm">
        {title}
        {(errorMessage || requiredValue) && (
          <span className="text-red-500 required-dot"></span>
        )}
      </label>
      <InputMask
        type={type}
        mask={mask}
        maskChar={null}
        className={`rounded-md border-transparent 
        w-full mt-0.5 px-2 
        flex-1 appearance-none border bg-white ${
          requiredValue ? `border-red-500 bg-yellow-50` : `border-gray-300`
        } py-0.5 text-sm h-8
      placeholder-gray-400 shadow-sm 
       focus:outline-none focus:ring-2
        focus:ring-blue-400 focus:border-transparent ${
          disabled
            ? `bg-gray-100 text-gray-400 cursor-not-allowed`
            : `text-gray-700`
        }`}
        name={name}
        id={id}
        disabled={disabled || validating}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e)}
        {...props}
      />
      {validating ? (
        <FontAwesomeIcon
          icon={faSpinner}
          className="absolute text-red-500 right-2 mt-3"
          spin={true}
        />
      ) : (
        errorMessage && (
          <>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="absolute text-red-500 right-2 mt-3"
            />
            <p className=" text-sm text-red-500">{errorMessage}</p>
          </>
        )
      )}
    </>
  )
}
