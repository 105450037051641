import React, { useContext } from 'react'
import { Container } from 'App/Styled'
import { CardField } from 'App/Components/Common/CardField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { Reload } from 'App/Components/Common/Reload'
import { CustomerContext } from '../../..'
import dateFormat from 'App/Services/General/dateFormat'

export const About = () => {
  const { currentCustomer } = useSelector(({ customer }) => customer)
  const { isLoading, isFetching, refetch, error } = useContext(CustomerContext)
  return (
    <>
      <Container>
        <div className="flex flex-col gap-0 ml-16">
          <div className="grid grid-cols-12 px-4 py-2">
            <div className="col-span-6 text-xl">Customer Info</div>
          </div>
          <hr />

          <div className="flex flex-col px-2">
            {isLoading || isFetching ? (
              <div className="text-center py-10">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  size="1x"
                  className="text-red-500"
                />
              </div>
            ) : error ? (
              <Reload refetch={() => refetch()} />
            ) : (
              <div className="flex flex-row">
                <div className="flex-1">
                  <div className="rounded-md overflow-hidden py-2">
                    <div className="flex items-start justify-evenly">
                      <div className="flex-1 mx-2 rounded">
                        <div className="flex">
                          <div className="flex-1">
                            <CardField
                              title="Gender"
                              value={currentCustomer?.gender?.replace(`_`, ` `)}
                            />
                            <CardField
                              title="Status"
                              value={currentCustomer?.status}
                            />
                            <CardField
                              title="Work Phone"
                              value={currentCustomer?.contactInfo?.workPhone}
                            />
                            <CardField
                              title="Fax"
                              value={currentCustomer?.contactInfo?.fax}
                            />
                          </div>
                          <div className="flex-1">
                            <CardField
                              title="Legacy Id"
                              value={currentCustomer?.legacyId}
                            />
                            <CardField
                              title="PCM"
                              value={currentCustomer?.preferredContactMethods}
                              multiple={true}
                            />
                            <CardField
                              title="Created"
                              value={dateFormat(currentCustomer?.createdAt)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  )
}
