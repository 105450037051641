import { Spinner } from 'App/Components/Common/Spinner'
import React, { Suspense } from 'react'

const Driver = React.lazy(() => import(`App/Components/Customers/Customer/Tabs/Assets/Driver/Driver`).then(module => ({
  default: module.Driver
})))


export const DriverPreviewPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <Driver />
  </Suspense>
)
