import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AllTasks = React.lazy(dynamicImportComponents[`/taskmanager`])


export const AllTasksPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllTasks />
  </Suspense>
)