import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const AddTemplate = React.lazy(dynamicImportComponents[`/assessment/template/add`])

export const AddTemplatePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AddTemplate />
  </Suspense>
)
