import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const PreviewTemplate = React.lazy(dynamicImportComponents[`/assessment/template/preview`])

export const PreviewTemplatesPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <PreviewTemplate />
  </Suspense>
)
