import React from 'react'
import { Badge } from '@windmill/react-ui'
import { TimeAgo } from 'App/Components/Common/TimeAgo'
import dateFormat from 'App/Services/General/dateFormat'
export const UpdateLogs = ({ logs }) => {
  const reversedLogs = [...logs]
  return (
    <>
      {!reversedLogs.length ? (
        <div className="text-center my-6 text-sm text-gray-400 font-bold">
          No Logs Found
        </div>
      ) : (
        <div className="overflow-y-auto hide-scroller hide-scroller-firefox h-420">
          {reversedLogs?.reverse()?.map((log, index) => (
            <div
              className="flex flex-col mx-auto rounded-xl shadow-sm  border-1 my-1 py-1.5"
              key={`logs` + index}
            >
              <div className="flex flex-row px-1">
                <div className="flex flex-row items-center justify-center px-1">
                  <span className="font-semibold text-indigo-500 text-sm">
                    {log._updater?.label ||
                      `${
                        log._updater?.firstName + ` ` + log._updater?.lastName
                      }`}
                  </span>
                  <span className="mx-1 text-xs flex items-center">
                    <TimeAgo timeStamp={log.updatedAt} />
                  </span>
                </div>
              </div>

              <div className="relative rounded-md mx-2 my-0.5 bg-blue-50 px-4 text-gray-600 dark:text-white">
                <div className="grid grid-cols-12 gap-3">
                  {log?.update?.operation ? (
                    <div className="col-span-11">
                      <Badge type="primary" className="mr-2">
                        {log?.update?.field?.split(`.`)?.slice(-1)[0]}
                      </Badge>
                      {log?.update?.operation.toLowerCase()} &nbsp;
                      {log?.update?.value?.split(``)[0] === `[` &&
                      log?.update?.value?.split(``)?.slice(-1)[0] === `]` ? (
                        JSON?.parse(log?.update?.value).map((val, index) => (
                          <span key={index} className="mr-2">
                            <Badge type="success">{val}</Badge>
                          </span>
                        ))
                      ) : (
                        <>
                          {log?.update?.operation === `UPDATE` &&
                            (log?.update?.oldValue == null ||
                            log?.update?.oldValue.toLowerCase() ==
                              `undefined` ||
                            log?.update?.oldValue === `` ? (
                              <>
                                &nbsp; from &nbsp;
                                <span className="break-words px-2 bg-yellow-100 text-xs">
                                  N/A
                                </span>
                                &nbsp; to &nbsp;
                              </>
                            ) : (
                              <>
                                &nbsp; from &nbsp;
                                <span className="break-words px-2 bg-yellow-100 text-xs">
                                  {log?.update?.oldValue?.includes(
                                    `Coordinated Universal Time`,
                                  ) ||
                                  log?.update?.oldValue?.includes(`00.000Z`) ||
                                  log?.update?.oldValue?.includes(`GMT`)
                                    ? dateFormat(log?.update?.oldValue)
                                    : log?.update?.oldValue}
                                </span>
                                &nbsp; to &nbsp;
                              </>
                            ))}

                          <span className="break-words px-2 bg-green-200 text-xs">
                            {log?.update?.value?.includes(
                              `Coordinated Universal Time`,
                            ) ||
                            log?.update?.value?.includes(`00.000Z`) ||
                            log?.update?.value?.includes(`GMT`)
                              ? dateFormat(log?.update?.value)
                              : log?.update?.value}
                          </span>
                        </>
                      )}
                      {/* &nbsp; in &nbsp; */}
                    </div>
                  ) : (
                    <div className="col-span-11">
                      changed &nbsp;
                      <Badge type="primary">
                        {log?.update?.field
                          ?.split(`.`)
                          ?.slice(-1)[0]
                          ?.toUpperCase()}
                      </Badge>
                      &nbsp; to &nbsp;
                      {log?.update?.value?.split(``)[0] === `[` &&
                      log?.update?.value?.split(``)?.slice(-1)[0] === `]` ? (
                        JSON?.parse(log?.update?.value)?.map((val, index) => (
                          <span key={index} className="mr-2">
                            <Badge type="success">{val}</Badge>
                          </span>
                        ))
                      ) : (
                        <Badge type="success">{log?.update?.value}</Badge>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
