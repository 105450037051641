import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'
const UpdateCustomer = React.lazy(dynamicImportComponents[`/customers/update`])



export const UpdateCustomersPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <UpdateCustomer />
  </Suspense>
)
