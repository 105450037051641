/* eslint-disable no-unused-vars */
import {
  faMicrophone,
  faMicrophoneSlash,
  faPhone,
  faPhoneSlash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { smallLogo } from 'App/Assets'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import {
  setActiveCall,
  setCurrentCall,
  setCurrentCallStatus,
  setGlobalCallModalActive,
  setOutGoingCall,
  setOutboundMessages,
  setUnknownCaller,
} from 'App/Redux/actions'
import { CallBlock } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { NotificationManager } from 'react-notifications'
import { useLocation } from 'react-router-dom'
import { Loader } from 'App/Components/Common/Loader'
import { newSocket } from 'App/HOC/MainLayout'
import { permissionGranted } from 'App/Services'

export const Call = ({
  leadCall,
  invoiceCall,
  customClass,
  topBar,
  nonCustomerCall,
  globalCenter,
}) => {
  const { activeCall, outGoingCall } = useSelector(({ customer }) => customer)
  const { twilioDevice } = useSelector(({ communication }) => communication)
  const { communicationCredentials, currentActiveCall, currentIncomingCall } =
    useSelector(({ currentCommunication }) => currentCommunication)
  const { number, unknownCaller } = useSelector(
    ({ globalCommunications }) => globalCommunications,
  )

  const { user, preferredLocation } = useSelector(({ user }) => user)

  const [deviceStatus, setDeviceStatus] = useState(twilioDevice?.isBusy)
  const [loading, setLoading] = useState(false)

  const [incomingCall, setIncomingCall] = useState(``)
  const [isMuted, setIsMuted] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const { mutate: leadIsContacted } = useMutation(
    async () =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/leads/${leadCall?.leadId}/iscontacted`,
      ).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const {
    mutate: muteApi,
    // isLoading,
    // isError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/customers-chat/mute-participant`,
        {
          ...payload,
        },
      ).then((res) => {
        console.log(`res====>`, res)
        // NotificationManager.success(`Operation successful`)
        // !res.hasError && history.push(`/admin/carriers`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const callMute = () => {
    setIsMuted(!isMuted)
    invoiceCall
      ? muteApi({
          isMute: !isMuted,
          CallSid: twilioDevice?._activeCall?.parameters?.CallSid,
        })
      : outGoingCall?.mute(!isMuted)
  }

  useEffect(() => {
    if (leadCall?.leadId && currentActiveCall?.status === `in-progress`) {
      leadIsContacted()
    }
  }, [leadCall?.leadId, currentActiveCall?.status])
  const startCall = async () => {
    if (twilioDevice) {
      twilioDevice.on(`error`, (twilioError) => {
        if (twilioError.code === 31005) endCall()

        NotificationManager.error(`An error has occurred: `, `Try Again!`)
      })
      setLoading(true)
      if (!deviceStatus) {
        dispatch(
          setOutGoingCall(
            await twilioDevice.connect({
              params: {
                To: communicationCredentials?.isWorkPhone
                  ? communicationCredentials?.workPhone
                  : unknownCaller
                  ? number
                  : invoiceCall?._insured?.phone
                  ? invoiceCall?._insured?.phone
                  : leadCall?.leadPhone
                  ? leadCall?.leadPhone
                  : communicationCredentials?.phone,
                From: preferredLocation.phone,
                callType: `Simple`,
                _agentId: user?.profile?.sub,
                agentName: user?.profile?.full_name,
                agentImg: user?.profile?.dp,
                _franchise: user?.profile?._franchise?._id,
                _location: preferredLocation?._id,
                _direction: `OUTBOUND`,
              },
            }),
          ),
        )
      }

      setLoading(false)
      setDeviceStatus(twilioDevice?.isBusy)
      dispatch(
        setCurrentCall({
          callerId: communicationCredentials?.id,
          activeCall: twilioDevice?.isBusy,
        }),
      )
    }
  }
  const endCall = async () => {
    twilioDevice?.disconnectAll()
    dispatch(setActiveCall(false))
    setDeviceStatus(twilioDevice?.isBusy)
    dispatch(
      setCurrentCall({
        callerId: ``,
        activeCall: twilioDevice?.isBusy,
      }),
    )
    dispatch(setOutGoingCall(``))
    dispatch(setGlobalCallModalActive(false))
    dispatch(setUnknownCaller(false))
  }

  useEffect(() => {
    if (newSocket?.connected) {
      newSocket?.on(`OUTBOUND_VOICE_CALL_RES`, (message) => {
        if (
          message?._id &&
          communicationCredentials?.id === message?._customerId
        ) {
          dispatch(setOutboundMessages(message))
        }
        dispatch(setCurrentCallStatus(message?.status))
        if (
          message?.status === `no-answer` ||
          message?.status === `completed` ||
          message?.status === `busy` ||
          message?.status === `failed` ||
          message?.status === `cancelled`
        ) {
          dispatch(setActiveCall(false))
          dispatch(setGlobalCallModalActive(false))
          setDeviceStatus(twilioDevice?.isBusy)
          dispatch(
            setCurrentCall({
              callerId: ``,
              activeCall: twilioDevice?.isBusy,
            }),
          )
          endCall()
        }
      })

      newSocket?.on(`INBOUND_VOICE_CALL_RES`, (message) => {
        if (
          message?._id &&
          communicationCredentials?.id === message?._customerId
        ) {
          dispatch(setOutboundMessages(message))
        }
        dispatch(setCurrentCallStatus(message?.status))
        if (
          message?.status === `no-answer` ||
          message?.status === `completed` ||
          message?.status === `busy` ||
          message?.status === `failed` ||
          message?.status === `cancelled`
        ) {
          dispatch(setActiveCall(false))
          dispatch(setGlobalCallModalActive(false))
          setDeviceStatus(twilioDevice?.isBusy)
          dispatch(
            setCurrentCall({
              callerId: ``,
              activeCall: twilioDevice?.isBusy,
            }),
          )
          endCall()
        }
      })
    }
    return () => {
      newSocket?.off(`INBOUND_VOICE_CALL_RES`)
      newSocket?.off(`OUTBOUND_VOICE_CALL_RES`)
    }
  }, [newSocket?.connected])
  useEffect(() => {
    if (twilioDevice && currentActiveCall?.status !== `in-progress`) {
      twilioDevice.on(`incoming`, (call) => {
        setIncomingCall(call)
      })
    }
  }, [twilioDevice])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {communicationCredentials?.doNotContact ? (
            <div className="flex justify-center items-center h-96 max-h-xl font-semibold text-sm text-gray-500">
              <p>DNC enabled</p>
            </div>
          ) : communicationCredentials?.preferredContactMethods?.length &&
            !communicationCredentials?.preferredContactMethods?.includes(
              `CALL`,
            ) ? (
            <div className="grid place-content-center place-items-center gap-y-2 h-96 max-h-xl font-semibold text-sm text-gray-500">
              <div className=" rounded-full bg-gray-200 p-4">
                <FontAwesomeIcon icon={faPhone} size="lg" />
              </div>
              <p>Not Preferred</p>
            </div>
          ) : communicationCredentials?.phone ||
            leadCall?.leadId ||
            topBar ||
            (unknownCaller && number) ? (
            <div
              className={`flex flex-col ${
                leadCall?.leadId && `w-80`
              } justify-center items-center ${
                activeCall ? `gap-20` : `gap-40`
              } pt-14 `}
            >
              <div className="flex items-center px-14 gap-2 w-auto relative">
                <img
                  className="bg-gray-100 z-10 border-1 object-cover rounded-full h-20 w-20 "
                  alt="fImage"
                  src={
                    leadCall?.leadId
                      ? smallLogo
                      : communicationCredentials?.img
                      ? communicationCredentials?.img
                      : smallLogo
                  }
                />
                {(activeCall &&
                  location?.pathname.split(`/`)[2] ===
                    currentActiveCall?.callerId) ||
                  (nonCustomerCall &&
                    activeCall &&
                    location?.pathname.split(`/`)[3] ===
                      currentActiveCall?.callerId &&
                    currentActiveCall?.activeCall && (
                      <div className="absolute mx-auto  h-20 w-20 rounded-full bg-gray-500  animate-call"></div>
                    ))}
              </div>

              <div className="flex flex-col items-center m-1 px-6 gap-6 -mt-16  w-auto">
                {(activeCall &&
                  (location?.pathname.split(`/`)[2] === `quoteform` ||
                    location?.pathname.split(`/`)[2] === `leads` ||
                    location?.pathname.split(`/`)[2] ===
                      currentActiveCall?.callerId ||
                    location?.pathname.includes(`rerate`))) ||
                (nonCustomerCall &&
                  activeCall &&
                  location?.pathname.split(`/`)[3] ===
                    currentActiveCall?.callerId) ||
                topBar ||
                (!unknownCaller && globalCenter) ? (
                  <>
                    <CallBlock className="capitalize">
                      {currentActiveCall?.status === undefined ||
                      currentActiveCall?.status === `initiated`
                        ? `Calling...`
                        : currentActiveCall?.status}
                    </CallBlock>
                    <div className="space-x-10">
                      {
                        <CallBlock
                          data-tip={`${isMuted ? `Unmute` : `Mute`}`}
                          className={`tooltip hover:text-gray-600 ${
                            isMuted ? `text-red-600` : `text-gray-600`
                          }`}
                          onClick={callMute}
                        >
                          <FontAwesomeIcon
                            icon={isMuted ? faMicrophoneSlash : faMicrophone}
                            className="cursor-pointer  hover:text-gray-800"
                            size="lg"
                          />
                        </CallBlock>
                      }
                      <CallBlock
                        data-tip="Hang Up"
                        className="tooltip hover:text-red-500"
                        onClick={() => {
                          endCall()
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPhoneSlash}
                          className="cursor-pointer "
                          size="lg"
                        />
                      </CallBlock>
                    </div>
                  </>
                ) : twilioDevice?.isBusy &&
                  currentActiveCall?.status === `in-progress` ? (
                  <span>Incoming Call is in Progress</span>
                ) : currentActiveCall?.status !== `in-progress` ||
                  currentActiveCall?.status !== `ringing` ? (
                  <>
                    <CallBlock
                      data-tip="Start Call "
                      className=" hover:text-gray-600 tooltip"
                      onClick={() => {
                        dispatch(setActiveCall(true))
                        dispatch(setUnknownCaller(false))
                        startCall()
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPhone}
                        className="cursor-pointer text-green-600"
                        size="lg"
                      />
                    </CallBlock>
                    <span>Click to Call</span>
                  </>
                ) : (
                  <span>Call is in Progress</span>
                )}
              </div>
            </div>
          ) : (
            <div className="grid place-content-center place-items-center gap-y-2 h-96 max-h-xl font-semibold text-sm text-gray-500">
              <div className=" rounded-full bg-gray-200 p-4">
                <FontAwesomeIcon icon={faPhone} size="lg" />
              </div>
              <p>Contact info is not provided</p>
            </div>
          )}
        </>
      )}
    </>
  )
}
