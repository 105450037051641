import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const AllCarDealers = React.lazy(dynamicImportComponents[`/crm/cardealers`])


export const AllCarDealersPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllCarDealers />
  </Suspense>
)