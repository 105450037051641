/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import {
  Container,
  PageHeader,
  SecondaryHeading,
  SubmitButton,
} from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory } from 'react-router'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import {
  loadPaymentFeeTypes,
  permissionGranted,
  ValidateDuplicatePaymentTemplateName,
} from 'App/Services'
import { useRouteMatch } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { useSelector } from 'react-redux'
import { Checkbox } from 'App/Components/Common/Checkbox'

export const UpdatePaymentTemplate = () => {
  const history = useHistory()
  const {
    url,
    params: { templateId },
  } = useRouteMatch()
  const { paymentTemplate } = useSelector(
    ({ paymentTemplate }) => paymentTemplate,
  )

  const [isGlobalTemplate, setIsGlobalTemplate] = useState(false)
  const [paymentTemplateNameValidating, setPaymentTemplateNameValidating] =
    useState(false)

  const [templateName, setTemplateName] = useState(``)
  const [errors, setErrors] = useState({
    templateNameError: ``,
    formSubmit: false,
  })

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }
  const [templateFee, setTemplateFees] = useState([
    {
      feeType: ``,
      feeTypeError: ``,
      amount: ``,
      amountError: ``,
      description: ``,
      isAmountFixed: false,
      descriptionError: ``,
    },
  ])

  useEffect(() => {
    if (paymentTemplate) {
      setTemplateName(paymentTemplate?.name)
      setIsGlobalTemplate(paymentTemplate.isGlobal)
      let temps = paymentTemplate?.templates?.map((_eachTemplate) => ({
        feeType: { value: _eachTemplate?.type, label: _eachTemplate?.type },
        feeTypeError: ``,
        amount: _eachTemplate?.amount?.toString(),
        amountError: ``,
        isAmountFixed: _eachTemplate?.isAmountFixed || false,
        description: _eachTemplate?.description,
        descriptionError: ``,
      }))
      setTemplateFees(temps)
    }
  }, [])

  const handleFeesErrors = (name, error, ind) => {
    if (ind !== undefined) {
      const newFees = templateFee?.map((fee, index) => {
        if (index == ind) {
          fee[name] = error
        }
        return fee
      })
      setTemplateFees(newFees)
    }
  }

  const addFee = () => {
    setTemplateFees([
      ...templateFee,
      {
        feeType: ``,
        feeTypeError: ``,
        amount: ``,
        amountError: ``,
        isAmountFixed: false,
        description: ``,
      },
    ])
  }

  const removeFee = (ind) => {
    const newFees = [...templateFee]
    if (newFees?.length != 1) {
      newFees.splice(ind, 1)
      setTemplateFees(newFees)
    }
  }

  const handleInvoice = (value, name, ind) => {
    if (ind !== undefined) {
      const newFees = templateFee?.map((fee, index) => {
        if (index === ind) {
          fee[name] = value
        }
        return fee
      })
      setTemplateFees(newFees)
    }
  }

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/payment-templates/${templateId}`,
        payload,
      ).then((res) => {
        if (res.data) {
          history.push(`/admin/paymenttemplates`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    let formValidated = false
    if (errors?.formSubmit && !errors.templateNameError?.length) {
      const checkTemplateFeeError = (location) => {
        if (
          !location?.feeTypeError?.length &&
          !location?.amountError?.length &&
          !location?.descriptionError?.length
        ) {
          return true
        } else {
          return false
        }
      }
      formValidated = templateFee?.every(checkTemplateFeeError)
    }
    if (formValidated) {
      //  call API
      mutate({
        name: templateName,
        isGlobal: isGlobalTemplate,
        templates: templateFee.map((template) => ({
          type: template.feeType?.label,
          amount: template?.amount || undefined,
          isAmountFixed: template?.amount ? template?.isAmountFixed : undefined,
          description: template?.description || undefined,
        })),
      })
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const ValidateForm = () => {
    if (!templateName?.length) {
      handleErrors(`templateNameError`, `Enter Name`)
    } else {
      if (errors.templateNameError !== `Template name already exist`) {
        handleErrors(`templateNameError`, ``)
      }
    }
    for (const index in templateFee) {
      const fee = templateFee[index]

      if (!fee?.feeType?.value?.length) {
        handleFeesErrors(`feeTypeError`, `Select Fee Type`, index)
      } else {
        handleFeesErrors(`feeTypeError`, ``, index)
      }

      if (fee?.amount && fee?.amount < 1) {
        handleFeesErrors(`amountError`, `Enter Valid Amount`, index)
      } else {
        handleFeesErrors(`amountError`, ``, index)
      }
      if (fee?.feeType?.value?.length && fee?.amount) {
        handleFeesErrors(`formSubmit`, true, index)
      } else {
        handleFeesErrors(`formSubmit`, false, index)
      }
    }

    handleErrors(`formSubmit`, true)
  }

  const validatePaymentTemplateName = async (e) => {
    const { value } = e.target
    if (value?.length > 0 && paymentTemplate.name !== value.toUpperCase()) {
      setPaymentTemplateNameValidating(true)
      const response = await ValidateDuplicatePaymentTemplateName({
        name: value,
      })

      if (response?.hasError) {
        handleErrors(`formSubmit`, false)
        handleErrors(`templateNameError`, `Template name already exist`)
      } else {
        handleErrors(`formSubmit`, false)
        handleErrors(`templateNameError`, ``)
      }
      setPaymentTemplateNameValidating(false)
    }
  }

  return (
    <Container>
      <PageHeader>Update Payment Template</PageHeader>

      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading} />
      <div
        className={`p-3 bg-white ${
          isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        <div className="grid grid-cols-12 px-3 mb-4">
          <div className="col-span-11 flex gap-6 ">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Template Name *"
                placeholder="Enter Name"
                value={templateName}
                validating={paymentTemplateNameValidating}
                errorMessage={errors.templateNameError}
                setValue={(e) => setTemplateName(e.target.value)}
                onBlur={validatePaymentTemplateName}
              />
            </div>
            {permissionGranted([`super-admin:*`]) ? (
              <div className="flex-1 relative">
                <div className="relative mt-6">
                  <Checkbox
                    title="Global Template? "
                    name="globalTemplate"
                    value={isGlobalTemplate}
                    id="globalTemplate"
                    checked={isGlobalTemplate}
                    setValue={() => {
                      setIsGlobalTemplate((preState) => !preState)
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="flex-1 relative"></div>
            )}
            {` `}
            <div className="flex-1 relative"></div>
          </div>
        </div>

        <div className="px-2">
          {templateFee?.length > 0 &&
            templateFee?.map((item, index) => (
              <div key={index} className="grid grid-cols-12 px-2">
                <div className="col-span-11 flex gap-6 ">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Fee Type *"
                      placeholder="Select Type"
                      value={item.feeType}
                      errorMessage={item.feeTypeError}
                      setValue={(e) => handleInvoice(e, `feeType`, index)}
                      isMulti={false}
                      loadOptions={loadPaymentFeeTypes}
                      isSearchable={true}
                      additionalFilters={templateFee}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <InputField
                      type="number"
                      title="Amount *"
                      placeholder="Enter Amount"
                      value={item.amount}
                      errorMessage={item.amountError}
                      setValue={(e) =>
                        handleInvoice(e.target.value, `amount`, index)
                      }
                      onKeyPress={(e) =>
                        [`e`, `E`, `+`, `-`, `.`].includes(e.key) &&
                        e.preventDefault()
                      }
                    />
                  </div>
                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Description"
                      placeholder="Enter Description"
                      value={item.description}
                      setValue={(e) =>
                        handleInvoice(e.target.value, `description`, index)
                      }
                    />
                  </div>
                  <div className="relative mt-6">
                    <Checkbox
                      title="Is Amount Fixed? "
                      name="isAmountFixed"
                      value={item.isAmountFixed}
                      id="isAmountFixed"
                      checked={item.isAmountFixed}
                      disabled={!item?.amount}
                      setValue={(e) => {
                        handleInvoice(e.target.checked, `isAmountFixed`, index)
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-1 mt-6">
                  <div className="flex items-center justify-center float-right">
                    <span
                      onClick={addFee}
                      data-tip="Add Form"
                      className="tooltip px-2 py-1.5 hover:text-red-500 cursor-pointer "
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="transform transition  hover:-translate-y-0 hover:scale-105"
                      />
                    </span>
                    <span
                      onClick={() => removeFee(index)}
                      data-tip="Remove Form"
                      className="tooltip px-2 py-1.5 hover:text-red-500 cursor-pointer "
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="transform transition  hover:-translate-y-0 hover:scale-105"
                      />
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="flex justify-center">
          <div className="text-center w-96 my-4">
            <SubmitButton
              disabled={paymentTemplateNameValidating}
              onClick={ValidateForm}
            >
              <div>Update</div>
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
