import {
  AMS_BACKEND_API,
  AxiosInstance,
  allowedSystemPermissions,
} from 'App/Config'
import { Container, PageHeader, StyledButton, SubmitButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { DateRange } from 'react-date-range'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { InputField } from '../../Common/InputField'
import { ReactModal } from '../../Common/Modal'
import { DateRangeInputField } from '../../Common/DateRangeInputField'
import { ReactSelectField } from '../../Common/ReactSelect'
import { Spinner } from '../../Common/Spinner'
import { useHistory } from 'react-router-dom'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import {
  isSuperUser,
  loadAgents,
  loadFranchises,
  permissionGranted,
} from 'App/Services'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'
import { DATE_FORMATS } from 'App/constants'

export const AddMonthlyGoals = () => {
  const history = useHistory()
  const [monthlyGoals, setMonthlyGoals] = useState({
    targetPayment: ``,
    targetLeads: ``,
    targetPolicies: ``,
    title: ``,
    target: ``,
    franchise: ``,
    assignedTo: [],
  })
  //  date range picker
  const [goalsDuration, setGoalsDuration] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
    },
  ])
  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)
  const _locations = useSelector(({ user }) => user?.preferredLocation)
  const [dateRangeModal, setDateRangeModal] = useState(false)
  const [errors, setErrors] = useState({
    goalDurationError: ``,
    targetPoliciesError: ``,
    targetLeadsError: ``,
    titleError: ``,
    targetPaymentError: ``,
    assignedToError: ``,
  })

  // const { profile } = useSelector(({ user: { user } }) => user)

  const { mutate, isLoading, error } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/goals`, {
        ...payload,
      }).then((res) => {
        if (res?.data) {
          NotificationManager.success(`Operation successful`)
          history.push(`/admin/monthlygoals`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.targetPaymentError.length &&
      !errors.assignedToError.length &&
      !errors?.franchiseError?.length &&
      !errors.targetPoliciesError.length &&
      !errors.targetLeadsError.length &&
      !errors.titleError.length
    ) {
      mutate({
        startDate: goalsDuration[0]?.startDate,
        endDate: goalsDuration[0]?.endDate,
        title: monthlyGoals?.title,
        targetPayment: monthlyGoals?.targetPayment,
        targetLeads: monthlyGoals?.targetLeads,
        targetPolicies: monthlyGoals?.targetPolicies,
        assignedTo: monthlyGoals?.assignedTo?.map((item) => item?.value),
        ...(monthlyGoals?.franchise?.value && {
          franchiseId: monthlyGoals?.franchise?.value,
        }),
      })
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const ValidateForm = () => {
    if (!monthlyGoals?.title.length) {
      handleErrors(`titleError`, `Enter title`)
    } else {
      handleErrors(`titleError`, ``)
    }
    if (isSuperUser(`super-admin:*`)) {
      if (!monthlyGoals?.franchise?.value?.length) {
        handleErrors(`franchiseError`, `Select franchise`)
      } else {
        handleErrors(`franchiseError`, ``)
      }
    }
    if (!monthlyGoals?.assignedTo.length) {
      handleErrors(`assignedToError`, `Select User`)
    } else {
      handleErrors(`assignedToError`, ``)
    }
    if (!monthlyGoals?.targetPayment?.length) {
      handleErrors(`targetPaymentError`, `Enter Payment`)
    } else if (
      monthlyGoals?.targetPayment?.length &&
      monthlyGoals?.targetPayment < 1
    ) {
      handleErrors(`targetPaymentError`, `Enter positive number`)
    } else {
      handleErrors(`targetPaymentError`, ``)
    }
    if (!monthlyGoals?.targetLeads?.length) {
      handleErrors(`targetLeadsError`, `Enter Leads`)
    } else if (
      monthlyGoals?.targetLeads?.length &&
      monthlyGoals?.targetLeads < 1
    ) {
      handleErrors(`targetLeadsError`, `Enter positive number`)
    } else {
      handleErrors(`targetLeadsError`, ``)
    }
    if (!monthlyGoals?.targetPolicies?.length) {
      handleErrors(`targetPoliciesError`, `Enter Policies`)
    } else if (
      monthlyGoals?.targetPolicies?.length &&
      monthlyGoals?.targetPolicies < 1
    ) {
      handleErrors(`targetPoliciesError`, `Enter positive number`)
    } else {
      handleErrors(`targetPoliciesError`, ``)
    }

    // if (!monthlyGoals?.target?.length) {
    //   handleErrors(`targetError`, `Enter Target`)
    // } else {
    //   handleErrors(`targetError`, ``)
    // }
    handleErrors(`formSubmit`, true)
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }
  return (
    <Container>
      {error && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      {dateRangeModal && (
        <ReactModal
          title="Date Range"
          closeModal={() => setDateRangeModal(false)}
        >
          <div className="pb-2">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setGoalsDuration([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={goalsDuration}
              minDate={new Date()}
              className="w-full"
            />
            <div className="px-2 text-right">
              <StyledButton
                onClick={() => {
                  setDateRangeModal(false)
                }}
              >
                <div className="flex gap-2 text-white">
                  <span>Set Date Range </span>
                </div>
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      )}

      <PageHeader padding="true">Monthly Goals</PageHeader>
      <Spinner loading={isLoading} />

      <div
        className={`p-4 bg-white ${
          isLoading && ` opacity-30 pointer-events-none`
        } `}
      >
        <div className="grid grid-cols-4 gap-6 mt-2 px-4">
          <div
            onClick={() => setDateRangeModal(!dateRangeModal)}
            className="flex-1 relative"
          >
            <DateRangeInputField
              type="text"
              title="Select Duration"
              name="target"
              placeholder="Select Date Range"
              value={
                goalsDuration &&
                moment(goalsDuration[0]?.startDate).format(
                  DATE_FORMATS.DATE_ONLY,
                ) +
                  `\t - \t` +
                  moment(goalsDuration[0]?.endDate).format(
                    DATE_FORMATS.DATE_ONLY,
                  )
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="title"
              title="Title *"
              placeholder="Enter title"
              value={monthlyGoals.title}
              setValue={(e) => {
                setMonthlyGoals((prevState) => ({
                  ...prevState,
                  [`title`]: e.target.value,
                }))
              }}
              errorMessage={errors.titleError}
            />
          </div>
          {permissionGranted([`super-admin:*`]) && (
            <div className="flex-1 relative">
              <ReactSelectField
                title="Franchise *"
                placeholder="Select Franchise"
                errorMessage={errors.franchiseError}
                value={monthlyGoals?.franchise}
                setValue={(value) => {
                  setMonthlyGoals((prevState) => ({
                    ...prevState,
                    [`franchise`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadFranchises}
                isSearchable={true}
              />
            </div>
          )}
          <div className="flex-1 relative">
            <ReactSelectField
              title="Assign To *"
              placeholder="Select User"
              value={monthlyGoals.assignedTo}
              errorMessage={errors.assignedToError}
              setValue={(value) => {
                setMonthlyGoals((preState) => ({
                  ...preState,
                  assignedTo: value,
                }))
              }}
              isMulti={true}
              loadOptions={loadAgents}
              isSearchable={true}
              additionalFilters={
                isSuperUser(`super-admin:*`)
                  ? {
                      franchiseId: monthlyGoals?.franchise?.value,
                      permissions: [`manager:*`],
                    }
                  : {
                      franchiseId: _franchise?._id,
                      locations: [_locations?._id],
                      permissions: allowedSystemPermissions?.goals,
                    }
              }
              cacheUniqs={monthlyGoals?.franchise?.value}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              name="target"
              title="Payment *"
              placeholder="Enter amount"
              value={monthlyGoals.targetPayment}
              setValue={(e) => {
                setMonthlyGoals((prevState) => ({
                  ...prevState,
                  [`targetPayment`]: e.target.value,
                }))
              }}
              onKeyPress={(e) =>
                [`e`, `E`, `+`, `-`, `.`].includes(e.key) && e.preventDefault()
              }
              errorMessage={errors.targetPaymentError}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              name="targetLeads"
              title="No of Leads *"
              placeholder="Enter no"
              value={monthlyGoals.targetLeads}
              setValue={(e) => {
                setMonthlyGoals((prevState) => ({
                  ...prevState,
                  [`targetLeads`]: e.target.value,
                }))
              }}
              onKeyPress={(e) =>
                [`e`, `E`, `+`, `-`, `.`].includes(e.key) && e.preventDefault()
              }
              errorMessage={errors.targetLeadsError}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              name="targetPolicies"
              title="No Policies *"
              placeholder="Enter no"
              value={monthlyGoals.targetPolicies}
              setValue={(e) => {
                setMonthlyGoals((prevState) => ({
                  ...prevState,
                  [`targetPolicies`]: e.target.value,
                }))
              }}
              onKeyPress={(e) =>
                [`e`, `E`, `+`, `-`, `.`].includes(e.key) && e.preventDefault()
              }
              errorMessage={errors.targetPoliciesError}
            />
          </div>
        </div>

        <div className="flex justify-center">
          <div className="text-center w-96 mt-5 my-2">
            <SubmitButton onClick={ValidateForm}>
              <div>Set Goals</div>
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
