import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Badge,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@windmill/react-ui'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { Container, PageHeader } from 'App/Styled'
import React from 'react'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router'

const Grid4TableColumns = [
  { label: `RecipientName`, value: `name` },
  { label: `Status`, value: `isRead` },
  { label: `RecipientName`, value: `name` },
  { label: `Status`, value: `isRead` },
]
const Grid2TableColumns = [
  { label: `RecipientName`, value: `name` },
  { label: `Status`, value: `isRead` },
]

export const GeneralNotification = () => {
  const {
    params: { generalNotificationId },
  } = useRouteMatch()

  //Api call
  const {
    data: generalNotificationDetail,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `monthlyGoalPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/general-notifications/${generalNotificationId}`,
      ).then((res) => {
        return res?.data?.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      // onSuccess: (res) => {
      //   console.log(res)
      // },
    },
  )

  return (
    <Container>
      <PageHeader bgcolor="bg-white">
        <div>General Notification Detail</div>
      </PageHeader>
      <hr className="my-3" />
      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <>
          <div className="p-4">
            <div>
              <p cl>
                <span className="text-gray-700 mr-2 font-semibold">
                  Notification Title:
                </span>
                <span>
                  {generalNotificationDetail
                    ? generalNotificationDetail.generalNotification.title
                    : ``}
                </span>
              </p>
              <p className="my-3">
                <span className="text-gray-700 mr-2 font-semibold">
                  Notification Description:
                </span>
                <span>
                  {generalNotificationDetail
                    ? generalNotificationDetail.generalNotification.description
                    : ``}
                </span>
              </p>
            </div>

            {generalNotificationDetail?.generalNotification?.franchises
              .length === 0 && (
              <p className="p-4 text-center text-gray-500 text-md">
                No data found
              </p>
            )}

            {generalNotificationDetail?.generalNotification?.franchises?.map(
              (franchise) => (
                <>
                  <div className="col-span-6 text-lg">
                    <span className="font-semibold mr-1">Franchise:</span>
                    {franchise.name}
                  </div>
                  <hr />
                  <table className="w-full border-collapse border-2 mb-4">
                    <TableHeader>
                      <TableRow className="grid grid-cols-4">
                        {franchise?.recipients?.length > 1
                          ? Grid4TableColumns.map((item, idx) => {
                              return (
                                <TableCell key={idx}>
                                  <div className="cursor-pointer select-none text-sm flex">
                                    {item.label}
                                  </div>
                                </TableCell>
                              )
                            })
                          : Grid2TableColumns.map((item, idx) => {
                              return (
                                <TableCell key={idx}>
                                  <div className="cursor-pointer select-none text-sm flex">
                                    {item.label}
                                  </div>
                                </TableCell>
                              )
                            })}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      <TableRow className={`grid grid-cols-4`}>
                        {franchise?.recipients?.map((recipient) => (
                          <>
                            <td className="px-4 py-1 capitalize truncate font-semibold text-sm">
                              {recipient?.name}
                            </td>
                            <td className="px-4 py-1 capitalize truncate font-semibold text-sm">
                              <Badge
                                type={recipient?.isRead ? `success` : `warning`}
                              >
                                {recipient?.isRead ? `Read` : `Unread`}
                              </Badge>
                            </td>
                          </>
                        ))}
                      </TableRow>
                    </TableBody>
                  </table>
                </>
              ),
            )}
          </div>
        </>
      )}
    </Container>
  )
}
