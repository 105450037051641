import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AllRatingTemplates = React.lazy(dynamicImportComponents[`/raters/ratingtemplates`])


export const AllRatingTemplatePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllRatingTemplates />
  </Suspense>
)
