import { DashboardSkelton } from 'App/Components/Dashboard/DashboardSkelton'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'


const Dashboard = React.lazy(dynamicImportComponents[`/dashboard`])

export const DashboardPage = () => (
  <Suspense fallback={<DashboardSkelton />}>
    <Dashboard />
  </Suspense>
)
