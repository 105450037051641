import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AllCampaignGroups = React.lazy(dynamicImportComponents[`/broadcasts/campaigns`])

export const AllCampaignGroupsPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllCampaignGroups />
  </Suspense>
)
