import { Spinner } from 'App/Components/Common/Spinner'
import React, { Suspense } from 'react'

const AddCustomer = React.lazy(() =>
  import(`App/Components/Customers/AddCustomer`).then((module) => ({
    default: module.AddCustomer,
  })),
)




export const AddCustomerPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AddCustomer />
  </Suspense>
)
