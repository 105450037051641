import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const AllTransactions = React.lazy(dynamicImportComponents[`/transaction/transactions-history`])

export const AllTransactionsPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllTransactions />
  </Suspense>
)