import React from 'react'

export const RadioButton = ({
  title,
  name,
  id,
  value,
  errorMessage,
  setValue,
  checked,
  disabled,
}) => {
  return (
    <>
      <label
        id={id}
        className={`inline-flex items-center mt-2 cursor-pointer font-light ${
          disabled ? `cursor-not-allowed opacity-70` : ``
        }`}
      >
        <span className="text-gray-700 font-medium text-sm mr-2">
          {title}
          {errorMessage && <span className="text-red-500 required-dot"></span>}
        </span>
        <input
          type="radio"
          className="cursor-pointer mt-1"
          name={name}
          id={id}
          defaultChecked={checked}
          value={value}
          onChange={setValue}
          disabled={disabled}
        />
      </label>
    </>
  )
}
