import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AllMonthlyGoals = React.lazy(dynamicImportComponents[`/admin/monthlygoals`])

export const AllMonthlyGoalsPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <AllMonthlyGoals />
  </Suspense>
)