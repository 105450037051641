import React, { Suspense } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'

const QuoteOffers = React.lazy(dynamicImportComponents[`/raters/quoteform/offers`])

export const QuoteOffersPage = () => (
  <Suspense fallback={<Spinner loading />}>
    <QuoteOffers />
  </Suspense>
)