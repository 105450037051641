import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const AllScrapingCredentials = React.lazy(dynamicImportComponents[`/admin/scarping-credentials`])

export const AllScrapingCredentialsPage = () => (
    <Suspense fallback={<Spinner loading />}>
        <AllScrapingCredentials />
    </Suspense>
)