import { Spinner } from 'App/Components/Common/Spinner'
import { dynamicImportComponents } from 'App/Config/dynamicImports'
import React, { Suspense } from 'react'

const Franchise = React.lazy(dynamicImportComponents[`/franchise/preview`])

export const FranchisePage = () => (
  <Suspense fallback={<Spinner loading />}>
    <Franchise />
  </Suspense>
)
